import { Component, Input } from '@angular/core';
import { LocalUserStream } from '../webrtc/LocalUserStream';


@Component({
  selector: 'vumeter',
  templateUrl: './vumeter.component.html'
})
export class VUMeterComponent {

  audioContext = new AudioContext();
  volume: number;

  @Input() stream: MediaStream;
  @Input() audio: boolean;

  constructor() { }

  ngOnInit() {
    this.initVolume();
  }

  initVolume() {
    if (!this.stream || !this.stream.getAudioTracks().length) {
      return;
    }

    const analyser = this.audioContext.createAnalyser();
    const microphone = this.audioContext.createMediaStreamSource(this.stream);
    const processor = this.audioContext.createScriptProcessor(2048, 1, 1);

    analyser.smoothingTimeConstant = 0.8;
    analyser.fftSize = 1024;

    microphone.connect(analyser);
    analyser.connect(processor);
    processor.connect(this.audioContext.destination);

    processor.onaudioprocess = () => {
      const array = new Uint8Array(analyser.frequencyBinCount);
      analyser.getByteFrequencyData(array);
      let x = 0;
      let sum = 0;

      var length = array.length;
      for (var i = 0; i < length; i++) {
        x = array[i];
        sum += x * x;
      }

      let intval = Math.ceil(Math.sqrt(sum / length));
      this.volume = intval * 100 / 200;
    }
  }

}