import { WebRtcClientConfiguration } from "../services/signalr.service";

let emptyAudioTrack: MediaStreamTrack | null = null;
let emptyVideoTrack: MediaStreamTrack | null = null;

function getEmptyAudioTrack() {

  if (emptyAudioTrack) {
    return emptyAudioTrack;
  }

  if ((window as any).AudioContext
    && (window as any).AudioContext.prototype.createOscillator
    && (window as any).AudioContext.prototype.createMediaStreamDestination) {

    const audioContext = new AudioContext();
    const oscillator = audioContext.createOscillator();
    const audioNode = oscillator.connect(audioContext.createMediaStreamDestination());

    oscillator.start();

    if ((audioNode as any)?.stream) {
      const track: MediaStreamTrack = (audioNode as any).stream.getAudioTracks()[0];
      track.enabled = false;
      return (emptyAudioTrack = track);
    }
  }

  return null;
}

function render(context: CanvasRenderingContext2D, width: number, height: number) {
  context.fillStyle = 'black';
  context.fillRect(0, 0, width, height);
}

function getEmptyVideoTrack() {

  if (emptyVideoTrack) {
    return emptyVideoTrack;
  }

  if ((HTMLCanvasElement.prototype as any).captureStream) {
    const width = 640;
    const height = 480;

    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const context = canvas.getContext('2d');

    setInterval(() => render(context, width, height), 1000);

    const canvasStream = (canvas as any).captureStream() as MediaStream;
    const track = canvasStream.getVideoTracks()[0];
    track.enabled = false;

    return (emptyVideoTrack = track);
  }

  return null;
}

export function getVideoTrackConstraints(configuration: WebRtcClientConfiguration, videoDeviceId: string | null): MediaTrackConstraints {

  const { idealWidth, idealHeight, idealFrameRate } = configuration || { idealWidth: 640, idealHeight: 480, idealFrameRate: 15 };

  const videoTrackConstraints: MediaTrackConstraints = videoDeviceId == null
    ? { facingMode: { ideal: 'user' } }
    : { deviceId: { ideal: videoDeviceId } };

  if (idealWidth) {
    videoTrackConstraints.width = { ideal: idealWidth };
  }

  if (idealHeight) {
    videoTrackConstraints.height = { ideal: idealHeight };
  }

  if (idealFrameRate) {
    videoTrackConstraints.frameRate = { ideal: idealFrameRate };
  }

  return videoTrackConstraints;
}

export function getAudioTrackConstraints(constraints: MediaTrackConstraints, audioDeviceId: string | null): MediaTrackConstraints {

  let audioTrackConstraints: MediaTrackConstraints = audioDeviceId == null
    ? { deviceId: '' }
    : { deviceId: { ideal: audioDeviceId } };

  audioTrackConstraints.autoGainControl = constraints.autoGainControl;
  audioTrackConstraints.echoCancellation = constraints.echoCancellation;
  audioTrackConstraints.noiseSuppression = constraints.noiseSuppression;

  return audioTrackConstraints;
}

export async function hasMultipleCameras() {
  try {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.filter(e => e.kind === 'videoinput').length > 1;
  } catch (error) {
    return false;
  }
}

/**
 * Setzt den Statustext anhand des ICE Connection State.
 * @param iceConnectionState Der ICE Connection State.
 */
export function getStatusText(iceConnectionState: RTCIceConnectionState) {
  switch (iceConnectionState) {
    case 'new':
      return 'Warten auf Verbindungsaufbau...';
    case 'checking':
      return 'Verbindung wird aufgebaut...';
    case 'failed':
      return 'Verbindung fehlgeschlagen';
    case 'disconnected':
      return 'Verbindung verloren';
    case 'closed':
      return 'Verbindung beendet';
    default:
      return null;
  }
}

export function setMediaBitrate(sdp: string, media: 'audio' | 'video', bitrate: number) {
  var lines = sdp.split("\n");
  var line = -1;
  for (var i = 0; i < lines.length; i++) {
    if (lines[i].indexOf("m=" + media) === 0) {
      line = i;
      break;
    }
  }
  if (line === -1) {
    return sdp;
  }

  // Pass the m line
  line++;

  // Skip i and c lines
  while (lines[line].indexOf("i=") === 0 || lines[line].indexOf("c=") === 0) {
    line++;
  }

  // If we're on a b line, replace it
  if (lines[line].indexOf("b") === 0) {
    lines[line] = "b=AS:" + bitrate;
    return lines.join("\n");
  }

  // Add a new b line
  var newLines = lines.slice(0, line)
  newLines.push("b=AS:" + bitrate)
  newLines = newLines.concat(lines.slice(line, lines.length))
  return newLines.join("\n");
}

export function getVideoTrackFromStream(stream: MediaStream) {
  const videoTracks = stream.getVideoTracks();
  return videoTracks.length > 0 ? videoTracks[0] : null;
}

export function getAudioTrackFromStream(stream: MediaStream) {
  const audioTracks = stream.getAudioTracks();
  return audioTracks.length > 0 ? audioTracks[0] : null;
}

export async function addOrReplaceTrack(peerConnection: RTCPeerConnection, stream: MediaStream, kind: 'audio' | 'video', track: MediaStreamTrack | null) {

  const senders = peerConnection.getSenders();
  const mediaSenders = senders.filter(s => s.track && s.track.kind === kind);
  const mediaSender = mediaSenders.length > 0 ? mediaSenders[0] : null;

  if (!track) {
    track = kind === 'audio' ? getEmptyAudioTrack() : getEmptyVideoTrack();
  }

  if (mediaSender) {
    if (track) {
      console.trace('replaceTrack', track);
      console.log('replaceTrack on ', mediaSender);
      await mediaSender.replaceTrack(track);
    } else {
      console.trace('removeTrack on ', mediaSender);
      peerConnection.removeTrack(mediaSender);
    }
  } else {
    if (track) {
      console.trace('addTrack to peerConnection', peerConnection);
      console.log('addTrack to peerConnection track', track);
      console.log('addTrack to peerConnection stream', stream);
      peerConnection.addTrack(track, stream);
    }
  }
}

export function stopStream(stream: MediaStream) {
  stream.getTracks().forEach(track => track.stop());
  try {
    if ((stream as unknown as any)?.stop) { (stream as unknown as any).stop(); }
  }
  catch (error) {
    this.errorCallback('stopStream', error);
  }
}

export function closeRtcPeerConnection(peerConnection: RTCPeerConnection, stopTracks: boolean) {
  if (stopTracks) {
    peerConnection.getTransceivers().forEach(transceiver => {
      try {
        transceiver.stop();
        transceiver.sender?.track?.stop();
        transceiver.receiver?.track?.stop();
      } catch (error) {
        this.errorCallback('closeRtcPeerConnection', error);
      }
    });
  }

  peerConnection.getSenders().forEach(sender =>
  {
    if (stopTracks) {
      sender.track?.stop();
    }

    peerConnection.removeTrack(sender);
  });

  if (stopTracks) {
    peerConnection.getReceivers().forEach(receiver => {
      receiver.track?.stop();
    });
  }

  peerConnection.close();
  console.log('closed RTCPeerConnection', peerConnection);
}
