import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { BadRequestModel } from "./types";

@Injectable()
export class UploadFileHttpService {

  constructor(private httpService : HttpService) { }

  post(file: File, token: string, benutzerToken: string) { 
    const body = new FormData();
    body.append('file', file, file.name);
    body.append('token', token);
    body.append('benutzerToken', benutzerToken);
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/UploadFile`, body, responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as BadRequestModel } }
        if (response.status === 413) { return { status: 413 as 413, statusText: response.statusText }; }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
