import { Subject } from "rxjs";

export class UploadNotifier {


    private _uploadFileInProgress = false;

    get uploadFileInProgress(): boolean {
        return this._uploadFileInProgress;
    }

    set uploadFileInProgress(_uploadFileInProgress: boolean) {
        this._uploadFileInProgress = _uploadFileInProgress;
        this.uploadFileInProgresChanged.next(_uploadFileInProgress);
    }

    public uploadFileInProgresChanged: Subject<boolean> = new Subject();

}
