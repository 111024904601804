import { Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Base64FileModel } from '../generated/types';

@Component({
  selector: 'shared-base64-upload',
  templateUrl: './base64-upload.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => Base64UploadComponent),
      multi: true
    }
  ]
})
export class Base64UploadComponent implements ControlValueAccessor {

  @Input() allowedFileTypes = '';

  value: Base64FileModel;

  disabled = false;

  @Input() maxSize: undefined | number;

  onModelChange: (value: Base64FileModel) => void = () => {
  };
  onModelTouched: () => void = () => {
  };

  writeValue(value: Base64FileModel): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  async change(event) {
    this.value = await this.getBase64(event.target.files[0], event.target);
    this.onModelChange(this.value);
    this.onModelTouched();
  }

  blur(event) {
    this.onModelTouched();
  }

  getBase64(file: File, input: HTMLInputElement) {

    if (!file) {
      return Promise.resolve(undefined);
    }

    if(!this.allowedFileTypes.includes(file.name.split('.')[1])){
      input.files = null;
      input.value = null;
      alert('Falscher Dateityp');
      return Promise.resolve(undefined);
    }

    if (this.maxSize) {
      let mb = ((file.size / 1024) / 1024);
      if (mb > this.maxSize) {
        input.files = null;
        input.value = null;
        alert('Datei ist zu groß');
        return Promise.resolve(undefined);
      }
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise<Base64FileModel>((resolve, reject) => {
      reader.onload = () => resolve({
        base64Data: (reader.result as any).split(',')[1],
        filename: file.name
      });
      reader.onerror = reject;
    });
  }

  //constructor(private downloadService: DownloadService) {

  //}

  //async download(event: Event) {
  //  event.preventDefault();
  //  await this.downloadService.download(this.value.id);
  //}
}

