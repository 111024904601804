import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FormControl } from "@angular/forms";


@Component({
  selector: 'shared-colorpicker-input',
  templateUrl: './colorpicker-input.component.html'
})
export class ColorpickerInputComponent {
  @Input() id: string;
  @Input() control: FormControl;
  @Input() label: string;
  @Input() withHoverLabel = false;
  @Input() inline: boolean;
  @Output() public change: EventEmitter<void> = new EventEmitter<void>();


  setColorPickerValue(value: string) {
    if (value.length === 7) {
      this.control.setValue(value);
      this.change.next();
    }
  }
}