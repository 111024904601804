import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { UploadSettingConfiguration } from "./types";
import { HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AllowedUploadSettingsHttpService {

  constructor(private httpService : HttpService) { }

  get() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/AllowedUploadSettings`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as UploadSettingConfiguration } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
