import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { ChatContainer, FrageGesprachEndeModel, RedirectToStartModel, BewertungContainer, TeilnehmerSettings, TeilnehmerWithTokenContainer, BeratungszeitContainer } from "./types";
import { HttpParams, HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class TeilnehmerHttpService {

  constructor(private httpService : HttpService) { }

  getChatContainer(anrufgruppe?: string) { 
    const params : any = {};
    if(anrufgruppe != null) { params['anrufgruppe'] = anrufgruppe; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/teilnehmer/chat`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as ChatContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getToken() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/teilnehmer/token`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as string } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getFragen(gespraechsStatus: number, token: string) { 
    const params : any = {};
    if(gespraechsStatus != null) { params['gespraechsStatus'] = gespraechsStatus; }
    if(token != null) { params['token'] = token; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/teilnehmer/frage`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as { [key: string]: FrageGesprachEndeModel } } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  downloadAllowed(token: string, benutzerToken: string) { 
    const params : any = {};
    if(token != null) { params['token'] = token; }
    if(benutzerToken != null) { params['benutzerToken'] = benutzerToken; }
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/teilnehmer/DownloadAllowed`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as boolean } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  redirectToStart(token: string) { 
    const params : any = {};
    if(token != null) { params['token'] = token; }
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/teilnehmer/RedirectToStart`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as RedirectToStartModel } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(gepraechToken: string, benutzerToken: string, bewertung: BewertungContainer) { 
    const params : any = {};
    if(gepraechToken != null) { params['gepraechToken'] = gepraechToken; }
    if(benutzerToken != null) { params['benutzerToken'] = benutzerToken; }
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/teilnehmer/bewertung`, body: bewertung, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getVideoAudioSettings(gespraechsToken: string, benutzerToken: string) { 
    const params : any = {};
    if(gespraechsToken != null) { params['gespraechsToken'] = gespraechsToken; }
    if(benutzerToken != null) { params['benutzerToken'] = benutzerToken; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/teilnehmer/settings`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as TeilnehmerSettings } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getTeilnehmerWithToken(gespraechsToken: string) { 
    const params : any = {};
    if(gespraechsToken != null) { params['gespraechsToken'] = gespraechsToken; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/teilnehmer/GetTeilnehmerWithToken`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as TeilnehmerWithTokenContainer[] } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  get(anrufgruppe?: string) { 
    const params : any = {};
    if(anrufgruppe != null) { params['anrufgruppe'] = anrufgruppe; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/Teilnehmer`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as BeratungszeitContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
