/*
 Als Key für den Sessionstorage, unter diesem legen wir den Gespraechstoken ab
*/
export const GESPRAECHTOKEN = "gespraechtoken";

/*
 Als Key für den Sessionstorage, unter diesem legen wir den Gespraechstoken für die Nachschaltseite ab
*/
export const NACHSCHALTSEITETOKEN = "nachschaltseite";

/*
 Als Key für den Sessionstorage, unter diesem legen wir den Benutzertoken ab
*/
export const BENUTZERTOKEN = "benutzerToken";

/*
 Als Key für den Sessionstorage, unter diesem legen wir die Anrufgruppe ab
*/
export const ANRUFGRUPPE = "anrufgruppe";

export const platzhalterWWW: string = "{{link*https://www.oevermann.de*Website Oevermann}}"; //TODO: global auslagern
export const platzhalterEMail: string = "{{email*noreply@oevermann.de*Schreiben Sie an Oevermann}}";
export const platzhalterStrong: string = "{{strong*Hervorgehobener Text}}";
export const urlValidateReg = '^((https?|http):\/\/)(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$';
export const platzhalterZeilenumbruch: string = "{{zeilenumbruch*Anzahl Umbrüche}}"; 

export const AUDIOSETTINGS = 'audioSettings';
export const VIDEOSETTINGS = 'videoSettings';
export const SPEAKERSETTINGS = 'speakerSettings';
export const CAMERA_BACKGORUND_BLUR_SETTING = 'cameraBackgroundBlurSetting';

export const AUDIOENABLED = 'audioEnabled';
export const VIDEOENABLED = 'videoEnabled';
