import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map, share } from 'rxjs/operators';
import { AnrufenRequest } from '../anrufenrequest';
import { AnrufenResult } from '../anrufenresult';
import { ChatNachricht } from '../chatnachricht';
import { Arbeitsplatzausstattung } from '../enums/arbeitsplatzausstattung';
import { BeendenGrund } from '../enums/BeendenGrund';
import { GespraechsStatus } from '../enums/gespraechsstatus';
import { GespraechsTyp } from '../enums/gespraechstyp';
import { TeilnehmerStatus } from '../enums/teilnehmerstatus';
import { AngemeldeterAgentContainer, GespraechContainer, RueckrufgesuchContainer } from '../generated/types';
import { IncomingCallContainer } from '../incomingcallcontainer';
import { RemindEinladungContainer } from '../remindeinladungcontainer';
import { AgentAddRequest } from '../shared/models/agent-add-request';
import { SystemStatusContainer } from '../systemstatuscontainer';
import { QueueContainer } from '../teilnehmer/teilnehmer.queue.component';
import { SignalRService, SignalRState } from './signalr.service';

export interface StateContainer {
  token: string;
  status: TeilnehmerStatus;
  gespraech: GespraechContainer;
  incomingCall: IncomingCallContainer;
}

@Injectable()
export class ChatHub {

  public signalRConnected: Observable<SignalRState>;

  constructor(private signalRService: SignalRService) {
    this.signalRConnected = signalRService.state.pipe(filter(state => state == 'connected'), share());
  }

  private async invoke<T = void>(methodName: string, ...args: any[]): Promise<T> {
    return this.signalRService.invoke('chat', methodName, ...args)
  }

  private observe<T extends any[]>(eventName: string) {
    return this.signalRService.observe<T>('chat', eventName);
  }

  senden(token: string, text: string, benutzerToken: string) {
    return this.invoke('Senden', token, text, benutzerToken);
  }

  dokumentSenden(token: string, text: string, benutzerToken: string, signatureId: string){
    return this.invoke('DokumentSenden', token, text, benutzerToken, signatureId);
  }

  whisper(gespraechToken: string, senderBenutzerToken: string, receiverBenutzerToken: string, text: string) {
    return this.invoke('Whisper', gespraechToken, senderBenutzerToken, receiverBenutzerToken, text);
  }

  templateSenden(token: string, templateName: string, benutzerToken: string) {
    return this.invoke('TemplateSenden', token, templateName, benutzerToken);
  }

  annehmen(token: string, connectionId: string) {
    return this.invoke<boolean>('Annehmen', token, connectionId);
  }

  anrufen(anrufenRequest: AnrufenRequest) {
    return this.invoke('Anrufen', anrufenRequest) as Promise<AnrufenResult>;
  }

  // todo: richtige Implentierung prüfen.
  // vermutlich auch anderer Rückgabewert.
  agentAnrufen(agentAddRequest: AgentAddRequest) {
    return this.invoke('AgentAnrufen', agentAddRequest) as Promise<AnrufenResult>;
  }


  activateBeweissicherung(token: string) {
    return this.invoke('ActivateBeweissicherung', token);
  }

  startScreensharing(token: string, benutzerToken, changeToScreenSharing: boolean) {
    return this.invoke('StartScreensharing', token, benutzerToken, changeToScreenSharing);
  }

  beenden(token: string, beendenGrund: BeendenGrund, benutzerToken: string) {
    return this.invoke('Beenden', token, beendenGrund, benutzerToken);
  }

  chatGruppeBeitreten(token: string) {
    return this.invoke('ChatGruppeBeitreten', token);
  }

  whisperGruppeBeitreten(benutzerToken: string) {
    return this.invoke('WhisperGruppeBeitreten', benutzerToken);
  }

  offline() {
    return this.invoke('Offline');
  }

  verfuegbar(arbeitsPlatzAusstattung: Arbeitsplatzausstattung) {
    return this.invoke('Verfuegbar', arbeitsPlatzAusstattung);
  }

  beschaeftigt() {
    return this.invoke('Beschaeftigt');
  }

  wartenAufTermin(arbeitsPlatzAusstattung: Arbeitsplatzausstattung) {
    return this.invoke('WartenAufTermin', arbeitsPlatzAusstattung);
  }

  toggleVideo(token: string, gespraechsTyp: GespraechsTyp) {
    return this.invoke('ToggleVideo', token, gespraechsTyp);
  }

  userGruppeBeitreten() {
    return this.invoke<StateContainer>('UserGruppeBeitreteten');
  }

  monitorGruppeBeitreten() {
    return this.invoke('MonitorGruppeBeitreten');
  }

  notifyIfWriting(token: string, benutzerToken: string) {
    return this.invoke('NotifyIfWriting', token, benutzerToken);
  }

  rueckrufbearbeiten(rueckrufgesuch: RueckrufgesuchContainer) {
    return this.invoke('RueckrufBearbeiten', rueckrufgesuch);
  }

  loadChatHistory(token: string, benutzerToken: string) {
    return this.invoke<ChatNachricht[]>('LoadChatHistory', token, benutzerToken);
  }


  onOffline = this
    .observe<[]>('Offline')
    .pipe(map(() => { }));

  onVerfuegbar = this
    .observe<[]>('Verfuegbar')
    .pipe(map(() => { }));

  onBeschaeftigt = this
    .observe<[]>('Beschaeftigt')
    .pipe(map(() => { }));

  onWartenAufTemin = this
    .observe<[]>('WartenAufTermin')
    .pipe(map(() => { }));

  onInVermittlung = this
    .observe<[IncomingCallContainer]>('InVermittlung')
    .pipe(map(a => a[0]));

  onImGespraech = this
    .observe<[GespraechContainer]>('ImGespraech')
    .pipe(map(a => a[0]));

  onInNachbearbeitung = this
    .observe<[number]>('InNachbearbeitung')
    .pipe(map(a => a[0]));

  onSenden = this
    .observe<[ChatNachricht]>('Senden')
    .pipe(map(a => a[0]));

  onWhisper = this
    .observe<[ChatNachricht]>('Whisper')
    .pipe(map(a => a[0]));

  onBeenden = this.observe<[string, GespraechsStatus, string]>('Beenden');

  onBeendenAbgeschlossen = this.observe<[string, GespraechsStatus]>('BeendenAbgeschlossen');

  onAnnehmen = this
    .observe<[string]>('Annehmen')
    .pipe(map(a => a[0]));

  onBeendetRueckRuf = this
    .observe<[string]>('BeendetRueckRuf')
    .pipe(map(a => a[0]));

  onActivateBeweissicherung = this
    .observe<[]>('ActivateBeweissicherung')
    .pipe(map(() => { }));

  onStartScreensharing = this.observe<[string, boolean]>('StartScreensharing');

  onAnrufSignalisieren = this
    .observe<[IncomingCallContainer]>('AnrufSignalisieren')
    .pipe(map(a => a[0]));

  onRemindEinladung = this
    .observe<[RemindEinladungContainer]>('RemindEinladung')
    .pipe(map(a => a[0]));

  onRemoveIncomingCall = this
    .observe<[]>('RemoveIncomingCall')
    .pipe(map(() => { }));

  onPushToggleVideo = this
    .observe<[GespraechsTyp]>('PushToggleVideo')
    .pipe(map(a => a[0]));

  onPushAgenten = this
    .observe<[AngemeldeterAgentContainer[]]>('PushAgenten')
    .pipe(map(a => a[0]));

  onPushMaxVerfuegbarAlert = this
    .observe<[number]>('PushMaxVerfuegbarAlert')
    .pipe(map(a => a[0]));

  onPushUpdateTermine = this
    .observe<[number]>('PushUpdateTermine')
    .pipe(map(a => a[0]));

  onPushIsNoAgent = this
    .observe<[boolean]>('IsNoAgent')
    .pipe(map(a => a[0]));

  onPushSystemstatus = this
    .observe<[SystemStatusContainer]>('PushSystemstatus')
    .pipe(map(a => a[0]));

  onNotifyOnWriting = this
    .observe<[string]>('NotifyOnWriting')
    .pipe(map(a => a[0]));

  onPushQueue = this
    .observe<[QueueContainer]>('PushQueue')
    .pipe(map(a => a[0]));

  onPushRueckrufgesuchEingestellt = this
    .observe<[RueckrufgesuchContainer]>('PushRueckrufgesuchEingestellt')
    .pipe(map(a => a[0]));

  onPushRueckrufStatusAenderung = this
    .observe<[RueckrufgesuchContainer]>('PushRueckrufStatusAenderung')
    .pipe(map(a => a[0]));

  onPushEingehendeAnrufe = this
    .observe<[IncomingCallContainer[]]>('PushEingehendeAnrufe')
    .pipe(map(a => a[0]));
}
