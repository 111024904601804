<label [for]="id + '_select'">{{label}}</label>
<select *ngIf="!required" [id]="id + '_select'" [formControl]="control">
  <option *ngIf="optional" [ngValue]="null"></option>
  <option *ngFor="let item of items" [ngValue]="item.value">{{item.label}}</option>
</select>
<select *ngIf="required" [id]="id + '_select'" [formControl]="control" required>
  <option *ngIf="optional" [ngValue]="null"></option>
  <option *ngFor="let item of items" [ngValue]="item.value">{{item.label}}</option>
</select>
<!-- class .error wird über css auf display:none gesetzt. Daher ist das hier warscheinlich obsolete -->
<!--<div class="error" *ngIf="control.touched && control.errors">-->
<!--  <div *ngIf="control.errors['required']">Eine Eingabe wird erwartet</div>-->
<!--  <div *ngIf="control.errors['match']">{{control.errors['match']}}</div>-->
<!--  <div *ngIf="control.errors['server']">{{control.errors['server']}}</div>-->
<!--  <div *ngIf="control.errors['custom']">{{control.errors['custom']}}</div>-->
<!--</div>-->
