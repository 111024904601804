import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

const successExpiresInSeconds = 2;
const errorExpiresInSeconds = 4;

export type Notification =
 (| { type: 'error'; text: string; }
  | { type: 'warning'; text: string; }
  | { type: 'success'; text: string; }) & { expires: Date };

@Injectable()
export class NotificationService {

  add(notification: Notification) {
    this.notifications.next(notification);
  }

  notifyError(text: string) {
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + errorExpiresInSeconds);
    this.notifications.next({ type: 'error', expires, text });
  }

  notifySuccess(text: string) {
    const expires = new Date();
    expires.setSeconds(expires.getSeconds() + successExpiresInSeconds);
    this.notifications.next({ type: 'success', expires, text });
  }

  notifications = new Subject<Notification>();
}