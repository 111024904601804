import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Component, Injectable, ErrorHandler, LOCALE_ID } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FullCalendarModule } from '@fullcalendar/angular';
import timeGridPlugin from '@fullcalendar/timegrid';
import { AppComponent } from './app.component';
import { ExternalLoginGuard } from './external-login/external-login.guard';
import { SignalRService } from './services/signalr.service';
import { ChatService } from './services/chat.service';
import { DownloadService } from './services/download.service';
import { BrowserDownloadService } from './services/browserdownload.service';
import { GespraecheListeService } from './services/gespraecheliste.service';
import { SharedModule } from './shared/shared.module';
import { AllowedUploadSettingsHttpService } from './generated/allowed-upload-settings-http.service';
import { MemberModule, memberRoutes, LogModule, HttpModule, MemberService, AuthServiceToken } from '@oevermann/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LayoutComponent } from './layout.component';
import { ChatHub } from './services/chat.hub';
import { WebRtcHub } from './services/webrtc.hub';
import { ConfigurationResolver } from './agent/configuration.resolver';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import { ConferenceHub } from './services/conference.hub';
import { TeilnehmerService } from './services/teilnehmer.service';
import { ForbiddenComponent } from './forbidden.component';
import { ConferenceHttpService } from './generated/conference-http.service';
import { DefaultConferenceResolver } from './agent/conference/default-conference.resolver';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import {RadioButtonModule} from 'primeng/radiobutton';
import { AgentEinladungBearbeitenResolver } from './agent/agent.einladung-bearbeiten.resolver';
import { EinladungHttpService } from './generated/einladung-http.service';
import { StorageService } from './services/storage.service';
import { Anis } from './anis';
import { ApiVersionChanged } from './app-version-changed';
import { ExternalLoginComponent } from './external-login/external-login.component';
import {DokumentHttpService} from './generated/dokument-http.service';
import {DokumentAdminService} from './generated/dokument-admin.service';
import {YouSignHttpService} from './generated/you-sign-http.service';

FullCalendarModule.registerPlugins([timeGridPlugin]);

registerLocaleData(localeDe, 'de-DE');

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    ForbiddenComponent,
    ExternalLoginComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ConfirmDialogModule,
    HttpModule.forRoot({
      apiVersion: Anis,
      apiVersionChanged: ApiVersionChanged,
    }),
    LogModule.forRoot(),
    SharedModule.forRoot(),
    MemberModule.forRoot({
      defaultPath: '/cockpit/monitor',
      loginPath: '/member/login',
      forbiddenPath: 'forbidden',
      keepAlive: true
    }),
    RouterModule.forRoot([
      {
        path: 'login-with-token/:token',
        canActivate: [ExternalLoginGuard],
        component: ExternalLoginComponent
      },
      {
        path: '',
        redirectTo: '/cockpit/monitor',
        pathMatch: 'full'
      },
      {
        path: 'forbidden',
        component: ForbiddenComponent,
        resolve: { configuration: ConfigurationResolver }
      },
      {
        path: 'member',
        component: LayoutComponent,
        resolve: { configuration: ConfigurationResolver },
        children: memberRoutes
      },
      {
        path: 'cockpit',
        loadChildren: () => import('./agent/agent.module').then(m => m.AgentModule)
      },
      {
        path: '',
        loadChildren: () => import('./teilnehmer/teilnehmer.module').then(m => m.TeilnehmerModule)
      }
    ], { onSameUrlNavigation: 'reload', enableTracing: false })
  ],
  providers: [
    StorageService,
    SignalRService,
    ChatHub,
    WebRtcHub,
    ConferenceHub,
    ChatService,
    DownloadService,
    ConfirmationService,
    BrowserDownloadService,
    GespraecheListeService,
    AllowedUploadSettingsHttpService,
    ConfigurationResolver,
    ConferenceHttpService,
    DefaultConferenceResolver,
    AgentEinladungBearbeitenResolver,
    EinladungHttpService,
    DokumentHttpService,
    DokumentAdminService,
    YouSignHttpService,
    { provide: MemberService, useExisting: AuthServiceToken },
    { provide: LOCALE_ID, useValue: 'de-DE' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
