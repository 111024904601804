import {Component, Input, Output, EventEmitter, OnChanges, SimpleChanges} from '@angular/core';
import {NotificationService} from './notification.service';

export type TemplatePart =
  | { type: 'text'; text: string }
  | { type: 'link'; href: string; text: string }
  | { type: 'email'; email: string, text: string }
  | { type: 'download'; blobId: number; text: string }
  | { type: 'zeilenumbruch'; count: number }
  | { type: 'rueckruflink'; text: string }
  | { type: 'systeminfolink'; text: string }
  | { type: 'strong'; text: string }
  | { type: 'dokument'; name: string, url: string, text: string }
  | { type: 'signedDokument'; text: string, data: string };

interface TemplateToken {
  index: number;
  length: number;
  params: string[];
}

function parseTemplate(template: string): TemplatePart[] {

  if (template != null) {
    template = template.replace(/\r\n|\r|\n/g, '{{zeilenumbruch}}');
  }

  const regex = /{{(.*?)}}/ig;
  const tokens: TemplateToken[] = [];
  var match: RegExpExecArray;

  while ((match = regex.exec(template)) !== null) {
    tokens.push({
      index: match.index,
      length: match[0].length,
      params: match[1].split('*')
    });
  }

  if (tokens.length === 0) {
    return [{ type: 'text', text: template }];
  }
  else {
    const result: TemplatePart[] = [];
    let index = 0;
    let matchIndex = 0;
    while (index < template.length) {

      const match = matchIndex == tokens.length ? null : tokens[matchIndex];
      if (match && match.index === index) {

        switch (match.params[0]) {
          case "link":
            result.push({ type: 'link', href: match.params[1], text: match.params[2] });
            break;
          case "email":
            result.push({ type: 'email', email: "mailto:" + match.params[1], text: match.params[2] });
            break;
          case "download":
            const blobId = parseInt(match.params[1]);
            const text = match.params[2];
            result.push({ type: 'download', blobId, text });
            break;
          case "rueckruflink":
            result.push({ type: 'rueckruflink', text: match.params[1] });
            break;
          case "systeminfolink":
            result.push({ type: 'systeminfolink', text: match.params[1] });
            break;
          case "zeilenumbruch":
            const param = match.params.length == 2 ? match.params[1] : '1';
            const count = parseInt(param);
            result.push({ type: 'zeilenumbruch', count });
            break;
          case "strong":
            result.push({ type: 'strong', text: match.params[1] });
            break;
          case "dokument":
            result.push({ type: 'dokument', url: match.params[1], name: match.params[2], text: match.params[3]});
            break;
          case "signedDokument":
            result.push({ type: 'signedDokument', text: match.params[1], data: match.params[2] });
            break;
        }
        index = match.index + match.length;
        matchIndex++;
      } else {
        //Text vor/zwischen/am Ende des strings.
        const end = match ? match.index : template.length;
        result.push({ type: 'text', text: template.substring(index, end) });
        index = end;
      }
    }
    return result;
  }
}

@Component({
  selector: 'shared-template',
  templateUrl: './template.component.html'
})

export class TemplateComponent implements OnChanges {

  @Input() inputText: string;
  @Input() token: string;
  @Input() isNoAgent: boolean;

  @Output() onRueckrufLinkClicked: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onAddSysteminfo: EventEmitter<string> = new EventEmitter<string>();

  constructor(private notificationService: NotificationService) {
  }

  parts: TemplatePart[];

  Arr = Array;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.inputText) {
      this.parts = parseTemplate(changes.inputText.currentValue);
    }
  }

  rueckrufLinkClicked(event: Event) {
    this.onRueckrufLinkClicked.next(event);
  }

  addSysteminfo(text: string) {
    this.onAddSysteminfo.next(text);
  }

  signDocument(url: string) {
    if(this.isNoAgent) {
      window.open(url, "popup", 'width=800,height=800');
    }else{
      this.notificationService.notifyError('Sie können das Dokument nicht selbst signieren.')
    }
  }

  openDocument(data: string) {
      let pdfWindow = window.open("")
      pdfWindow.document.write(
          "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
          encodeURI(data) + "'></iframe>"
      )
  }
}
