import { ConfigurationContainer } from "../generated/types";
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { ConfigurationService } from "../services/configuration.service";

/** Resolver um die Konfiguration zu laden. */
@Injectable()
export class ConfigurationResolver implements Resolve<ConfigurationContainer> {

  constructor(private configurationService: ConfigurationService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ConfigurationContainer> {
    return this.configurationService.getLizenz();
  }
}