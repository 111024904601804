import { Component, OnInit, Output, EventEmitter, Input, forwardRef, OnDestroy } from "@angular/core";
import { Subject, Subscription } from "rxjs";
import { map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FormControl, NG_VALUE_ACCESSOR, ControlValueAccessor } from "@angular/forms";

/**
 * Sucheingabe die das Change-Event verzögert aufruft um nicht zu viele Requests zu machen.
 */
@Component({
  selector: 'shared-search',
  templateUrl: './search.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SearchComponent),
      multi: true
    }
  ]
})
export class SearchComponent implements OnInit, ControlValueAccessor, OnDestroy {

  disabled = false;
  inputs = new Subject<Event>();
  private subscription = new Subscription();
  blur$ = new Subject<Event>();

  @Input() value = '';
  @Input() isAgent: boolean = null;
  @Input() isKunde: boolean = null;

  @Output() onChange = new EventEmitter<string>();
  @Output() clickAgent = new EventEmitter<Event>();
  @Output() clickKunde = new EventEmitter<Event>();

  ngOnInit() {
    this.subscription.add(this.change$.subscribe(this.onChange));
  }

  change$ = this.inputs.pipe(
    map(e => (e.currentTarget as HTMLInputElement).value),
    debounceTime(500),
    distinctUntilChanged()
  );
 

  writeValue(obj: string) {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this.subscription.add(this.change$.subscribe(fn));
  }

  registerOnTouched(fn: any): void {
    this.subscription.add(this.blur$.subscribe(fn));
  }

  setDisabledState?(isDisabled: boolean): void {
    throw new Error("Method not implemented.");
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  onAgentClick(event: Event) {
    this.clickAgent.next(event)
  }

  onKundeClick(event: Event) {
    this.clickKunde.next(event)
  }
}