import { Injectable } from '@angular/core';
import { NotificationService } from '../shared/notification.service';
import { BeendenGrund } from '../enums/BeendenGrund';
import { NachrichtenTemplateHttpService } from '../generated/nachrichten-template-http.service';
import { UploadFileHttpService } from '../generated/upload-file-http.service';
import { AllowedUploadSettingsHttpService } from '../generated/allowed-upload-settings-http.service';
import { RueckrufgesuchContainer, UploadSettingConfiguration } from '../generated/types';
import { GespraechsTyp } from '../enums/gespraechstyp';
import { ChatHub } from './chat.hub';
import { map } from 'rxjs/operators';
import { BENUTZERTOKEN } from '../consts';
import { BehaviorSubject } from 'rxjs';
import {DokumentHttpService} from '../generated/dokument-http.service';


@Injectable()
export class ChatService {

    public uploadInProgress = new BehaviorSubject<boolean>(false);

  constructor(private chatHub: ChatHub,
              private nachrichtenTemplateHttpService: NachrichtenTemplateHttpService,
              private uploadFileHttpService: UploadFileHttpService,
              private notificationService: NotificationService,
              private allowedUploadSettingsService: AllowedUploadSettingsHttpService,
              private dokumenteHttpService: DokumentHttpService) { }

    onSenden = this.chatHub.onSenden;

    onWhisper = this.chatHub.onWhisper;

    onBeenden = this.chatHub.onBeenden;

    onBeendenAbgeschlossen = this.chatHub.onBeendenAbgeschlossen;

    onNotifyOnWriting = this.chatHub.onNotifyOnWriting;

    onAnnehmen = this.chatHub.onAnnehmen;

    onBeendetRueckRuf = this.chatHub.onBeendetRueckRuf;

    onPushToggleVideo = this.chatHub.onPushToggleVideo;

    onActivateBeweissicherung = this.chatHub.onActivateBeweissicherung;

    onStartScreensharing = this.chatHub.onStartScreensharing;

    onPushQueue = this.chatHub.onPushQueue;

    //Gespraech über die Gruppe beitreten
    async beitreten(token: string) {
        if (token && token.length) {
            await this.chatHub.chatGruppeBeitreten(token).catch(() => {
                this.notificationService.notifyError('Sie können an diesem Gespräch nicht teilnehmen.');
            });
        } else {
            this.notificationService.notifyError('Sie können an diesem Gespräch nicht teilnehmen.');
        }
    }

    async whisperGruppeBeitreten(benutzerToken: string) {
        await this.chatHub.whisperGruppeBeitreten(benutzerToken);
    }

    /**
     * Schickt die Nachricht an einen Teilnehmer im Chat
     */
    whisper(gespraechToken: string, senderBenutzerToken: string, receiverBenutzerToken: string, text: string) {
        this.chatHub.whisper(gespraechToken, senderBenutzerToken, receiverBenutzerToken, text);
    }

    /**
     * Schickt die Nachricht in den passenden Chat zum Token
     */
    senden(token: string, nachricht: string, benutzerToken: string) {
        this.chatHub.senden(token, nachricht, benutzerToken);
    }

  dokumentSenden(token: string, nachricht: string, benutzerToken: string, signatureId: string) {
    this.chatHub.dokumentSenden(token, nachricht, benutzerToken, signatureId);
  }

  activateBeweissicherung(token: string) {
    this.chatHub.activateBeweissicherung(token);
  }

    startScreensharing(token: string, benutzerToken: string, changeToScreenSharing: boolean) {
        this.chatHub.startScreensharing(token, benutzerToken, changeToScreenSharing);
    }

    /**
     * Lädt das Template über den Namen und verschickt dieses in den Chat
     */
    templateSenden(token: string, templateName: string, benutzerToken: string) {
        this.chatHub.templateSenden(token, templateName, benutzerToken);
    }

    /**
     * Informiert alle Teilnehmer des Gesprächs, dass dieser Benutzer schreibt
     */
    notifyIfWriting(token: string, benutzerToken: string) {
        this.chatHub.notifyIfWriting(token, benutzerToken);
    }

    toggleVideo(token: string, typ: GespraechsTyp) {
        this.chatHub.toggleVideo(token, typ);
    }

    /**
     * Beendet das Gespräch
     */
    beenden(token: string, beendenGrund: BeendenGrund, benutzerToken: string) {
        this.chatHub.beenden(token, beendenGrund, benutzerToken);
    }

    /**
     * Lädt alle verfügbaren NachrichtenTemplates
     */
    chat_LoadAvailableTemplates(token: string) {
        return this.nachrichtenTemplateHttpService.getAllChatTemplates(token).toPromise();
    }

  loadAvailableDocuments(token: string){
    return this.dokumenteHttpService.getAllChatDocuments(token).toPromise();
  }
  ///**
  // * Beendet das Gespräch mit dem Status Rueckruf
  // */
  //chat_PushRueckruf(token: string) {
  //  this.signalRService.chat_pushRueckruf(token);
  //}

    /**
     * Lädt die bereits versendeten Nachrichten
     */
    loadChatHistory(token: string, benutzerToken: string) {
        return this.chatHub.loadChatHistory(token, benutzerToken);
    }

    rueckrufBearbeiten(rueckrufgesuch: RueckrufgesuchContainer): Promise<void> {
        return this.chatHub.rueckrufbearbeiten(rueckrufgesuch);
    }

    //TODO: Mehr folgt... Dateiupload usw

    private uploadSettingConfiguration: UploadSettingConfiguration;

    async getAllowedFileTypes() {
        if (!this.uploadSettingConfiguration) {

        }
    }


    async uploadFile(file: File, token: string, benutzerToken: string) {
        if (!this.uploadSettingConfiguration) {
            let result = await this.allowedUploadSettingsService.get().toPromise();
            if (result.status === 200) {
                this.uploadSettingConfiguration = result.data;
            }
        }

        var extension = file.name.substring(file.name.lastIndexOf('.'));

        if (this.uploadSettingConfiguration.allowedFileExtensions.indexOf(extension) > -1) {
            this.uploadInProgress.next(true);
            this.notificationService.notifySuccess('Upload gestartet.');
            try {
                var result = await this.uploadFileHttpService.post(file, token, benutzerToken)
                    .toPromise();


                // console.log('uploadFileHttpService status', result.status);
                if (result.status === 400) {
                    this.notificationService.notifyError(result.data.message);
                } else if (result.status === 413) {
                    this.notificationService.notifyError('Der Upload ist zu groß.');
                }
            } catch (error) {
                if (error && error.status && error.status == 413) {
                    this.notificationService.notifyError('Der Upload ist zu groß.');
                } else {
                    throw new Error(error.message);
                }
            } finally {
                this.uploadInProgress.next(false);
            }
        } else {
            this.notificationService.notifyError('Dateiendung nicht erlaubt. Erlaubte Dateien: ' + this.uploadSettingConfiguration.allowedFileExtensions.toString());
        }

    }


}
