import { Component, Input} from '@angular/core';
import { DownloadService } from '../services/download.service';

@Component({
  selector: 'download',
  templateUrl: './download.component.html'
})

export class DownloadComponent {
  @Input() token: string;
  @Input() blobId: number;
  @Input() text: string;
  @Input() iconPath: string;
  @Input() iconPathInactive: string;

  constructor(private downloadService: DownloadService) {
  }

  async doDownload() {
    await this.downloadService.download(this.token, this.blobId);
  }


  calculateDisplayType(type: number) {
    let ret: boolean = false;
    switch (type) {
      case 1:
        if ((this.blobId !== null && this.blobId > 0) && this.iconPathIsSet()) {
          ret = true;
        }
        break;
      case 2:
        if ((this.blobId === null || this.blobId <= 0) && this.iconPathInactiveIsSet()) {
          ret = true;
        }
        break;
      case 3:
        if ((this.blobId !== null && this.blobId > 0) && !this.iconPathIsSet()) {
          ret = true;
        }
        break;
      case 4:
        if ((this.blobId === null || this.blobId <= 0) && !this.iconPathIsSet()) {
          ret = true;
        }
        break;
    }
    return ret;
  }

  iconPathIsSet() {
    return typeof this.iconPath !== 'undefined' && this.iconPath !== null && this.iconPath !== '';
  }

  iconPathInactiveIsSet() {
    return typeof this.iconPathInactive !== 'undefined' && this.iconPathInactive !== null || this.iconPathInactive !== '';
  }
}