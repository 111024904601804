// ######################################
// ############## Globales ##############
// ######################################


export class GlobalFunctions {

    public static maxAllowedConferenceMembers = 20;

    public static displayHtmlComponent = false;
    public static debugJsonOnForm = false;
    public static chatverlaufDebug = false;
    public static inConferenceVariableLogging = false;
    public static ComponentLogging = false;
    public static ComponentMethodsLogging = false; // Methoden, die recht häufig ausgeführt werden.
    public static Warnings = false;
    public static ServiceLogging = false;

    // public static ComponentUnsubscribe: boolean = false;
    // public static ComponentOnInitLogging: boolean = false;
    // public static VariableCondititonLogging: boolean = false;
    // public static FormValueChangeLogging: boolean = false;


    public static ServiceLogger(obj: any): void {
        if (GlobalFunctions.ServiceLogging) {
            console.log(`${obj} `);
        }
    }

    public static ComponentLogger(obj: any): void {
        if (GlobalFunctions.ComponentLogging) {
            console.log(`${obj} `);
        }
    }

    // public static ComponentOnInitLogger(obj: any): void {
    //   if (GlobalFunctions.ComponentOnInitLogging) {
    //     console.log(`${obj} `);
    //   }
    // }


    // public static inConferenceVariableLogger(obj: any): void {
    //   if (GlobalFunctions.inConferenceVariableLogging) {
    //     console.log(`${obj} `);
    //   }
    // }

    // public static ComponentUnsubscribeLogger(obj: any): void {
    //   if (GlobalFunctions.ComponentUnsubscribe) {
    //     console.log(`${obj} `);
    //   }
    // }

    // public static FormValueChangeLogger(obj: any): void {
    //   if (GlobalFunctions.FormValueChangeLogging) {
    //     console.log(`${obj} `);
    //   }
    // }

}
