import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'vico-external-login',
  templateUrl: './external-login.component.html',
  styles: [
  ]
})
export class ExternalLoginComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
