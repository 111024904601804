import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import * as flatpickr from 'flatpickr';
import { ActivatedRoute } from '@angular/router';
import { StyleHttpService } from './generated/style-http.service';
import { AnrufService } from './services/anruf.service';
import { isNullOrEmpty } from '@oevermann/core';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { HttpService } from '@oevermann/angular';
import { lang } from 'moment';
import { LanguageService } from './services/language.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {

  loaded = false;
  seitentitel = '';

  constructor(private route: ActivatedRoute,
    private styleHttpService: StyleHttpService,
    private primeNGConfig: PrimeNGConfig,
    private languageService: LanguageService,
    private http: HttpService,
    private anrufService: AnrufService) {
  }

  async ngOnInit() {
    window.addEventListener('orientationchange', function (e) {
      // console.log('orientationchange', e);
      const originalBodyStyle = getComputedStyle(document.body).getPropertyValue('display');
      document.body.style.display = 'none';
      setTimeout(function () {
        document.body.style.display = originalBodyStyle;
      }, 10);
    });

    const anrufGruppe = this.route.params["anrufgruppe"];

    if (!isNullOrEmpty(anrufGruppe)) {
      this.anrufService.GetAnrufGruppeIdByName(anrufGruppe).subscribe(o => {
        if (o.status === 200) {
          this.createCustomStyleLinkTag(o.data);
        }
      });
    } else {
      this.createCustomStyleLinkTag();
    }

    const response = await this.styleHttpService.getSeitenTitel().toPromise();
    if (response.status === 200) {
      this.seitentitel = response.data;
      document.title = this.seitentitel;
    }

    // das besser danach laden: falls ressourcen nicht werden englische ressourcen angezeigt
    // erzeugt trotzdem ständiges neu laden auf preview, wieso?
    // falsche ressourcen werden doch einfach nich geladen?!
    // this.languageService.loadPrimeNg();
    // this.http.get<Translation>('assets/i18n/de-de.json', 'json').subscribe((json) => {
    //   this.primeNGConfig.setTranslation(json.body);
    // });
  }

  private createCustomStyleLinkTag(anrufgruppeId?: number) {
    const cs = <HTMLLinkElement>document.getElementById("customStyle");
    if (cs != null && cs.parentNode != null) {
      cs.parentNode.removeChild(cs);
    }
    const head: HTMLHeadElement = <HTMLHeadElement>document.getElementsByTagName("head")[0];
    const layoutLink: HTMLLinkElement = <HTMLLinkElement>document.createElement("link");
    layoutLink.id = "customStyle";
    layoutLink.rel = "stylesheet";
    layoutLink.type = "text/css";
    layoutLink.onload = () => {
      console.log('this.loaded 2');
      this.loaded = true;
    };
    layoutLink.href = "/api/style" + (anrufgruppeId > 0 ? "?anrufgruppeId=" + anrufgruppeId : "");
    head.appendChild(layoutLink);
  }

}