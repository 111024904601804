<div id="cockpit" class="flex">
  <nav id="mainnav">
    <div id="logo"><img [src]="configuration.logoBackendUrl" alt="Logo" /></div>
  </nav>
  <div id="content">
    <header class="clearfix"></header>
    <main>
      <div class="scrollableScroll height100 clearfix">
        <div class="span6_of_6 box">
          <h3>Zugriff verweigert</h3>
          <div class="row">
            Sie verfügen nicht / nicht mehr über die ausreichende Berechtigung, um die angeforderte Seite zu laden.
          </div>
          <div class="row">
            <ul>
              <li>Falls Sie die angeforderte Seite z.B. als Favorit gespeichert oder die Url aus einer E-Mail kopiert haben, überprüfen Sie die Url in der Adresszeile Ihres Browsers.</li>
              <li>Melden Sie sich ggf. mit einem anderen Login an, dass die erforderliche Berechtigung enthält.</li>
            </ul> 
          </div>
          <div class="row clearfix">
            <button (click)="backToLogin($event)" class="actionbutton inline">Zurück zum Login</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>

