<div class="pager">
  <div class="pageselect clearfix">
    <button class="pagerbutton prev" [disabled]="value.index < 2" (click)="changeIndex(value.index - 1)"><img src="/assets/prev.svg" class="linkicon" alt="Vorige Seite" title="Vorige Seite" /></button>
    <button class="pagerbutton next" [disabled]="value.index > total / value.size" (click)="changeIndex(value.index + 1)"><img src="/assets/next.svg" class="linkicon" alt="Nächste Seite" title="Nächste Seite" /></button>
    <div class="pagertext">Datensatz {{(value.index - 1) * value.size + 1}} - {{(value.index - 1) * value.size + count}} von {{total}}</div>
  </div>
  <div class="pagesize">
    <label>Anzahl pro Seite</label>
    <select (change)="changeSize($event)" [value]="value.size">
      <option>10</option>
      <option>20</option>
      <option>50</option>
      <option>100</option>
    </select>
  </div>
</div>