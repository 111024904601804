import { Pipe } from '@angular/core';
import { ConferenceStreamData } from '../services/conference.hub';

@Pipe({
  name: 'teilnehmername'
})
export class TeilnehmerNamePipe {

  transform(stream: ConferenceStreamData, forTeilnehmer: boolean, teilnehmerName?: string) {
    if (forTeilnehmer === undefined) {
      return '';
    }

    if (!stream) {
      if (forTeilnehmer) {
        return '';
      }
      else {
        return teilnehmerName || '';
      }
    }

    if (forTeilnehmer) {
      return stream.isAnonymousForOthers ? 'Teilnehmer ' + stream.teilnehmerId : stream.userName;
    } else {
      return stream.userName;
    }
  }

}