import { Injectable } from '@angular/core';
import { GespraechsTyp } from '../enums/gespraechstyp';
import { RueckrufHttpService } from '../generated/rueckruf-http.service';
import { GespraechHttpService } from '../generated/gespraech-http.service';
import { RueckrufgesuchRequestContainer } from '../generated/types';
import { AnrufenRequest } from '../anrufenrequest';
import { ChatHub } from './chat.hub';
import { CapabilityService } from './capability.service';
import { AgentAddRequest } from '../shared/models/agent-add-request';

@Injectable()
export class AnrufService {

  constructor(private chatHub: ChatHub, private capabilityService: CapabilityService, private rueckrufHttpService: RueckrufHttpService, private gespraechHttpService: GespraechHttpService) {
  }

  public anrufGruppeId: number;

  async StartAnruf(name: string, gespraechsTyp: GespraechsTyp, referrer: string, einladungToken: string, anrufGruppe: string, additionalInfo: string) {
    var ausstattung = await this.capabilityService.getArbeitsplatzausstattung();
    var anrufenRequest: AnrufenRequest = {
      Name: name,
      Ausstattung: ausstattung,
      Referrer: referrer,
      Gespraechstyp: gespraechsTyp,
      EinladungToken: einladungToken,
      Anrufgruppe: anrufGruppe,
      AdditionalInfo: additionalInfo
    };

    var anrufenResult = await this.chatHub.anrufen(anrufenRequest);
    return anrufenResult;
  }

  //todo: recheck
  async StartAgentAnruf(teilnehmerId: number, gespraechsToken: string) {
    //var ausstattung = await this.capabilityService.getArbeitsplatzausstattung();
    var agentAddRequest: AgentAddRequest = {
      teilnehmerId: teilnehmerId,
      gespraechsToken: gespraechsToken
    };

    var anrufenResult = await this.chatHub.agentAnrufen(agentAddRequest);
    return anrufenResult;
  }

  async RueckrufEinstellen(anrufgruppeId: number, benutzerToken: string, nummer: string, email: string, name: string, extra: string) {
    const rueckrufRequest: RueckrufgesuchRequestContainer = { anrufgruppeId, benutzerToken: benutzerToken, nummer: nummer, name: name, eMail: email, extra: extra }
    return await this.rueckrufHttpService.post(rueckrufRequest).toPromise();
  }
  
  async GetGespraech(gespraechsToken: string, loadAdditionalInformation: boolean = false) {
    return await this.gespraechHttpService.get(gespraechsToken, loadAdditionalInformation).toPromise();
  }

   GetAnrufGruppeIdByName(name: string) {
     return this.gespraechHttpService.getAnrufGruppeId(name);
  }

  Beenden(gespraechsToken: string, benutzertoken: string){
    this.chatHub.beenden(gespraechsToken, 2, benutzertoken);
  }

}
