import { Component, Input, Output, EventEmitter } from "@angular/core";


/** Enthält Informationen darüber, welche Seite angezeigt werden soll. */
export interface Paging {

  /** Die Anzahl der Elemente die auf einer Seite möglich sind. */
  size: number;

  /** Der Index der Seite. */
  index: number;
}

/**
 * Stellt Funktionen für die Auswahl einer Seite bereit.
 */
@Component({
  selector: 'shared-paging',
  templateUrl: './paging.component.html'
})
export class PagingComponent {

  /** Das Paging. */
  @Input() value: Paging;

  /** Die Anzahl aller Elemente. */
  @Input() total: number;

  /** Die Anzahl der Elemente auf der aktuellen Seite.  */
  @Input() count: number;

  /** Liefert Änderungen an der Seite. */
  @Output() change: EventEmitter<{ size: number; index: number; }> = new EventEmitter(); 

  /**
   * Ändert die Seitengröße.
   * @param event Das change-Event eine HTMLSelectElement.
   */
  changeSize(event: Event) {
    event.stopPropagation();
    const size = Number((event.target as HTMLSelectElement).value);
    this.change.next({ ...this.value, size, index: 1 });
  }

  /**
   * Ändert den Index der Seite.
   * @param index Der Index.
   */
  changeIndex(index : number) {
    this.change.next({ ...this.value, index });
  }
}