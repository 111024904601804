<ng-container *ngFor="let part of parts">
    <ng-container [ngSwitch]="part.type">
        <ng-container *ngSwitchCase="'text'">{{part.text}}</ng-container>
        <ng-container *ngSwitchCase="'zeilenumbruch'"><br *ngFor="let i of Arr(part.count).fill(1)" /></ng-container>
        <ng-container *ngSwitchCase="'rueckruflink'">
            <shared-rueckruflink-output [token]="token" [text]="part.text" (clicked)="rueckrufLinkClicked($event)"></shared-rueckruflink-output>
        </ng-container>
        <ng-container *ngSwitchCase="'systeminfolink'"><shared-systeminfolink-output [token]="token" [text]="part.text" (onAddSysteminfo)="addSysteminfo($event)"></shared-systeminfolink-output>
        </ng-container>
        <ng-container *ngSwitchCase="'link'"><a [href]="part.href" [target]="_blank">{{part.text}}</a></ng-container>
        <ng-container *ngSwitchCase="'email'"><a [href]="part.email">{{part.text}}</a></ng-container>
        <ng-container *ngSwitchCase="'download'"><download [blobId]="part.blobId" [token]="token" [text]="'Es wurde die Datei ' + part.text + ' hochgeladen.'"></download>
        </ng-container>
        <ng-container *ngSwitchCase="'strong'"><strong>{{part.text}}</strong>
        </ng-container>
        <ng-container *ngSwitchCase="'dokument'">{{part.text}} <a style="cursor: pointer" (click)="signDocument(part.url)">{{part.name}}.pdf</a></ng-container>
        <ng-container *ngSwitchCase="'signedDokument'">Unterschrieben: <a style="cursor: pointer" (click)="openDocument(part.data)">{{part.text}}.pdf</a></ng-container>
    </ng-container>
</ng-container>
