import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { EinladungHttpService } from "../generated/einladung-http.service";
import { EinladungItem } from "../generated/types";

@Injectable()
export class AgentEinladungBearbeitenResolver implements Resolve<EinladungItem> {

  constructor(private httpService: EinladungHttpService) {

  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<EinladungItem> {

    const id = parseInt(route.params.id);

    const result = await this.httpService.getById(id).toPromise();

    if (result.status === 200) {
      return result.data;
    }

    throw result;
  }
}