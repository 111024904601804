import { Inject, Injectable } from '@angular/core';
import { DialogServiceToken, DialogService } from '@oevermann/angular';
import { ConfirmationService } from 'primeng/api';
import { filter, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfirmDialogService {

  constructor(
    private confirmationService: ConfirmationService,
    // @Inject(DialogServiceToken) public dialogService: DialogService,
    // public dialogService: DialogService,
    ) { }


  public showConfirmDialog(): Observable<boolean>{
    const subject = new Subject<boolean>();
    this.confirmationService.confirm({
      // header:'Ungespeicherte Änderungen',
      message: 'Sollen die Änderungen gespeichert werden?',
      acceptLabel:'Änderungen speichern',
      rejectLabel:'Verwerfen',
      
      accept: () => {
          subject.next(true);
          subject.complete();
      },
      reject: () => {
          subject.next(false);
          subject.complete();
      }
    });
    return subject.asObservable();
  }

  public openDialogService(){
    // problem war hier der Rückgabewert, daher mit confirm-dialog Komponente gelöst.
    // this.dialogService.open({
    //   contentHtml: '<center>xxx</center>' + '<br>',
    //   // 'Der Erfassungsmodus muss von ' + aktuellerErfassungsModusName + ' zu ' + requiredErfassungsmodusName + ' geändert werden. ' +
    //   // '<b>Bereits im bestehenden Erfassungsmodus gemachte Eingaben gehen dabei verloren.</b> Sind Sie damit einverstanden?',
    //   translate: false,
    //   config: {
    //     header: ('Ungespeicherte Änderungen')
    //   },
    //   buttons: [
    //     {
    //       id: 'ja',
    //       label: ('speichern'),
    //       class:'default'
    //     },
    //     {
    //       id: 'nein',
    //       label: ('verwerfen'),
    //       class:'p-button-secondary'
    //     }
    //   ]
    // }).pipe(filter(id => id === 'ja')).subscribe(() => {
    //   //this.setErfassungsmodus(key, requiredErfassungsmodus);
    //   //setValue();
    // });
  }
}
