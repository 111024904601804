import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { GespraechContainer } from "./types";
import { HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AnrufVerpasstHttpService {

  constructor(private httpService : HttpService) { }

  post() { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/AnrufVerpasst`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as GespraechContainer[] } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
