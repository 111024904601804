import { Observable, BehaviorSubject, Subscription, fromEvent, Subject } from 'rxjs';
import { Inject, Injectable, NgZone, OnDestroy } from '@angular/core';
import { AuthServiceToken, ConsoleErrorLogService, MemberService } from '@oevermann/angular';
import { share } from 'rxjs/operators';

type HubName = 'chat' | 'webRtc' | 'conference';

export type SignalRState = 'closed' | 'connected' | 'slow' | 'disconnected';

const SIGNALR_CONNECTION_ID = 'SIGNALR_CONNECTION_ID';

export interface WebRtcClientConfiguration {
  iceServers: RTCIceServer[];
  maxBandwidthSteps: number[];
  idealWidth?: number;
  idealHeight?: number;
  idealFrameRate?: number;
}

@Injectable()
export class SignalRService implements OnDestroy {

  private subscription = new Subscription();
  private connection: SignalR.Hub.Connection;
  private hubs: { [P in HubName]: SignalR.Hub.Proxy };
  private started: JQueryPromise<string>;
  private connecting = false;
  private pingHandle: number | null;
  private _prevSignalRConnectionId: string | null = null;
  public $connectionAvailabe = new Subject<boolean>();
  public connectionAvailabe: boolean;
  private disconnectHandling;

  state = new BehaviorSubject<SignalRState>('closed');

  private isEventTarget = (sourceObj: any): sourceObj is EventTarget => {
    return !!sourceObj && typeof sourceObj.addEventListener === 'function' && typeof sourceObj.removeEventListener === 'function';
  }

  constructor(private memberService: MemberService, private zone: NgZone) {
    if (this.isEventTarget(window)) {
      this.subscription.add(fromEvent(window, 'unload').subscribe(() => {
        if (this.connection) {
          this.connection.stop();
        }
      }));
    }

    // überprüfen auf Internetverbindung.
    window.addEventListener('offline',
      () => {
          this.$connectionAvailabe.next(false);
          this.connectionAvailabe = false;

      }
    );
    window.addEventListener('online',
      () => {
          this.$connectionAvailabe.next(true);
          this.connectionAvailabe = true
      }
    );

  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  public async renewConnection() {
    // this.connection = null; // alte mögliche Verbindung löschen.
    this.reconnect();
  }

  async reconnect() {
    this.started = null;
    await this.ensureConnected();
  }

  get connectionId() {
    return this.connection
      ? this.connection.id
      : null;
  }

  get prevSignalRConnectionId() {
    return this._prevSignalRConnectionId;
  }


  private async ensureConnected() {
    if ((!this.started)) { 
      if (!this.connecting) {
        this.connecting = true;
        // console.log('connecting wird gesetzt.');
        if (this.pingHandle != null) {
          clearInterval(this.pingHandle); // wenn connected. Ping interval löschen.
          this.pingHandle = null;
          console.log('Ping handle gelöscht');
        }

        if (!this.connection) { // wird in der SPA nur einmal gesetzt
          // console.log('ensureConnected: new hubConnection');

          this.connection = $.hubConnection();
          this.hubs = {
            chat: this.connection.createHubProxy("ChatHub"),
            webRtc: this.connection.createHubProxy("WebRtcHub"),
            conference: this.connection.createHubProxy("ConferenceHub")
          };

          this.connection.logging = true;
          this.connection.connectionSlow(() => {
            // console.log('signalR slow');
            this.state.next('slow');
          });
          this.connection.reconnected(() => {
            // console.log('signalR reconnected');
            this.disconnectHandling = null;
            this.state.next('connected');
          });
          this.connection.reconnecting(() => {
            // console.log('signalR reconnecting');
          });
          this.connection.disconnected(() => {
            this.started = null;
            // console.log('signalR disconnected');
            
            this.ensureConnected();

            this.state.next('disconnected');
          // Versuch gescheitert Interval zu setzen, 
          // indem die signalr verbindung nach einem disconnect einfach wieder erzeugt wird.
          // Problem negotiate wird nach Disconnect nicht wieder aufgebaut.
          //   this.disconnectHandling = setInterval(function() {
          //     console.log('versuch connection starten');
          //     this.connection.start();
          // }, 5000); // Restart connection after 5 seconds.
          });
          this.hubs.chat.on('error', (message) => { console.log("error:" + message); });
          this.hubs.webRtc.on('error', (message) => { console.log("error:" + message); });
          this.hubs.conference.on('error', (message) => { console.log("error:" + message); });
        }

        this._prevSignalRConnectionId = sessionStorage.getItem(SIGNALR_CONNECTION_ID) || null;

        // console.log('ensureConnected: start connection old connectionId' + this._prevSignalRConnectionId);
        // if (!this.started) { // passiert  bei einem reconnect
        //   // console.log("connection started");
        //   this.started = this.connection.start();
        // }
        this.started = this.connection.start();

        
        this.started.then(() => {
          // console.log('connection started: ', this.connection);
          this.pingHandle = setInterval(() => {
            this.invoke('chat', 'Ping').catch((err) => {
              // lokal wird das hier ohne internet ausgeführt, internet slow kommt nie.
              console.log('SIGNALR PING FAILED:', err)
            });
          }, 5000) as any;

          // console.log('connection id: ' + this.connection.id);
          // console.log('clientProtocol', this.connection.clientProtocol);
          // console.log('transport.name', this.connection.transport.name);
          sessionStorage.setItem(SIGNALR_CONNECTION_ID, this.connection.id);

          this.state.next('connected');
        }, (reason) => {
          console.warn('connection error', reason);
        });
      }
    }
    await this.started;
    this.connecting = false;
  }

  /**
   * Ruft eine Methode auf einem Hub und kümmert sich dabei um Verbindungsaufbau und fehlende Authorisierung. 
   * @param hub Der Hub.
   * @param methodName Der Name der Methode.
   * @param args Die Parameter.
   * verwendete Methodnames: Ping, chat,....
   * Der Ping Handle läuft im Hintergrund weiter.
   */
  async invoke<T = void>(hubName: HubName, methodName: string, ...args: any[]): Promise<T> {
    // console.log('invoke method: ' + methodName);
    await this.ensureConnected();
    const hub = this.hubs[hubName];
    try {
      const authenticationInfo = await this.memberService.getAuthenticationInfo();
      if (authenticationInfo) {
        hub.state = { access_token: authenticationInfo.access_token };
      }
      return await hub.invoke(methodName, ...args);
    } catch (error) {
      // console.warn('not authenticated!');
      if (hub.state && hub.state.unauthorized) {
        delete hub.state.unauthorized;
        await this.memberService.logout();
      }
      throw error;
    }
  }

  // reagieren auf verschiedene Events
  observe<T extends any[]>(hubName: HubName, eventName: string): Observable<T> {

    var observable = new Observable<T>(subscriber => {

      const callback = (...args: T) => this.zone.run(() => subscriber.next(args));

      let hub: SignalR.Hub.Proxy;

      this.ensureConnected()
        .then(() => {
          hub = this.hubs[hubName];
          hub.on(eventName, callback);
          console.log(`[SignalR]: Subscribed to ${hubName}.${eventName}`);
        })
        .catch(error => subscriber.error(error));

      return () => {
        if (hub) {
          hub.off(eventName, callback);
          console.log(`[SignalR] Unsubscribed from ${hubName}.${eventName}`);
        }
      };
    });

    return observable
      .pipe(share()); // Damit wird nicht bei jedem "subscribe" uns ein weiteres mal beim Hub registrieren.
  }
}
