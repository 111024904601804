import { Component, OnDestroy } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Subscription, Subject, Observable, of } from "rxjs";
import { GlobalFunctions } from "./global-functions";

@Component({
  template: ''
}) 

export abstract class BaseComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected classame: string= (<any>this).constructor.name;;
  protected subscription = new Subscription(); 
  public displayHtmlComponent = GlobalFunctions.displayHtmlComponent;
  public debugJsonOnForm = GlobalFunctions.debugJsonOnForm;

  constructor(
    // protected confirmationService: ConfirmationService
    
    ) {

  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.subscription.unsubscribe();
  }

  public LogComponentMethods(obj: any): void {
    if (GlobalFunctions.ComponentMethodsLogging) {

     if (this.classame){
      console.log(`class: ${this.classame}; ${obj}  `);
     } else {
      console.log(`${obj} `);
     }  
    }
  }

  public tryClose(): Observable<string> {
    return of ('discard');
  }

  public LogInConferenceVariable(obj: any,objToStringify: any=undefined): void {
    if (GlobalFunctions.inConferenceVariableLogging) {
      console.log(`${obj} `);
      if (objToStringify){
        console.log(JSON.stringify(objToStringify));
      }
    }
  }
  public displayHtmlComponentName(name: string=''): string {
    if (name){
      return (name);
    }
    return this.classame + '.html'

  }

  // public LogInConferenceVariable(obj: any): void {
  //   if (GlobalFunctions.inConferenceVariableLogging) {
  //     console.log(JSON.stringify(obj));
  //   }
  // }


  

}