<div class="box filter clearfix">
  <div class="col span_3_of_6">
    <label for="search">Suche</label>
    <input id="search" type="text" [value]="value" (input)="inputs.next($event)" />
  </div>
  <div class="col span_1_of_6" *ngIf="isAgent != null">
    <input id="isAgent" type="checkbox" [(ngModel)]="isAgent" (click)="onAgentClick($event)" /><label for="isAgent">Agent</label>
  </div>
  <div class="col span_1_of_6" *ngIf="isKunde != null">
    <input id="isKunde" type="checkbox" [(ngModel)]="isKunde" (click)="onKundeClick($event)" /><label for="isKunde">Kunde</label>
  </div>
  <div class="col span_1_of_6">
  </div>
</div>