import { Injectable } from '@angular/core';
import { BrowserDownloadService } from './browserdownload.service';
import { DownloadHttpService } from '../generated/download-http.service';
import { ChatStatistikHttpService } from '../generated/chat-statistik-http.service';
import { DownloadRequestContainer } from '../generated/types';
import { getFilenameFromHeaderString } from '../shared/helpers';
import { HttpErrorResponse } from '@angular/common/http';
import { NotificationService } from '../shared/notification.service';
import { BENUTZERTOKEN } from '../consts';

@Injectable()
export class DownloadService {
  constructor(
    private chatStatistikHttpService: ChatStatistikHttpService,
    private downloadHttpService: DownloadHttpService,
    private browserDownloadService: BrowserDownloadService,
    private notificationService: NotificationService) { };

  async download(token: string, blobId: number) {
    const response = await this.getBlob(token, blobId);
    if (response.status === 200) {
      this.browserDownloadService.saveOrOpenBlob(response.data.blob, response.data.filename);
    } else if (response.status === 400) {
      this.notificationService.notifyError(response.data.message);
    } else {
      throw response;
    }
  }

  async downloadChatStatistik(von: Date, bis: Date) {
    const response = await this.getChatStatistikBlob(von, bis);
    if (response.status === 200) {
      this.browserDownloadService.saveOrOpenBlob(response.data.blob, response.data.filename);
    }
  }

  async downloadBewertungen(von: Date, bis: Date, anrufgruppeId: number) {
    const response = await this.getChatBewertungenBlob(von, bis, anrufgruppeId);
    if (response.status === 200) {
      this.browserDownloadService.saveOrOpenBlob(response.data.blob, response.data.filename);
    }
  }

  async downloadRueckrufgesuche(von: Date, bis: Date, anrufgruppeId: number) {
    const response = await this.getChartStatistikRueckrufgesuche(von, bis, anrufgruppeId);
    if (response.status === 200) {
      this.browserDownloadService.saveOrOpenBlob(response.data.blob, response.data.filename);
    }
  }

  async downloadKonferenzStatistik(von: Date, bis: Date) {
    const response = await this.getKonferenzStatistikBlob(von, bis);
    if (response.status === 200) {
      this.browserDownloadService.saveOrOpenBlob(response.data.blob, response.data.filename);
    }
  }

  async downloadAktionStatistik(von: Date, bis: Date, teilnehmerId: number) {
    const response = await this.getAktionStatistikBlob(von, bis, teilnehmerId);
    if (response.status === 200) {
      this.browserDownloadService.saveOrOpenBlob(response.data.blob, response.data.filename);
    }
  }

  private async getChatStatistikBlob(von: Date, bis: Date) {
    return await this.chatStatistikHttpService.post(von.toDateString(), bis.toDateString()).toPromise();
  }

  private async getChartStatistikRueckrufgesuche(von: Date, bis: Date, anrufgruppeId: number) {
    return await this.chatStatistikHttpService.postRueckrufgesuche(von.toDateString(), bis.toDateString(), anrufgruppeId).toPromise();
  }

  private async getKonferenzStatistikBlob(von: Date, bis: Date) {
    return await this.chatStatistikHttpService.postKonferenz(von.toDateString(), bis.toDateString()).toPromise();
  }

  private async getAktionStatistikBlob(von: Date, bis: Date, teilnehmerId: number) {
    return await this.chatStatistikHttpService.postAktion(von.toDateString(), bis.toDateString(), teilnehmerId).toPromise();
  }

  private async getChatBewertungenBlob(von: Date, bis: Date, anrufgruppeId: number) {
    return await this.chatStatistikHttpService.postBewertungen(von.toDateString(), bis.toDateString(), anrufgruppeId).toPromise();
  }

  private async getBlob(token: string, blobId: number) {
    const request: DownloadRequestContainer = { token: token, blobId: blobId, benutzerToken: sessionStorage.getItem(BENUTZERTOKEN) };
    return await this.downloadHttpService.post(request).toPromise();
  }
}
