import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthServiceToken, MemberService } from '@oevermann/angular';
import { from, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { MemberHttpService } from '../generated/member-http.service';

@Injectable({
  providedIn: 'root'
})
export class ExternalLoginGuard implements CanActivate {

  constructor(private memberService: MemberHttpService,
              @Inject(AuthServiceToken) private authService: MemberService,
              private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!route.params?.token) {
      return this.router.parseUrl('/');
    }

    return this.memberService.loginWithToken(route.params.token).pipe(
      catchError(err => of(null)),
      map(response => response?.data as string),
      switchMap(cacheKey => from(this.authService.login({
        username: '',
        password: '',
        externalLoginCacheKey: cacheKey
      }))),
      map(result => this.router.parseUrl('/')));
  }

}
