import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TypedFormArray, SingleStyleModelForm, createStyleEditModelForm } from "../generated/forms";
import { SingleStyleModel, Option } from "../generated/types";
import { BaseComponent } from "./base.component";


@Component({
  selector: 'shared-style-detail',
  templateUrl: './style.detail.component.html'
})
export class StyleDetailComponent extends BaseComponent implements OnInit {


  @Input() styles: TypedFormArray<SingleStyleModel[], SingleStyleModel>;

  @Output() public change: EventEmitter<void> = new EventEmitter<void>();
  textDecorationOptions: Option[] = [
    { label: "Unterstrichen", value: "underline" },
    { label: "Ohne", value: "none"}
  ]
  
  ngOnInit(): void {

    // this.form = createStyleEditModelForm(this.styles);
    this.subscription.add(this.styles.valueChanges.subscribe(() => {
      this.changeEvent();
    }));
    
    console.log(this.styles.value);
  }
  changeEvent(){
    console.log('change Event');

  }
  hasChanged(){
    this.change.next();
  }
}