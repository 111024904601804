import { ActivityComponent } from "./activity.component";
import { NgModule, ModuleWithProviders } from "@angular/core";
import { ErrorComponent } from "./error.component";
import { CommonModule } from "@angular/common";
import { NotificationComponent } from "./notification.component";
import { NotificationService } from "./notification.service";
import { EmojiPickerComponent } from "./emojipicker.component";
import { DownloadComponent } from "./download.component";
import { HttpService } from "./http.service";
import { TimesPipe } from "./times.pipe";
import { AutofocusDirective } from './autofocus.directive';
import { PagingComponent } from "./paging.component";
import { SearchComponent } from "./search.component";
import { ChatVerlaufComponent } from "./chatverlauf.component";
import { ChatNachrichtComponent } from "./chatnachricht.component";
import { ChatAktionenComponent } from "./chataktionen.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AnrufService } from "../services/anruf.service";
import { MemberHttpService } from "../generated/member-http.service";
import { AngemeldeteAgentenHttpService } from "../generated/angemeldete-agenten-http.service";
import { TeilnehmerHttpService } from "../generated/teilnehmer-http.service";
import { AgentHttpService } from "../generated/agent-http.service";
import { ChatStatistikHttpService } from "../generated/chat-statistik-http.service";
import { DownloadHttpService } from "../generated/download-http.service";
import { GespraechHttpService } from "../generated/gespraech-http.service";
import { NachrichtenTemplateHttpService } from "../generated/nachrichten-template-http.service";
import { RueckrufHttpService } from "../generated/rueckruf-http.service";
import { UploadFileHttpService } from "../generated/upload-file-http.service";
import { MultiSelectComponent } from "./multi-select-component";
import { EllipsisPipe } from "./ellipsis.pipe";
import { TemplateComponent} from './template.component';
import { DialogServiceToken,DialogService, MemberModule, PipesModule } from "@oevermann/angular";
import { RatingModule } from 'primeng/rating';
import { BewertungComponent } from '../shared/bewertung.component';
import { StyleHttpService } from "../generated/style-http.service";
import { DropdownInputComponent } from "./dropdown-input.component"
import { UploadComponent } from "./upload.component";
import { Base64UploadComponent } from "./base64-upload.component";
import { ImageComponent } from "./image.component";
import { SeitentextOutputComponent } from "./outputs/seitentext-output.component";
import { RueckrufLinkOutputComponent } from "./outputs/rueckruflink-output.component";
import { SysteminfoLinkOutputComponent } from "./outputs/systeminfolink-output.component";
import { SeitentextHeadlineOutputComponent } from "./outputs/seitentext-headline-output.component";
import { ConfigurationService } from "../services/configuration.service";
import { ConfigurationHttpService } from "../generated/configuration-http.service";
import { CapabilityService } from "../services/capability.service";
import { MeineRueckrufgesuchePipe } from "./meine-rueckrufgesuche.pipe";
import { StyleDetailComponent } from "./style.detail.component";
import { ColorpickerInputComponent } from "./colorpicker-input.component";
import { ColorPickerModule } from 'primeng/colorpicker';
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ConfirmationService } from 'primeng/api'
import { StylesNamePipe } from "./styles-name.pipe";
import { TeilnehmerService } from "../services/teilnehmer.service";
import { DraggableDirective } from "./draggable.directive";
import { ConferenceStartComponent } from "./conference/conference.start.component";
import { ConferenceStartResolver } from "./conference/conference.start.resolver";
import { ConferenceMembersComponent } from "./conference-members.component";
import { DatepickerInputComponent } from "./inputs/datepicker-input.component";
import { CalendarModule } from 'primeng/calendar';
import { MediaSettingsComponent } from './mediasettings.component';
import { ConferenceTeilnehmerEinladungComponent } from './conference/conference.teilnehmer.einladung.component';
import { VUMeterComponent } from './vumeter.component';
import { TeilnehmerNamePipe } from './TeilnehmerName.pipe';
import {AgendInvoicePopupComponent} from './agend-invoice-popup/agend-invoice-popup.component';
import {DialogModule} from 'primeng/dialog';
import {CheckboxModule} from 'primeng/checkbox';
import {RadioButtonModule} from 'primeng/radiobutton';
import { AgentVerpassteAnrufeComponent } from "../agent/agent-verpasste-anrufe.component";
import { AnrufVerpasstHttpService } from "../generated/anruf-verpasst-http.service";
import { ConferenceAgentAddListComponent } from './conference/conference-agent-add-list.component'
import { ConferenceAddAgentenHttpService } from "../generated/conference-add-agenten-http.service";
import { AngemeldeteAgentenFuerAnrufHttpService } from "../generated/angemeldete-agenten-fuer-anruf-http.service";
import { UploadNotifier } from "./upload-notifier";
import { FlagsSelectComponent } from "./inputs/flags-select.component";
import { EnsureSaveService } from "./ensure-save.service";
import { ConfirmDialogService } from "./service/confirm-dialog.service";;
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component'
import { KonferenzdauerHttpService } from "../generated/konferenzdauer-http.service";
import { BewertungstypHttpService } from "../generated/bewertungstyp-http.service";
import { AnrufgruppenManagerHttpService } from "../generated/anrufgruppen-manager-http.service";
import { TeilnehmerHistoryHttpService } from "../generated/teilnehmer-history-http.service";

const components = [
  ActivityComponent,
  ErrorComponent,
  NotificationComponent,
  EmojiPickerComponent,
  DownloadComponent,
  PagingComponent,
  SearchComponent,
  ChatVerlaufComponent,
  ChatAktionenComponent,
  TimesPipe,
  EllipsisPipe,
  TeilnehmerNamePipe,
  StylesNamePipe,
  MeineRueckrufgesuchePipe,
  ChatNachrichtComponent,
  AutofocusDirective,
  MultiSelectComponent,
  TemplateComponent,
  BewertungComponent,
  DropdownInputComponent,
  UploadComponent,
  Base64UploadComponent,
  ImageComponent,
  SeitentextOutputComponent,
  RueckrufLinkOutputComponent,
  SysteminfoLinkOutputComponent,
  SeitentextHeadlineOutputComponent,
  StyleDetailComponent,
  ColorpickerInputComponent,
  DraggableDirective,
  ConferenceStartComponent,
  ConferenceMembersComponent,
  DatepickerInputComponent,
  FlagsSelectComponent,
  MediaSettingsComponent,
  ConferenceTeilnehmerEinladungComponent,
  ConferenceAgentAddListComponent,
  VUMeterComponent
];

@NgModule({
    declarations: [
        components,
        AgendInvoicePopupComponent
,
        ConferenceAgentAddListComponent
,
        ConfirmDialogComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MemberModule,
        PipesModule,
        RatingModule,
        ColorPickerModule,
        ConfirmDialogModule,
        CalendarModule,
        DialogModule,
        CheckboxModule,
        RadioButtonModule
    ],
    providers: [
      UploadNotifier
    ],
    exports: [
        ...components,
        PipesModule,
        RatingModule,
        ConfirmDialogModule,
        AgendInvoicePopupComponent,
        ConfirmDialogComponent,
    ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        HttpService,
        MemberHttpService,
        AngemeldeteAgentenHttpService,
        AnrufgruppenManagerHttpService,
        AngemeldeteAgentenFuerAnrufHttpService,
        ConferenceAddAgentenHttpService,
        TeilnehmerHttpService,
        AgentHttpService,
        ChatStatistikHttpService,
        DownloadHttpService,
        GespraechHttpService,
        KonferenzdauerHttpService,
        BewertungstypHttpService,
        AnrufVerpasstHttpService,
        NachrichtenTemplateHttpService,
        RueckrufHttpService,
        UploadFileHttpService,
        NotificationService,
        CapabilityService,
        AnrufService,
        StyleHttpService,
        ConfigurationHttpService,
        ConfigurationService,
        ConfirmationService,
        TeilnehmerService,
        TeilnehmerHistoryHttpService,
        ConferenceStartResolver,
        EnsureSaveService,
        ConfirmDialogService,
        // DialogService,
        // { provide: DialogServiceToken, useClass: ConfirmDialogService }
      ]
    };
  }
}
