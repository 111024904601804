import { Injectable } from "@angular/core";
import { ConfigurationContainer } from "../generated/types";
import { ConfigurationHttpService } from "../generated/configuration-http.service";

@Injectable()
export class ConfigurationService {

  private configurationPromise: Promise<ConfigurationContainer> = null;

  constructor(private configurationHttpService: ConfigurationHttpService) { }

  /** Gibt ein Promise mit den Lizenzinformationen zurück. Die Informationen werden nur einmal geladen und dann gecached. */
  getLizenz() {
    return this.configurationPromise || (this.configurationPromise = this.configurationHttpService.get().toPromise().then(response => response.data));
  }
}