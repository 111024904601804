import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MonitorService } from '../../agent/monitor.service';
import { ConferenceAddAgentenHttpService } from '../../generated/conference-add-agenten-http.service';
import {AngemeldeterAgentContainer, ConfigurationContainer, TeilnehmerContainer} from '../../generated/types';
import { AnrufService } from '../../services/anruf.service';
import { BaseComponent } from '../base.component';
import {ConfirmationService} from 'primeng/api';

interface ParentData {
  configuration: ConfigurationContainer;
}

@Component({
  selector: 'vico-conference-agent-add-list',
  templateUrl: './conference-agent-add-list.component.html',
  styles: [
  ]
})
export class ConferenceAgentAddListComponent extends BaseComponent implements OnInit {

  @Input() gespraechsToken: string;
  @Input() teilnehmerCount: number; // todo: wird das aktualisiert?
  @Input() isConference: boolean;
  @Input() anrufgruppeId: number=0;
  @Input() benutzertoken: string;

  // todo: was mache ich, wenn ein Agent angerufen wird, dann erscheint er nicht mehr in der Liste, wieso?
  public maxAllowedConferenceMembers = 20;
  public agenten: AngemeldeterAgentContainer[];
  public angerufenerAgent:AngemeldeterAgentContainer;
  private subscription2 = new Subscription();

  public maxTeilnehmerReached = false

  constructor(
    private conferenceAddAgentenHttpService: ConferenceAddAgentenHttpService,
    private anrufService: AnrufService,
    private monitorService: MonitorService,
    private route: ActivatedRoute,
    private confirmationService: ConfirmationService) {
      super();
    }

  async ngOnInit(): Promise<void> {

    // erstmalige laden der Agenten.
    await this.loadAgentenListe();

    // Auf changes aller Agenten reagieren. Bereits vorhande Funktionalität der monitor seite nutzen.
    // zusätzlich noch mal auf Stataus online/In Vermittlung filtern. 
    this.subscription.add(this.monitorService.onPushAgenten.subscribe(a => {
      // anrufgruppen berücksichtigen.
      this.agenten = a.filter(x => x.status != 4 && x.anrufgruppen.filter( a => a.id == this.anrufgruppeId));
      if(this.angerufenerAgent){
        let ag = a.filter(x => x.id == this.angerufenerAgent.id)[0];
        if(ag.status == 4) {
          this.anrufService.Beenden(this.gespraechsToken, this.benutzertoken);
          this.angerufenerAgent = null;
        }
      }
    }));


    this.subscription.add(this.route.parent.data.subscribe((data: ParentData) => {
      this.maxAllowedConferenceMembers = (data.configuration.maxAllowedConferenceMembers ?? 20) + 0;
      this.maxTeilnehmerReached = this.maxAllowedConferenceMembers <= this.teilnehmerCount;

    }));
  }


  async loadAgentenListe() {

    this.conferenceAddAgentenHttpService.get(this.gespraechsToken).subscribe((response) => {

      if (response.status === 200) {
        this.agenten = response.data.filter((x => x.status != 4 && x.anrufgruppen.filter( a => a.id == this.anrufgruppeId)));
      }

    })

  }

  async startAgentAnruf(teilnehmer: AngemeldeterAgentContainer):Promise<void> {
    if (teilnehmer.status == 1) {
      
      //var anrufenResult = await this.anrufService.StartAgentAnruf(teilnehmer.id, this.gespraechsToken);

      this.anrufService.StartAgentAnruf(teilnehmer.id, this.gespraechsToken).then( x => {
        console.log(x);
        const anrufenResult = x;
        teilnehmer.status = 3;
      });
     
      // ++this.teilnehmerCount;
    }
  }

  async switchAgent(agent: AngemeldeterAgentContainer){
    this.confirmationService.confirm({
      header: "Gespräch übergeben",
      message: "Dieses Gespräch an " + agent.name + " übergeben?",
      accept: () => {
        this.anrufService.StartAgentAnruf(agent.id, this.gespraechsToken).then(x =>{
          this.angerufenerAgent = agent;
          console.log(x);
          const anrufenResult = x;
          agent.status = 3;}
        );
        console.log(this.angerufenerAgent)
      }
    });
  }

}
