import { Injectable } from "@angular/core";
import { DokumentHttpService } from "./dokument-http.service";
import { DokumentItem, DokumentPageRequest, createDokumentPageRequest, PageOfDokumentItem, createPageOfDokumentItem, DokumentContainer } from "./types";
import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { map, filter, switchMap, share } from "rxjs/operators";

@Injectable()
export class DokumentAdminService {

  request = new BehaviorSubject<DokumentPageRequest>(createDokumentPageRequest());
  page = new BehaviorSubject<PageOfDokumentItem>(createPageOfDokumentItem());
  modelState = new BehaviorSubject<any>({});
  selection = new BehaviorSubject<number[]>([]);
  selectedAll = new BehaviorSubject<boolean>(false);

  constructor(private httpService : DokumentHttpService) {

    const response = this.request.pipe(switchMap(filter => this.httpService.getPage(this.request.value)), share());

    response.pipe(filter(response => response.status === 200), map(response => response.data as PageOfDokumentItem)).subscribe(this.page);

    response.pipe(filter(response => response.status === 400), map(response => response.data as any)).subscribe(this.modelState);

    this.page.pipe(map(page => this.selection.value.filter(id => page.items.findIndex(item => item.id === id) > -1))).subscribe(this.selection);

    combineLatest(this.page, this.selection, (page, selection) => page.items.length > 0 && page.items.length === selection.length).subscribe(this.selectedAll);
  }

  toggleSelectAll() {
    this.selection.next(this.selectedAll.value?[] : this.page.value.items.map(item => item.id));
  }

  toggleSelect(id: number) {
    const index = this.selection.value.findIndex(v => v === id);
    const selection = index === -1
      ? [...this.selection.value, id]
      : this.selection.value.filter((v, i) => i !== index);
    this.selection.next(selection);
  }

  toggleOrdering(key: keyof DokumentItem) {
    let ordering = this.request.value.ordering;
    ordering = ordering.key === key
      ? { key, direction: ordering.direction == 0 ? 1 : 0 }
      : { key, direction: 0 };
    this.request.next({ ...this.request.value, ordering });
  }

  changePaging(paging: DokumentPageRequest['paging']) {
    this.request.next({ ...this.request.value, paging });
  }

  changeFilter(filter: DokumentPageRequest['filter']) {
    this.request.next({ ...this.request.value, filter });
  }

  refresh() {
    this.request.next(this.request.value);
  }

  async add(request : DokumentContainer) {
    const response = await this.httpService.post(request).toPromise();
    if (response.status == 204) { this.refresh(); }
    return response;
  }

  async get(id: number) {
    return this.httpService.get(id).toPromise();
  }

  async update(id: number, request: DokumentContainer) {
    const response = await this.httpService.put(id, request).toPromise();
    if (response.status == 204) { this.refresh(); }
    return response;
  }

  async deleteSelection() {
    const response = await this.httpService.delete(this.selection.value).toPromise();
    if (response.status === 204) { this.refresh(); }
    return response;
  }

  async delete(id : number) {
    const response = await this.httpService.delete([id]).toPromise();
    if (response.status === 204) { this.refresh(); }
    return response;
  }
}
