import { Directive, ElementRef, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[autofocus]'
})


export class AutofocusDirective implements OnInit {

  private _autofocus;

  @Input() set autofocus(condition: boolean) {
    this._autofocus = condition != false;
  }

  constructor(private el: ElementRef) { }

  ngOnInit(): void {
    if (this._autofocus || typeof this._autofocus === 'undefined') {
      this.el.nativeElement.focus();
    }
  }

}