import { Component, Input } from "@angular/core";

/**
 * Ein Container um Inhalt auszublenden, während dieser aktualisiert wird.
 */
@Component({
  selector: 'shared-activity',
  template: `
    <div class="shared-activity-overlay">
      <div id="loading"><div [innerHTML]="text"></div></div>
    </div>
    <ng-content></ng-content>
  `,
  host: {
    '[class.active]': 'active'
  }
})
export class ActivityComponent {

  /** Gibt an, ob der Inhalt gerade aktualisiert wird. */
  @Input() active: boolean
  @Input() text: string = "";

}