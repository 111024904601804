import { ConferenceHttpService } from "../../generated/conference-http.service";
import { RouterStateSnapshot, ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ConferenceCreateModel } from "../../generated/types";
import { Injectable } from "@angular/core";



@Injectable()
export class DefaultConferenceResolver implements Resolve<ConferenceCreateModel> {

  constructor(private conferenceHttpService: ConferenceHttpService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    const response = await this.conferenceHttpService.getDefaultConferenceEinladung().toPromise();
    if (response.status === 200) {
      return response.data;
    }
  }
}