<ng-container *ngIf="displayHtmlComponent">{{displayHtmlComponentName()}}</ng-container>
<ng-container *ngIf="maxTeilnehmerReached">
  Maximale Anzahl an Teilnehmern erreicht
</ng-container>
<div *ngIf="(!agenten || agenten.length === 0) && !maxTeilnehmerReached">
  Kein Agent verfügbar
</div>
<ng-container *ngIf="(agenten && agenten.length > 0)">
    <ul>
      <li *ngFor="let agent of agenten">
        <span *ngIf="!maxTeilnehmerReached" title="Zum Gespräch hinzufügen" style="cursor: pointer" [ngClass]="agent.status != 1 && agent.status != 3 ? 'disabledAnruf' : ''" (click)="startAgentAnruf(agent)">{{agent.name}}</span>
        <span *ngIf="maxTeilnehmerReached">{{agent.name}}</span>
        <span *ngIf="!maxTeilnehmerReached" class="agentstatus status{{agent.status}}" style="cursor: pointer" (click)="startAgentAnruf(agent)" [ngClass]="agent.status != 1 && agent.status != 3 ? 'disabledAnruf' : ''"></span>
        <span *ngIf="agent.isTextOnly" class="textonly" title="Nur Textchat">&#160;</span>
        <span *ngIf="!agent.isTextOnly && !agent.videoEnabled && (agent.status == 1 || agent.status == 6)" class="noVideo" title="Kein Video">&#160;</span>
        <span class="switchAgent" style="cursor: pointer" title="Gespräch übergeben" (click)="switchAgent(agent)"></span>
      </li>
    </ul>
</ng-container>



