import { Subscription, BehaviorSubject } from "rxjs";
import { LocalUserStream } from "./LocalUserStream";
import { LocalDisplayStream } from "./LocalDisplayStream";
import { TestConferenceComponent } from "./test-conference.component";
import { v4 as uuid } from "uuid";
import { addOrReplaceTrack, setMediaBitrate, getVideoTrackFromStream, getAudioTrackFromStream, closeRtcPeerConnection } from "./helpers";
import { ConferenceHub } from "../services/conference.hub";
import { FailCounter } from "./FailCounter";
import { ConnectionLogger } from "./types";
import { BandwidthReducer } from "./BandwidthReducer";
import { LogService } from '@oevermann/angular';
import { LogLevel } from '@oevermann/core';
import { Injectable, OnDestroy } from '@angular/core';

/**
 *  Baut eine WebRTC Verbindung auf um den lokalen Stream zu streamen.
 *  Der Verbindungsaufbau wird mit singleStreamerConnection.start() gestartet.
 *  Bei fehlgeschlagenem Verbindungsaufbau wird es maximal 5 mal hintereinander automatisch erneut versucht.
 * */
@Injectable()
export class OfferingStreamerConnection implements OnDestroy {

  private subscription = new Subscription();

  // _connectionId und _peerConnection werden immer zusammenn gesetzt
  private _connectionId: string | null = null;
  private _peerConnection: RTCPeerConnection | null = null;

  /** Dieses Promise ist nicht null, so lange eine Verbindung aufgebaut wird. Danach wird es auf null gesetzt und wir können erneut eine Verbindung aufbauen. */
  private startPromise: Promise<void> | null = null;

  /** Diesen Buffer brauchen wir, da wir die lokalen ICE Candidates erst zur Gegenseite schicken dürfen, wenn diese Gegenseite auch schon existiert und der RTCPeerConnection unsere SDP Offer als local description hinzugefügt wurde. */
  private localIceCandidateBuffer: RTCIceCandidateInit[] = [];

  private maxBandwidth: BandwidthReducer;

  /**
   *  Wir zählen die Anzahl der Fehlschläge beim Verbindungsaufbau. 
   *  So lange wir eine bestimmte Anzahl nicht überschritten haben, versuchen wir uns automatisch erneut zu verbinden.
   *  Bei jedem erlfogreichen Verbindungsaufbau setzen wir die Fehlschläge wieder auf 0.
   * */
  readonly fails = new FailCounter();

  /** Der Connection State der RTCPeerConnection. */
  connectionState: BehaviorSubject<RTCPeerConnectionState>;

  readonly kind = 'single';

  /** Die ID der aktuellen Connection. */
  get connectionId() {
    return this._connectionId;
  }

  get peerConnection() {
    return this._peerConnection;
  }

  constructor(private token: string,
    private localStream: LocalUserStream | LocalDisplayStream,
    private connectionLogger: ConnectionLogger | null,
    private conferenceHub: ConferenceHub,
    private iceServers: RTCIceServer[],
    maxBandwidthSteps: number[],
    private logService: LogService) {

    console.log('constructor localStream: ', this.localStream);

    // Wir registrieren uns auf die Events vom Conference Hub.
    this.subscription.add(this.conferenceHub.onSendAnswerToStreamer.subscribe(([streamId, connectionId, sdpAnswer]) => this.onSendAnswer(streamId, connectionId, sdpAnswer)));
    this.subscription.add(this.conferenceHub.onSendIceCandidateToStreamer.subscribe(([streamId, connectionId, iceCandidate]) => this.onSendIceCandidate(streamId, connectionId, iceCandidate)));
    this.maxBandwidth = new BandwidthReducer(maxBandwidthSteps);

    //// Wenn wir einen neuen Audio Track bekommen, müssen wir diesen allen Peer Connection hinzufügen.
    //if (this.localStream instanceof LocalUserStream) {

    //  this.subscription.add(this.localStream.audioTrack.subscribe(track => {
    //    console.log("audio" : )
    //    if (this._peerConnection) {
    //      console.log('audio');
    //      addOrReplaceTrack(this._peerConnection, this.localStream.stream, 'audio', track);
    //    }
    //  }));

    //  this.subscription.add(this.localStream.videoTrack.subscribe(track => {
    //    if (this._peerConnection) {
    //      console.log('video');
    //      addOrReplaceTrack(this._peerConnection, this.localStream.stream, 'video', track);
    //    }
    //  }));

    //}
  }

  ngOnDestroy(): void {
    this.release(true);
  }

  // Der Callback der gerufen wird, wenn der Streamer eine SDP Answer geschickt hat.
  private onSendAnswer = async (streamId, connectionId: string, sdpAnswer: string) => {

    console.log('onSendAnswer');

    if (streamId !== this.localStream.id || connectionId !== this._connectionId) {
      return; // SDP Answer ist nicht für diese Komponente bestimmt, da die Connection IDs nicht gleich sind.
    }

    if (this.connectionLogger) {
      this.connectionLogger.setRemoteDescription(connectionId, { type: 'answer', sdp: sdpAnswer });
    }

    try {
      console.log('OfferingStreamerConnection.setRemoteDescription:', this._peerConnection);
      await this._peerConnection.setRemoteDescription({ type: 'answer', sdp: sdpAnswer });

      // Jetzt können wir die lokalen ICE Candidates zur Gegenseite schicken, da wir wissen, dass unsere SDP Offer auf der Gegenseite als local description hinzugefügt wurde.
      for (let localIceCandidate of this.localIceCandidateBuffer) {
        if (this.connectionLogger) {
          this.connectionLogger.addLocalIceCandidate(this._connectionId, localIceCandidate);
        }
        try {
          await this.conferenceHub.sendIceCandidateToStreamer(this.token, streamId, this._connectionId, localIceCandidate);
        }
        catch (err) {
          this.logService
            .logAsync(LogLevel.INFO, 'sendIceCandidateToStreamer', err)
            .catch(console.log);
        }
      }
    }
    catch (ex) {
      this.logService
        .logAsync(LogLevel.INFO, 'OfferingStreamerConnection onSendAnswer', ex)
        .catch(console.log);
    }
  };

  // Der Callback der gerufen wird, wenn der Streamer einen ICE Candidate geschickt hat.
  private onSendIceCandidate = async (streamId: string, connectionId: string, iceCandidate: RTCIceCandidateInit) => {

    if (streamId !== this.localStream.id || connectionId !== this._connectionId) {
      return; // Ice Candidate ist nicht für diese Komponente bestimmt, da die Connection IDs nicht gleich sind.
    }

    // Wir können den ICE Candidate nur hinzufügen, wenn wir setLocalDescription schon gerufen haben. Ist das nicht der Fall, schieben wir den ICE Candidate in einen Buffer.
    /*if (this._peerConnection.currentLocalDescription) {*/
    if (this.connectionLogger) {
      this.connectionLogger.addRemoteIceCandidate(connectionId, iceCandidate);
    }
    await this._peerConnection.addIceCandidate(iceCandidate).catch(error => this.errorCallback('addICECandidate', error));
    //} else {
    //  this.localIceCandidateBuffer.push(iceCandidate);
    //}
  };

  // Der Callback der gerufen wird, wenn unsere RTCPeerConnection einen ICE Candidate gefunden hat.
  private onIceCandidate = async ({ candidate }) => {
    if (this.connectionLogger) {
      this.connectionLogger.addLocalIceCandidate(this._connectionId, candidate);
    }
    if (candidate != null) {
      if (this._peerConnection.remoteDescription) {
        try {
          await this.conferenceHub.sendIceCandidateToStreamer(this.token, this.localStream.id, this._connectionId, candidate);
        } catch (err) {
          this.logService
            .logAsync(LogLevel.INFO, 'sendIceCandidateToStreamer', err)
            .catch(console.log);
        }
      } else {
        this.localIceCandidateBuffer.push(candidate);
      }
    }
  };

  private prevConnectionState: RTCPeerConnectionState | null = null;

  private onConnectionStateChange = () => {
    const connectionState = this._peerConnection.connectionState;
    console.log("STREAMER: ", connectionState);
    if (connectionState === 'failed' || connectionState === 'disconnected' || connectionState === 'closed') {
      this.startPromise = null; // Wir setzten das startPromise auf null, nur so kann per this.start() eine neue Verbindung aufgebaut werden.
      this.fails.increment(); // Wir erhöhen die Fehlschläge.

      if (this.prevConnectionState === 'connected') {
        this.maxBandwidth.reduce();
      }

      if (!this.fails.exceeded) { this.start(); } // Die maximalen Fehlschläge sind noch nicht überschritten.
    } else if (connectionState === 'connected') {
      console.log("STREAMER connected:" + this.connectionId);
      this.startPromise = null; // Wir setzten das startPromise auf null, nur so kann per this.start() eine neue Verbindung aufgebaut werden.
      this.fails.reset(); // Wir haben eine Verbindung und setzen die Fehlschläge zurück.
    }

    if (this.connectionLogger) {
      this.connectionLogger.log(this._connectionId, 'connectionstate: ' + this._peerConnection.connectionState);
    }

    this.prevConnectionState = connectionState;
    this.connectionState.next(connectionState);
  };

  private onIceConnectionStateChange = () => {

    const peerConnection = this._peerConnection;

    if (!peerConnection) {
      return;
    }

    const iceConnectionState = peerConnection.iceConnectionState
    if (iceConnectionState === 'connected') {

      // Verbindung aufgebaut. Wir setzten die Anzahl der Reconnects wieder auf 0.
      this.fails.reset();
    } else if (iceConnectionState === 'disconnected') {
      this.reconnect();
    } else if (iceConnectionState === 'failed') {
      // Verbindungsaufbau fehlgeschlagen.
      console.log("STREAMER ICE-Verbindung fehlgeschlagen");
      this.reconnect();
    }
    // this.message = getStatusText(iceConnectionState);


    if (this.connectionLogger) {
      this.connectionLogger.log(this._connectionId, 'iceconnectionstate: ' + this._peerConnection.iceConnectionState);
    }


    if (this.connectionLogger) {
      this.connectionLogger.log(this._connectionId, 'iceconnectionstate: ' + this._peerConnection.iceConnectionState);
    }
  };

  private onSignalingStateChange = () => {
    if (this.connectionLogger) {
      this.connectionLogger.log(this._connectionId, 'signalingstatechange: ' + this._peerConnection.signalingState);
    }
  };

  private onIceGatheringStateChange = () => {
    if (this.connectionLogger) {
      this.connectionLogger.log(this._connectionId, 'icegatheringstatechange: ' + this._peerConnection?.iceGatheringState);
    }
  };

  /** Startet einen Verbindungsaufbau, wenn nicht schon einer läuft. */
  start() {

    console.log('start()');

    return this.startPromise || (this.startPromise = this.startInternal());
  }

  reconnect() {
    this.fails.reset();
    return this.start();
  }

  private async startInternal() {

    console.log('startInternal() OfferingStreamerConnection');

    if (this._peerConnection) {
      this.stopInternal(false);
    }

    this._connectionId = uuid();

    // Wir erzeugen die RTCPeerConnection mit den passenden ICE Servern.
    this._peerConnection = new RTCPeerConnection({ iceServers: this.iceServers });
    console.log('offeringstreamerconnection created RTCPeerConnection', this._peerConnection);

    // Wir registrieren uns für die Events der RTCPeerConnection
    this._peerConnection.addEventListener('connectionstatechange', this.onConnectionStateChange);
    this._peerConnection.addEventListener('iceconnectionstatechange', this.onIceConnectionStateChange);
    this._peerConnection.addEventListener('signalingstatechange', this.onSignalingStateChange);
    this._peerConnection.addEventListener('icegatheringstatechange', this.onIceGatheringStateChange);
    this._peerConnection.addEventListener('icecandidate', this.onIceCandidate);
    this.connectionState = new BehaviorSubject(this._peerConnection.connectionState);

    if (this.localStream instanceof LocalUserStream) {

      console.log('localUserStream');

      this.subscription.add(this.localStream.audioTrack.subscribe(track => {
        if (this._peerConnection && this.localStream && this.localStream.stream) {
          addOrReplaceTrack(this._peerConnection, this.localStream.stream, 'audio', track);
        }
      }));

      this.subscription.add(this.localStream.videoTrack.subscribe(track => {
        if (this._peerConnection && this.localStream && this.localStream.stream) {
          console.log('OfferingStreamerConnection addOrReplaceTrack');
          addOrReplaceTrack(this._peerConnection, this.localStream.stream, 'video', track);
        }
      }));

    }
    else if (this.localStream) {

      console.log('kein localUserStream');
      if (this._peerConnection && this.localStream && this.localStream.stream) {
        addOrReplaceTrack(this._peerConnection, this.localStream.stream, 'audio', getAudioTrackFromStream(this.localStream.stream));
        addOrReplaceTrack(this._peerConnection, this.localStream.stream, 'video', getVideoTrackFromStream(this.localStream.stream));
      }
    }
    else {
      console.log('kein localStream');
    }

    // Wir erzeugen ein SDP Offer.
    const sdpOffer = await this._peerConnection.createOffer();


    if (this.maxBandwidth.value) {

      if (this.connectionLogger) {
        this.connectionLogger.log(this._connectionId, 'maxBandwidth: ' + this.maxBandwidth.value);
      }

      // Wir passen die maximale Bandbreite an.
      sdpOffer.sdp = setMediaBitrate(sdpOffer.sdp, 'video', this.maxBandwidth.value);
    }

    // Setzen unsere lokale SDP.
    if (this.connectionLogger) {
      this.connectionLogger.setLocalDescription(this._connectionId, sdpOffer);
    }

    await this._peerConnection.setLocalDescription(sdpOffer);

    // Wir schicken unser SDP Offer an den Streamer, dieser soll uns dann seine SDP Answer zukommen lassen.
    await this.conferenceHub.sendOfferToConsumer(this.token, this.localStream.id, this._connectionId, sdpOffer.sdp);

  }

  /** Stoppt die aktuelle Verbindung. */
  private stopInternal(stopTracks: boolean) {

    if (this.connectionLogger && this._connectionId) {
      this.connectionLogger.log(this._connectionId, 'stopped');
    }

    if (this._peerConnection) {
      this._peerConnection.removeEventListener('connectionstatechange', this.onConnectionStateChange);
      this._peerConnection.removeEventListener('iceconnectionstatechange', this.onIceConnectionStateChange);
      this._peerConnection.removeEventListener('signalingstatechange', this.onSignalingStateChange);
      this._peerConnection.removeEventListener('icegatheringstatechange', this.onIceGatheringStateChange);
      this._peerConnection.removeEventListener('icecandidate', this.onIceCandidate);
      closeRtcPeerConnection(this._peerConnection, stopTracks);
      console.log('offeringstreamerconnection closed RTCPeerConnection with stopTracks: ' + stopTracks, this._peerConnection);
      this._peerConnection = null;
    }
    this.localIceCandidateBuffer = [];
    this._connectionId = null;

    if (stopTracks) {
      console.log('OfferingStreamerConnection stopInternal stopAll');
      LocalUserStream.stopAll(this.logService, true);
      this.localStream?.stop()
    }

    //this.localStream = null;
  }

  /** Gibt alle Ressourcen frei. */
  release(stopTracks: boolean) {
    this.stopInternal(stopTracks);
    this.subscription.unsubscribe();
  }

  private errorCallback = (action: string, error: Error | DOMException | string, data: any = undefined) => {

    this.logService
      .logAsync(LogLevel.ERROR, 'ConferenceComponent.' + action, error || '', data)
      .catch(console.log);
  }
}