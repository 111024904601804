import { Injectable } from '@angular/core';

@Injectable()
export class StorageService {
  uselocalStorage = true;
  getItem(itemKey: string): string {
    if (this.uselocalStorage) {
      try {
        return localStorage.getItem(itemKey);
      }
      catch {
        this.uselocalStorage = false;
        return sessionStorage.getItem(itemKey);
      }
    } else {
      return sessionStorage.getItem(itemKey);
    }
  }

  setItem(itemKey: string, itemvalue: string) {
    if (this.uselocalStorage) {
      try {
        localStorage.setItem(itemKey, itemvalue);
      }
      catch {
        try {
          this.uselocalStorage = false;
          sessionStorage.setItem(itemKey, itemvalue);
        }
        catch {
        }
      }
    } else {
      sessionStorage.setItem(itemKey, itemvalue);
    }
  }
}