export class FailCounter {

  private fails = 0;

  increment() {
    this.fails++;
  }

  reset() {
    this.fails = 0;
  }

  get exceeded() {
    return this.fails > 5;
  }
}