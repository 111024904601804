import { Component, OnInit, Input, EventEmitter, Output, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { LocaleSettings } from 'primeng/calendar';

@Component({
	selector: 'app-datepicker-input',
	templateUrl: './datepicker-input.component.html'
})
export class DatepickerInputComponent implements OnInit {

	// das dürfte nach der Umstellung auf angular 13 überflüssig sein
	// das deutsche datumsformat funktioniert auch nicht mehr.
	de: LocaleSettings = {
		dateFormat: 'dd.mm.yy'
	};
	date: Date = new Date();

	public invalidDateFormat:boolean=false;

	@Input() id: string;
	@Input() label: string;
	@Input() placeholder: string;
	@Input() autocomplete: string;
	@Input() control: FormControl;
	@Input() disabledDays: number[] = [];
	@Input() readonlyInput: boolean = false;
	@Input() wrapperCssClass: string;
	@Input() timeOnly: boolean
	@Input() view: string = "date";
	@Input() yearRange: string;
	@Input() minDate: FormControl;
	@Input() maxDate: FormControl;
	@Input() required: boolean;
	@Input() cssClass: string = "";
	@Input() showTime: boolean;
	@Input() value: string;  // wenn der Wert übergeben wird, kann das Control auch auf changes des parents reagieren.
	
	@Output() public change: EventEmitter<void> = new EventEmitter<void>();

	public dateValue: Date;
	public form: FormControl;
	formControlName: string;

	constructor() {}

	ngOnInit() {
		this.setDefaultValues();
		this.form = new FormControl(this.control.value != '0001-01-01T00:00:00' ? new Date(this.control.value) : '');

		if (this.control.value) {
			this.value = this.control.value;
			this.dateValue = new Date(this.value);
			if (this.control.value.length < 24){
				this.invalidDateFormat = true;
				console.error(`Datum ${this.control.value} ist im falschen Format (kein UTC).`);
			}
		}

		// form value change auf ngmodel umgebaut
		// this.form.valueChanges.subscribe(value => {
		// 	if (value) {
		// 		const date = new Date(value);	
		// 		this.control.markAsDirty()	
		// 		this.control.setValue(date);
		// 		this.change.next();
		// 	}
		// });
	}

	public changeDate(){
		if (this.dateValue){

			this.control.setValue(this.dateValue.toJSON());
			this.value = this.control.value;
			this.control.markAsDirty();
			this.change.next();		
		}
	}

	// Wert wird vom parent geändert.
	public ngOnChanges(changes: SimpleChanges): void {
		if (this.value) {
			if (changes.value){
				this.invalidDateFormat = false;
				// todo: hier kommt ein Fehler 
				// Zeitverschiebung 2 h.
				this.dateValue = new Date(this.value); 
				// const value = Date.parse(this.value); 
				// this.dateValue = new Date (Date.parse(this.value));
				if (changes.control?.previousValue){
					setTimeout(() => {
						this.control.setValue(this.dateValue);
						if (this.control.value.length < 24){
							this.invalidDateFormat = true;
							console.error(`Datum ${this.control.value} ist im falschen Format (kein UTC).`);
						}
					},0);
				}
			}
		}
	}

	getUtcDate(value: any) {
		return new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(), value.getSeconds()))
  }

	setDefaultValues() {
		if (!this.yearRange || this.yearRange == "") {
			this.yearRange = this.getYearRange();
		}
	}

	getYearRange() {
		let date = new Date();
		return (date.getUTCFullYear() - 5).toString() + ":2050";
	}

	removeValue() {
		this.control.setValue('');
	}

	getMinDate() {
		return this.minDate != null && this.minDate.value != null && this.minDate.value != '' ? new Date(this.minDate.value) : null;
	}

	getMaxDate() {
		return this.maxDate != null && this.maxDate.value != null && this.maxDate.value != '' ? new Date(this.maxDate.value) : null;
	}


	setTouched(event: Event) {
		this.control.markAsTouched();
	}

}
