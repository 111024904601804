import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, forwardRef, Input, ContentChild, QueryList, ElementRef } from "@angular/core";

export interface SelectOption {
  value: number;
  label: string;
}

@Component({
  selector: 'shared-multi-select',
  template: `
    <ng-container *ngFor="let option of options">
      <div><input type="checkbox" [disabled]="isDisabled || disabled" [checked]="isChecked(option.value)" (change)="check(option.value)" id="{{ id + '_' + option.value}}" /><label [ngStyle]="{ color: isDisabled || disabled ? '#ccc' : '#3c3c3c' }" for="{{ id + '_' + option.value}}">{{option.label}}</label></div>
      <ng-container *ngIf="template">
        <ng-content *ngTemplateOutlet="template; context : { option : option, checked: isChecked(option.value) }"></ng-content>
      </ng-container>
    </ng-container>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectComponent),
      multi: true
    }
  ]
})
export class MultiSelectComponent implements ControlValueAccessor {

  @ContentChild('template') template: QueryList<ElementRef>;

  selection: number[] = [];

  isDisabled = false;

  @Input() id: string = '';

  @Input() options: SelectOption[] = [];
  @Input() disabled = false;

  writeValue(selection: number[]) {
    this.selection = selection;
  }

  onModelChange: (value: (number)[]) => void = () => { };
  onModelTouched: () => void = () => { };

  registerOnChange(fn: (value: (number)[]) => void) {
    this.onModelChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onModelTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  check(value: number) {
    if (this.selection.includes(value)) {
      this.selection = this.selection.filter(v => v !== value);
    } else {
      this.selection.push(value);
    }
    this.onModelChange(this.selection);
    this.onModelTouched();
  }

  isChecked(value: number) {
    return this.selection.includes(value);
  }

}