import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { HttpParams, HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";
import { DownloadRequestContainer } from "./types";

@Injectable()
export class DownloadHttpService {

  constructor(private httpService : HttpService) { }

  postReceiveBlobAccessToken(request? : { token?: string; blobId?: number; benutzerToken?: string }) { 
    const params : any = {};
    if(request.token != null) { params['request.token'] = request.token; }
    if(request.blobId != null) { params['request.blobId'] = request.blobId; }
    if(request.benutzerToken != null) { params['request.benutzerToken'] = request.benutzerToken; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/PostReceiveBlobAccessToken`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'blob'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: { blob: responseData as Blob, filename: getFilenameFromHeaderString(response.headers.get('Content-Disposition')) } } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  get(cacheGuid: string) { 
    const params : any = {};
    if(cacheGuid != null) { params['cacheGuid'] = cacheGuid; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/Download`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'blob'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: { blob: responseData as Blob, filename: getFilenameFromHeaderString(response.headers.get('Content-Disposition')) } } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(request: DownloadRequestContainer) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/Download`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'blob'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: { blob: responseData as Blob, filename: getFilenameFromHeaderString(response.headers.get('Content-Disposition')) } } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
