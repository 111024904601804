<ng-container *ngIf="displayHtmlComponent">{{displayHtmlComponentName()}}</ng-container>
<div id="conferencestart">
  <div class="box centered scrollable verticalOnly clearfix">
    <ng-container *ngIf="!startdata.conferenceStartInfo.conferenceClosed">
      <h1>Herzlich Willkommen</h1>
      <p>
        Hallo {{startdata.teilnehmer.anrede}} {{startdata.teilnehmer.name}},<br />
        <ng-container *ngIf="startdata.teilnehmer.isOrganizer">Sie haben zur WebKonferenz <b>{{startdata.conferenceStartInfo.betreff}}</b> eingeladen. Klicken Sie kurz vor dem angegebenen Beginn auf "WebKonferenz jetzt starten", um den Teilnehmern den Zugang zu ermöglichen.</ng-container>
        <ng-container *ngIf="!startdata.teilnehmer.isOrganizer">Sie wurden von {{startdata.conferenceStartInfo.organisatorName}} zur WebKonferenz <b>{{startdata.conferenceStartInfo.betreff}}</b> eingeladen.</ng-container>
      </p>
      <p>Beginn: <b>{{startdata.conferenceStartInfo.start | date:'dd.MM.yyyy, HH:mm':'de'}} Uhr</b></p>
      <div class="conferencestart-video-row">
        <div class="videoContainer" *ngIf="startdata.teilnehmer.rechtevorlage.canActivateVideo || startdata.teilnehmer.rechtevorlage.canActivateAudio">
          <video #preview id="conferenceStartPreviewVideo" autoplay playsinline disablePictureInPicture [class.off]="!video"></video>
          <div id="videoControls">
            <button class="webrtc-button webrtc-button-video" [class.off]="!video" [disabled]="!startdata.teilnehmer.rechtevorlage.canActivateVideo" [title]="!video ? 'Kamera einschalten' : 'Kamera ausschalten'" (click)="setVideo($event)"></button>
            <button class="webrtc-button webrtc-button-audio" [class.off]="!audio" [disabled]="!startdata.teilnehmer.rechtevorlage.canActivateAudio" [title]="!audio ? 'Mikrofon einschalten' : 'Mikrofon ausschalten'" (click)="setAudio($event)"></button>
            <button *ngIf="(startdata.teilnehmer.rechtevorlage.canActivateVideo || startdata.teilnehmer.rechtevorlage.canActivateAudio) && localStream" class="webrtc-button webrtc-button-settings" title="Media-Einstellungen" (click)="showMediaSetttings($event)"></button>
          </div>
        </div>
        <div #mediaSettingsContainer id="videoPreviewSettings" *ngIf="(startdata.teilnehmer.rechtevorlage.canActivateVideo || startdata.teilnehmer.rechtevorlage.canActivateAudio) && localStream">
          <mediasettings [token]="token" [previewStream]="localStream" [closeButton]="true"></mediasettings>
        </div>
      </div>
      <button class="actionbutton" (click)="joinConference($event)" [disabled]="!canJoin && !startdata.teilnehmer.isOrganizer">{{startdata.teilnehmer.isOrganizer ? 'WebKonferenz jetzt starten' : (canJoin ? 'Jetzt beitreten': 'Warte auf Konferenzstart ...')}}</button>

    </ng-container>
    <ng-container *ngIf="startdata.conferenceStartInfo.conferenceClosed">
      <h1>Leider zu spät ...</h1>
      <p>Bitte entschuldigen Sie, aber die WebKonferenz <b>{{startdata.conferenceStartInfo.betreff}}</b> ist bereits beendet.</p>
      <p>Beginn war: <b>{{startdata.conferenceStartInfo.start | date:'dd.MM.yyyy, HH:mm':'de'}} Uhr</b></p>
    </ng-container>
  </div>
</div>