
import {throwError as observableThrowError,  Observable ,  of ,  from as fromPromise ,  defer } from 'rxjs';
import { AuthServiceToken, MemberService } from '@oevermann/angular';
import { Inject, Injectable } from '@angular/core';
import { catchError, switchMap, filter, map } from 'rxjs/operators';
import { HttpClient, HttpRequest, HttpHeaders, HttpResponse, HttpParams, HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class HttpService {

  constructor(private httpClient: HttpClient, private memberService: MemberService) { }

  getResponseData<T>(response: HttpResponse<T> | HttpErrorResponse) {

    if (response instanceof HttpErrorResponse) {
      const data = response.error;
      if (data instanceof Blob) {
        var reader = new FileReader();
        const promise = new Promise(resolve => {
          reader.addEventListener('load', () => {
            resolve(JSON.parse(reader.result as string));
          })
        });
        reader.readAsText(data);
        return promise;
      } else {
        return Promise.resolve(data);
      }
    } else {
      return Promise.resolve(response.body);
    }
  }

  requestWithBody<T>(options: { method: 'POST' | 'PUT'; url: string; body?: any; headers?: HttpHeaders; params?: HttpParams, responseType: 'json' | 'blob' }): Observable<HttpResponse<T> | HttpErrorResponse> {

    return defer(() => fromPromise(this.headersWithToken(options.headers)))
      .pipe(
        map(headers => {
          return new HttpRequest<any>(options.method, options.url, options.body, { headers, params: options.params, responseType: options.responseType });
        }),
        switchMap(request => this.httpClient.request(request)),
        filter(event => event instanceof HttpResponse),
        catchError(this.handleCatch)
      ) as Observable<HttpResponse<T> | HttpErrorResponse>;
  }

  request<T>(options: { method: 'GET' | 'DELETE'; url: string; headers?: HttpHeaders; params?: HttpParams, responseType: 'json' | 'blob' }): Observable<HttpResponse<T> | HttpErrorResponse> {

    return defer(() => fromPromise(this.headersWithToken(options.headers)))
      .pipe(
        map(headers => {
          return new HttpRequest<any>(options.method, options.url, { headers, params: options.params, responseType: options.responseType });
        }),
        switchMap(request => this.httpClient.request(request)),
        filter(event => event instanceof HttpResponse),
        catchError(this.handleCatch)
      ) as Observable<HttpResponse<T> | HttpErrorResponse>;
  }

  handleCatch = (error: any) => {

    if (error instanceof HttpErrorResponse) {
      if (error.status === 400) {
        return of(error);
      } else if (error.status === 401) {
        this.memberService.logout();
      }
    }

    return observableThrowError(error);
  }

  /**
   * Fügt den Headers das Authorization Token hinzu und gibt die neuen Headers zurück oder erzeugt neue Headers mit dem Authorization Token.
   * @param headers
   */
  private async headersWithToken(headers?: HttpHeaders) {
    const authenticationInfo = await this.memberService.getAuthenticationInfo();

    if (authenticationInfo) {

      if (!headers) {
        headers = new HttpHeaders();
      }

      return headers.append('Authorization', 'Bearer ' + authenticationInfo.access_token);
    }
    return headers;
  }
}