<ul id="emojiPicker" class="clearfix">
  <!--li>Letzte
    <ul>
      <li title="grinning face" (click)="addEmoji('😀')">😀</li>
    </ul>
  </!--li>
  <li>Gesichter>
    <ul-->
      <li title="grinning face" (click)="addEmoji('😀')">😀</li>
      <li title="grinning face with smiling eyes" (click)="addEmoji('😄')">😄</li>
      <li title="smiling face with open mouth" (click)="addEmoji('😃')">😃</li>
      <li title="smiling face with open mouth and cold sweat" (click)="addEmoji('😅')">😅</li>
      <li title="smiling face with open mouth and tightly-closed eyes" (click)="addEmoji('😆')">😆</li>
      <li title="smiling face with open mouth and smiling eyes" (click)="addEmoji('😁')">😁</li>
      <li title="face with tears of joy" (click)="addEmoji('😂')">😂</li>
      <li title="rolling on the floor laughing" (click)="addEmoji('🤣')">🤣</li>
      <li title="smiling face with halo" (click)="addEmoji('😇')">😇</li>
      <li title="winking face" (click)="addEmoji('😉')">😉</li>
      <li title="smiling face with smiling eyes" (click)="addEmoji('😊')">😊</li>
      <li title="face savouring delicious food" (click)="addEmoji('😋')">😋</li>
      <li title="hugging face" (click)="addEmoji('🤗')">🤗</li>
      <li title="thinking face" (click)="addEmoji('🤔')">🤔</li>
      <li title="slightly smiling face" (click)="addEmoji('🙂')">🙂</li>
      <li title="upside-down face" (click)="addEmoji('🙃')">🙃</li>
      <li title="relieved face" (click)="addEmoji('😌')">😌</li>
      <li title="smiling face" (click)="addEmoji('☺')">☺</li>
      <li title="smiling face with heart-shaped eyes" (click)="addEmoji('😍')">😍</li>
      <li title="nerd face" (click)="addEmoji('🤓')">🤓</li>
      <li title="smiling face with sunglasses" (click)="addEmoji('😎')">😎</li>
      <li title="smirking face" (click)="addEmoji('😏')">😏</li>
      <li title="face with thermometer" (click)="addEmoji('🤒')">🤒</li>
      <li title="neutral face" (click)="addEmoji('😐')">😐</li>
      <li title="expressionless face" (click)="addEmoji('😑')">😑</li>
      <li title="face with rolling eyes" (click)="addEmoji('🙄')">🙄</li>
      <li title="unamused face" (click)="addEmoji('😒')">😒</li>
      <li title="face with cold sweat" (click)="addEmoji('😓')">😓</li>
      <li title="pensive face" (click)="addEmoji('😔')">😔</li>
      <li title="confused face" (click)="addEmoji('😕')">😕</li>
      <li title="thinking face" (click)="addEmoji('🤔')">🤔</li>
      <li title="face with head-bandage" (click)="addEmoji('🤕')">🤕</li>
      <li title="confounded face" (click)="addEmoji('😖')">😖</li>
      <li title="kissing face" (click)="addEmoji('😗')">😗</li>
      <li title="face throwing a kiss" (click)="addEmoji('😘')">😘</li>
      <li title="kissing face with smiling eyes" (click)="addEmoji('😙')">😙</li>
      <li title="kissing face with closed eyes" (click)="addEmoji('😚')">😚</li>
      <li title="face with stuck-out tongue" (click)="addEmoji('😛')">😛</li>
      <li title="face with stuck-out tongue and winking eye" (click)="addEmoji('😜')">😜</li>
      <li title="face with stuck-out tongue and tightly-closed eyes" (click)="addEmoji('😝')">😝</li>
      <li title="drooling face" (click)="addEmoji('🤤')">🤤</li>
      <li title="disappointed face" (click)="addEmoji('😞')">😞</li>
      <li title="worried face" (click)="addEmoji('😟')">😟</li>
      <li title="frowning face" (click)="addEmoji('☹')">☹</li>
      <li title="angry face" (click)="addEmoji('😠')">😠</li>
      <li title="pouting face" (click)="addEmoji('😡')">😡</li>
      <li title="crying face" (click)="addEmoji('😢')">😢</li>
      <li title="persevering face" (click)="addEmoji('😣')">😣</li>
      <li title="face with look of triumph" (click)="addEmoji('😤')">😤</li>
      <li title="disappointed but relieved face" (click)="addEmoji('😥')">😥</li>
      <li title="frowning face with open mouth" (click)="addEmoji('😦')">😦</li>
      <li title="anguished face" (click)="addEmoji('😧')">😧</li>
      <li title="fearful face" (click)="addEmoji('😨')">😨</li>
      <li title="weary face" (click)="addEmoji('😩')">😩</li>
      <li title="sleepy face" (click)="addEmoji('😪')">😪</li>      	
      <li title="tired face" (click)="addEmoji('😫')">😫</li>
      <li title="grimacing face" (click)="addEmoji('😬')">😬</li>
      <li title="loudly crying face" (click)="addEmoji('😭')">😭</li>
      <li title="face with open mouth" (click)="addEmoji('😮')">😮</li>
      <li title="hushed face" (click)="addEmoji('😯')">😯</li>
      <li title="lying face" (click)="addEmoji('🤥')">🤥</li>
      <li title="face with open mouth and cold sweat" (click)="addEmoji('😰')">😰</li>
      <li title="face screaming in fear" (click)="addEmoji('😱')">😱</li>
      <li title="dizzy face" (click)="addEmoji('😵')">😵</li>
      <li title="flushed face" (click)="addEmoji('😳')">😳</li>
      <li title="sleeping face" (click)="addEmoji('😴')">😴</li>
      <li title="astonished face" (click)="addEmoji('😲')">😲</li>
      <li title="face without mouth" (click)="addEmoji('😶')">😶</li>
      <li title="money-mouth face" (click)="addEmoji('🤑')">🤑</li>
      <li title="zipper-mouth face" (click)="addEmoji('🤐')">🤐</li>
      <li title="face with head-bandage" (click)="addEmoji('🤕')">🤕</li>
      <li title="face with medical mask" (click)="addEmoji('😷')">😷</li>
      <li title="nauseated face" (click)="addEmoji('🤢')">🤢</li>
      <li title="sneezing face" (click)="addEmoji('🤧')">🤧</li>
      <li title="robot face" (click)="addEmoji('🤖')">🤖</li>
      <li title="smiling face with horns" (click)="addEmoji('😈')">😈</li>
      <li title="angry face with horns" (click)="addEmoji('👿')">👿</li>
      <li title="pile of poo" (click)="addEmoji('💩')">💩</li>
      <li title="grinning cat face" (click)="addEmoji('😸')">😸</li>
      <li title="cat face with tears of joy" (click)="addEmoji('😹')">😹</li>
      <li title="grinning cat face with smiling eyes" (click)="addEmoji('😺')">😺</li>
      <li title="smiling cat face with heart-eyes" (click)="addEmoji('😻')">😻</li>
      <li title="cat face with wry smile" (click)="addEmoji('😼')">😼</li>
      <li title="kissing cat face" (click)="addEmoji('😽')">😽</li>
      <li title="pouting cat face" (click)="addEmoji('😾')">😾</li>
      <li title="crying cat face" (click)="addEmoji('😿')">😿</li>
      <li title="weary cat face" (click)="addEmoji('🙀')">🙀</li>
      <li title="man bowing" (click)="addEmoji('🙇')">🙇</li>
      <li title="see-no-evil monkey" (click)="addEmoji('🙈')">🙈</li>
      <li title="hear-no-evil monkey" (click)="addEmoji('🙉')">🙉</li>
      <li title="speak-no-evil monkey" (click)="addEmoji('🙊')">🙊</li>
      <li title="person frowning" (click)="addEmoji('🙍')">🙍</li>
      <li title="person gesturing NO" (click)="addEmoji('🙅')">🙅</li>
      <li title="person gesturing OK" (click)="addEmoji('🙆')">🙆</li>
      <li title="person raising hand" (click)="addEmoji('🙋')">🙋</li>
      <li title="person pouting" (click)="addEmoji('🙎')">🙎</li>
      <li title="person tipping hand" (click)="addEmoji('💁')">💁</li>
      <li title="raising hands" (click)="addEmoji('🙌')">🙌</li>
      <li title="folded hands" (click)="addEmoji('🙏')">🙏</li>
      <li title="raised fist" (click)="addEmoji('✊')">✊</li>
      <li title="oncoming fist" (click)="addEmoji('👊')">👊</li>
      <li title="index pointing up" (click)="addEmoji('☝')">☝</li>
      <li title="victory hand" (click)="addEmoji('✌')">✌</li>
      <li title="middle finger" (click)="addEmoji('🖕')">🖕</li>
      <li title="backhand index pointing up" (click)="addEmoji('👆')">👆</li>
      <li title="backhand index pointing down" (click)="addEmoji('👇')">👇</li>
      <li title="backhand index pointing left" (click)="addEmoji('👈')">👈</li>
      <li title="backhand index pointing right" (click)="addEmoji('👉')">👉</li>
      <li title="waving hands" (click)="addEmoji('👋')">👋</li>
      <li title="OK hand" (click)="addEmoji('👌')">👌</li>
      <li title="thumbs up" (click)="addEmoji('👍')">👍</li>
      <li title="thumbs down" (click)="addEmoji('👎')">👎</li>
      <li title="clapping hands" (click)="addEmoji('👏')">👏</li>
      <li title="open hands" (click)="addEmoji('👐')">👐</li>
      <li title="raised hand" (click)="addEmoji('✋')">✋</li>
      <li title="hand with fingers splayed" (click)="addEmoji('🖐')">🖐</li>
      <li title="vulcan salute" (click)="addEmoji('🖖')">🖖</li>
      <li title="sign of the horns" (click)="addEmoji('🤘')">🤘</li>
      <li title="flexed biceps" (click)="addEmoji('💪')">💪</li>
      <li title="writing hand" (click)="addEmoji('✍')">✍</li>
      <li title="ear" (click)="addEmoji('👂')">👂</li>
      <li title="nose" (click)="addEmoji('👃')">👃</li>
      <li title="mouth" (click)="addEmoji('👄')">👄</li>
      <li title="tongue" (click)="addEmoji('👅')">👅</li>
      <li title="eye" (click)="addEmoji('👁')">👁</li>
      <li title="eyes" (click)="addEmoji('👀')">👀</li>
      <li title="bust in silhouette" (click)="addEmoji('👤')">👤</li>
      <li title="busts in silhouette" (click)="addEmoji('👥')">👥</li>
      <li title="speaking head" (click)="addEmoji('🗣')">🗣</li>
      <li title="detective" (click)="addEmoji('🕵')">🕵</li>
      <li title="Santa Claus" (click)="addEmoji('🎅')">🎅</li>
      <li title="police officer" (click)="addEmoji('👮')">👮</li>
      <li title="man with Chinese cap" (click)="addEmoji('👲')">👲</li>
      <li title="construction worker" (click)="addEmoji('👷')">👷</li>
      <li title="princess" (click)="addEmoji('👸')">👸</li>
      <li title="boy" (click)="addEmoji('👦')">👦</li>
      <li title="girl" (click)="addEmoji('👧')">👧</li>
      <li title="man" (click)="addEmoji('👨')">👨</li>
      <li title="woman" (click)="addEmoji('👩')">👩</li>
      <li title="family" (click)="addEmoji('👪')">👪</li>
      <li title="man and woman holding hands" (click)="addEmoji('👫')">👫</li>
      <li title="two men holding hands" (click)="addEmoji('👬')">👬</li>
      <li title="two women holding hands" (click)="addEmoji('👭')">👭</li>
      <li title="Japanese dolls" (click)="addEmoji('🎎')">🎎</li>
      <li title="people with bunny ears" (click)="addEmoji('👯')">👯</li>
      <li title="bride with veil" (click)="addEmoji('👰')">👰</li>
      <li title="blond-haired person" (click)="addEmoji('👱')">👱</li>
      <li title="person wearing turban" (click)="addEmoji('👳')">👳</li>
      <li title="old man" (click)="addEmoji('👴')">👴</li>
      <li title="old woman" (click)="addEmoji('👵')">👵</li>
      <li title="baby" (click)="addEmoji('👶')">👶</li>
      <li title="ogre" (click)="addEmoji('👹')">👹</li>
      <li title="goblin" (click)="addEmoji('👺')">👺</li>
      <li title="ghost" (click)="addEmoji('👻')">👻</li>
      <li title="baby angel" (click)="addEmoji('👼')">👼</li>
      <li title="aliens" (click)="addEmoji('👽')">👽</li>
      <li title="alien monster" (click)="addEmoji('👾')">👾</li>
      <li title="scull" (click)="addEmoji('💀')">💀</li>
      <li title="skull and crossbones" (click)="addEmoji('☠')">☠</li>
      <li title="guard" (click)="addEmoji('💂')">💂</li>
      <li title="kiss mark" (click)="addEmoji('💋')">💋</li>
      <li title="love letter" (click)="addEmoji('💌')">💌</li>
      <li title="kiss" (click)="addEmoji('💏')">💏</li>
      <li title="bouquet" (click)="addEmoji('💐')">💐</li>
      <li title="couple with heart" (click)="addEmoji('💑')">💑</li>
      <li title="wedding" (click)="addEmoji('💒')">💒</li>
      <li title="beating heart" (click)="addEmoji('💓')">💓</li>
      <li title="two hearts" (click)="addEmoji('💕')">💕</li>
      <li title="sparkling heart" (click)="addEmoji('💖')">💖</li>
      <li title="growing heart" (click)="addEmoji('💗')">💗</li>
      <li title="heart with arrow" (click)="addEmoji('💘')">💘</li>
      <li title="broken heart" (click)="addEmoji('💔')">💔</li>
      <li title="red heart" (click)="addEmoji('❤')">❤</li>
      <li title="yellow heart" (click)="addEmoji('💛')">💛</li>
      <li title="green heart" (click)="addEmoji('💚')">💚</li>
      <li title="blue heart" (click)="addEmoji('💙')">💙</li>
      <li title="purple heart" (click)="addEmoji('💜')">💜</li>
      <li title="heart with ribbon" (click)="addEmoji('💝')">💝</li>
      <li title="revolving hearts" (click)="addEmoji('💞')">💞</li>
      <li title="heart decoration" (click)="addEmoji('💟')">💟</li>
    <!--/ul>
  <li-->
  <!--li>Fahrrad
    <ul>
      <li></li>
    </ul>
  </!--li>
  <li>Blume
    <ul>
      <li></li>
    </ul>
  </li>
  <li>Krone
    <ul>
      <li></li>
    </ul>
  </li>
  <li>Auto
    <ul>
      <li></li>
    </ul>
  </li>
  <li>Dreieck
    <ul>
      <li></li>
    </ul>
  </li>
  <li>Erde
    <ul>
      <li></li>
    </ul>
  </li-->
</ul>