import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { FrageGesprachEndeModel, TupleOfBooleanAndString, BewertungContainer, AngemeldeterAgentContainer } from "./types";
import { HttpParams, HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class AgentHttpService {

  constructor(private httpService : HttpService) { }

  getFragen(id: number) { 
    const params : any = {};
    if(id != null) { params['id'] = id; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/agent/frage`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as { [key: string]: FrageGesprachEndeModel } } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getAdditionalInfoHeadline() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/agent/additionalinfoheadline`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as string } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  showCallerNameInInformationTab(token: string) { 
    const params : any = {};
    if(token != null) { params['token'] = token; }
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/agent/showCallerNameInInformationTab`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as TupleOfBooleanAndString } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(gespraechtoken: string, benutzerToken: string, bewertung: BewertungContainer) { 
    const params : any = {};
    if(gespraechtoken != null) { params['gespraechtoken'] = gespraechtoken; }
    if(benutzerToken != null) { params['benutzerToken'] = benutzerToken; }
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/agent/bewertung`, body: bewertung, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getAngemeldeterAgent() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/agent/getAngemeldeterAgent`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as AngemeldeterAgentContainer } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
