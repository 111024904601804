import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'shared-rueckruflink-output',
  templateUrl: './rueckruflink-output.component.html'
}) 


export class RueckrufLinkOutputComponent {
  @Input() token: string;
  @Input() text: string;

  @Output() clicked: EventEmitter<Event> = new EventEmitter<Event>();


  constructor(private router: Router) { }

  rueckrufEinstellen(event: Event) {
    this.clicked.next(event);
    event && event.preventDefault();
  }
}