import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { DefaultNachrichtenContainerRequest, NachrichtenTemplateContainer, PageOfNachrichtenTemplateItem, Option } from "./types";
import { HttpRequest, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";

@Injectable()
export class NachrichtenTemplateHttpService {

  constructor(private httpService : HttpService) { }

  getDefaultForAnrufgruppe(request: DefaultNachrichtenContainerRequest) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/NachrichtenTemplate/GetDefaultForAnrufgruppe`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as NachrichtenTemplateContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getAllChatTemplates(token: string) { 
    const params : any = {};
    if(token != null) { params['token'] = token; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/GetAllChatTemplates`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as NachrichtenTemplateContainer[] } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getPage(request? : { filter? : { searchText?: string; anrufgruppeId?: number; typen?: number[] }; paging? : { size?: number; index?: number }; ordering? : { key?: string; direction?: number } }) { 
    const params : any = {};
    if(request.filter.searchText != null) { params['request.filter.searchText'] = request.filter.searchText; }
    if(request.filter.anrufgruppeId != null) { params['request.filter.anrufgruppeId'] = request.filter.anrufgruppeId; }
    if(request.filter.typen != null) { params['request.filter.typen'] = request.filter.typen; }
    if(request.paging.size != null) { params['request.paging.size'] = request.paging.size; }
    if(request.paging.index != null) { params['request.paging.index'] = request.paging.index; }
    if(request.ordering.key != null) { params['request.ordering.key'] = request.ordering.key; }
    if(request.ordering.direction != null) { params['request.ordering.direction'] = request.ordering.direction; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/page`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as PageOfNachrichtenTemplateItem } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getTextOptions() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/text-options`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as Option[] } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getEmptyOptions() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/empty-options`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as Option[] } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getSeitentextAllgemein(name: string) { 
    const params : any = {};
    if(name != null) { params['name'] = name; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/seitentextAllgemein`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as NachrichtenTemplateContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getSeitentext(name: string, token: string, anrufgruppeId: number) { 
    const params : any = {};
    if(name != null) { params['name'] = name; }
    if(token != null) { params['token'] = token; }
    if(anrufgruppeId != null) { params['anrufgruppeId'] = anrufgruppeId; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/seitentext`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as NachrichtenTemplateContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  get(id: number) { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/NachrichtenTemplate/${id}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as NachrichtenTemplateContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  put(id: number, request: NachrichtenTemplateContainer) { 
    return this.httpService
      .requestWithBody({ method: 'PUT', url: URI`/api/NachrichtenTemplate/${id}`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(request: NachrichtenTemplateContainer) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/NachrichtenTemplate`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  delete(ids: number[]) { 
    const params : any = {};
    if(ids != null) { params['ids'] = ids; }
    return this.httpService
      .request({ method: 'DELETE', url: URI`/api/NachrichtenTemplate`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
