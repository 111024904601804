import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConfigurationContainer } from "./generated/types";
import { ActivatedRoute } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  templateUrl: './layout.component.html'
})
export class LayoutComponent implements OnInit, OnDestroy {

  private subscription = new Subscription();

  configuration: ConfigurationContainer;

  constructor(private route: ActivatedRoute) {

  }

  ngOnInit() {
    this.subscription.add(this.route.data.subscribe(({ configuration }: { configuration: ConfigurationContainer }) => {
      this.configuration = configuration;
    }));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}