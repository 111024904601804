<div id="cockpit" class="flex">
  <nav id="mainnav">
    <div id="logo"><img [src]="configuration.logoBackendUrl" alt="Logo" /></div>
  </nav>
  <div id="content">
    <header class="clearfix"></header>
    <main id="agent">
      <router-outlet></router-outlet>
    </main>
  </div>
</div>