<div class="relative">
  <!-- <div style="width:100%;  display: flex;justify-content: center;align-items: center;" *ngIf="uploadFileInProgress" id="loading" [innerHTML]="'Datei wird hochgeladen und ist in kürze verfügbar.'">
  </div> -->

  <div style="display: flex;justify-content: center;align-items: center;" *ngIf="uploadFileInProgress">
    <img style="height:70px; width: auto;"  src="assets/queue.png" alt="loading" />
   <div style="display:flex;justify-content: center;align-items: center;margin-left: -60px; margin-top: 0px;">
      loading
   </div>
  </div>
  <ng-container *ngIf="!uploadFileInProgress">
    <button class="emoji" title="Emoji einfügen" [disabled]="isDisabled" (click)="toggleEmojiPicker()"
      *ngIf="settings && !settings.emojiDisabled">Emoji</button>
    <button class="templates" title="Zeige NachrichtenTemplate" [disabled]="isDisabled" (click)="toggleTemplatePicker()"
      *ngIf="settings && settings.isAgent && agentTemplatesAvailable">Template</button>
    <!--Alternative überlegen wie man das sonst mit der Breite machen kann statt mit der input-class-->
    <input #nachrichtInput type="text" name="nachricht" [disabled]="isDisabled" [(ngModel)]="nachricht"
      autocomplete="off" placeholder="Nachricht" (keyup.arrowup)="selectWrite2UserList()" (keyup.enter)="send()"
      (keyup)="messageActions($event)" [autofocus]="currentGespraech?.typ===0"
      [class]="'input-iconcount-' + (1 + !settings.emojiDisabled + (agentTemplatesAvailable > 0))" *ngIf="settings" />
    <!--<textarea #nachrichtInput name="nachricht" [disabled]="isDisabled" [(ngModel)]="nachricht" autocomplete="off" placeholder="Nachricht" (keyup.enter)="send()" (keyup)="messageActions($event)"></textarea>-->
    <button class="send" title="Nachricht senden" [disabled]="isDisabled" (click)="send()"
      *ngIf="!inputIsEmpty()">Senden</button>
    <ng-container *ngIf="settings && !settings.uploadDisabled">
      <button class="attach" [class.inprogress]="chatService.uploadInProgress | async"
        [title]="(chatService.uploadInProgress | async) ? 'Upload gestartet' : 'Datei anhängen'" [disabled]="isDisabled"
        (click)="toggleUpload()" *ngIf="inputIsEmpty()">Anhang</button>
      <div class="upload" *ngIf="uploadVisible">
        <button class="close" title="Schließen" (click)="toggleUpload()">Schließen</button>
        <input type="file" name="upload" id="uploadFile" (change)="fileChanged($event)" />
        <button class="send" [class.inprogress]="chatService.uploadInProgress | async"
          [title]="(chatService.uploadInProgress | async) ? 'Upload gestartet' : 'Datei senden'" [disabled]="isDisabled"
          (click)="sendFile()" *ngIf="!uploadIsEmpty()">Senden</button>
      </div>
    </ng-container>
  </ng-container>
</div>
<div #write2userContainer tabindex="0" class="write2userContainer" [class.show]="write2UserList.length"
  (keydown)="navigate($event)">
  <div *ngFor="let item of write2UserList" (click)="writeTo(item)">@{{item}}</div>
</div>
<shared-emojipicker (emoji)="addEmojiToText($event);"></shared-emojipicker>