import { Component, OnInit, ViewChild, ElementRef, AfterViewChecked, AfterViewInit, Renderer2 } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConferenceStartModel } from "../../generated/types";
import { ConferenceData, ConferenceHub } from "../../services/conference.hub";
import { BaseComponent } from "../base.component";
import { LocalUserStream } from "../../webrtc/LocalUserStream";
import { AUDIOSETTINGS, BENUTZERTOKEN, GESPRAECHTOKEN, VIDEOSETTINGS } from "../../consts";
import { ConferenceHttpService } from "../../generated/conference-http.service";
import { WebRtcService } from "../../webrtc/webrtc.service";
import { LogService } from '@oevermann/angular';
import { StorageService } from '../../services/storage.service';
import { SignalRService } from "../../services/signalr.service";

export interface Data {
  conferenceinfos: ConferenceStartModel;
}
@Component({
  selector: 'shared-conference-start',
  templateUrl: './conference.start.component.html'
})
export class ConferenceStartComponent extends BaseComponent implements OnInit {

  startdata: ConferenceStartModel | null = null;
  token: string;
  canJoin: boolean;
  localVideo: HTMLVideoElement;
  localStream: any;

  video: boolean;
  audio: boolean;

  @ViewChild('preview') preview: ElementRef;
  @ViewChild('mediaSettingsContainer') mediaSettingsContainer: ElementRef;

  constructor(private conferenceHub: ConferenceHub,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    private conferenceHttpService: ConferenceHttpService,
    private webRtcService: WebRtcService,
    private logService: LogService,
    private signalRService: SignalRService,
    private storageService: StorageService) {
    super();
    this.subscription.add(this.route.data.subscribe((data: Data) => {
      this.startdata = data.conferenceinfos;
      this.canJoin = data.conferenceinfos.conferenceStartInfo.hasStarted;
      conferenceHub.joinWaitingRoom(this.startdata.conferenceStartInfo.token);
    }));
  }

  ngOnInit() {
    this.initPreview(this.startdata.teilnehmer.rechtevorlage.initialVideoActive, this.startdata.teilnehmer.rechtevorlage.initialAudioActive);
    this.subscription.add(this.conferenceHub.onInformConferenceStarted.subscribe(([token]) => {
      if (token === this.startdata.conferenceStartInfo.token) {
        sessionStorage.setItem(GESPRAECHTOKEN, token);
        this.canJoin = true;
      }
    }));
  }

  async initPreview(video: boolean, audio: boolean) {
    // console.log('initPreview');
    this.video = video;
    this.audio = audio;

    const configuration = await this.webRtcService.getGeneralConfiguration();
    let videoTrackConstraints: MediaTrackConstraints = {};

    videoTrackConstraints.facingMode = { ideal: 'user' };
    videoTrackConstraints.width = { ideal: configuration.idealWidth ? configuration.idealWidth : 1024 };
    videoTrackConstraints.height = { ideal: configuration.idealHeight ? configuration.idealHeight : 768 };
    videoTrackConstraints.frameRate = { ideal: configuration.idealFrameRate ? configuration.idealFrameRate : 15 };

    if (!!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)) {
      try {
        const savedSettingVideo = JSON.parse(this.storageService.getItem(VIDEOSETTINGS)) as MediaTrackSettings;
        if (savedSettingVideo) {
          if (savedSettingVideo.deviceId != null && savedSettingVideo.deviceId != '') {
            videoTrackConstraints.deviceId = { ideal: savedSettingVideo.deviceId.toLocaleString() };
            videoTrackConstraints.facingMode = null;
          }
        }

        const audioTrackConstraints: MediaTrackConstraints = {};
        const saveSettingsAudio = JSON.parse(this.storageService.getItem(AUDIOSETTINGS)) as MediaTrackSettings;

        if (saveSettingsAudio) {
          if (saveSettingsAudio.deviceId != null && saveSettingsAudio.deviceId != '') {
            audioTrackConstraints.deviceId = { ideal: saveSettingsAudio.deviceId.toLocaleString() };
          }
          audioTrackConstraints.autoGainControl = saveSettingsAudio.autoGainControl ?? false;
          audioTrackConstraints.echoCancellation = saveSettingsAudio.echoCancellation ?? true;
          audioTrackConstraints.noiseSuppression = saveSettingsAudio.noiseSuppression ?? false;
        }

        const { stream, message } = await LocalUserStream.getUserMediaWithMessage({
          audio: audio ? (audioTrackConstraints != {} ? audioTrackConstraints : true) : false,
          video: video ? videoTrackConstraints : false
        }, this.logService);

        this.streamCallback(stream);
      }
      catch (err) {
        this.handleError(err);
      }
      //navigator.mediaDevices.getUserMedia({ audio: this.audio, video: this.video ? mediaTrackConstraints : false })
      //  .then(this.streamCallback)
      //  .catch(this.handleError);
    }
    else {
      alert('Keine Kamera verfügbar.'); // ist das die richtige Prüfung???
    }
  }

  private streamCallback = (stream: MediaStream) => {
    this.renderer.setProperty(this.preview.nativeElement, 'srcObject', stream);
    this.localVideo = (this.preview.nativeElement as HTMLVideoElement);
    this.video ? this.startPreview(stream) : this.stopPreview(false);
  }

  startPreview(stream: MediaStream) {
    // console.log('startPreview');
    this.localStream = stream;
    if (this.startdata.teilnehmer.rechtevorlage.initialVideoActive) {
      try {
        // console.log('localVideo.play');
        this.localVideo.play();
      } catch (error) {
        console.log('error playing video: ', error);
      }
    }
  }

  stopPreview(stopTrack: boolean) {
    // console.log('stopPreview');
    if (this.localStream && this.localStream.getTracks()[0]) {
      if (this.localVideo) {
        // console.log('localVideo.pause');
        this.localVideo.pause();
        //this.localVideo.src = null;
      }

      if (stopTrack) {
        this.localStream.getTracks()[0].stop();
      }
    }
  }

  handleError(error) {
    console.log('Error: ', error);
  }

  setVideo(event: Event) {
    // console.log('setVideo');
    event && event.preventDefault();

    // console.log('this.localVideo', this.localVideo);
    if (this.localVideo && this.localVideo.paused) {
      this.video = true;
      this.initPreview(this.video, this.audio);
    }
    else {
      this.stopPreview(false);
      this.video = false;
    }
  }

  setAudio(event: Event) {
    event && event.preventDefault();
    this.audio = !this.audio;
  }

  joinConference(event: Event) {
    event && event.preventDefault();
    sessionStorage.setItem(BENUTZERTOKEN, this.startdata.teilnehmer.benutzerToken);
    this.stopPreview(true);

    if (this.startdata.teilnehmer.isOrganizer) {
      this.conferenceHub.conferenceStarten(this.startdata.conferenceStartInfo.token, this.startdata.teilnehmer.benutzerToken, this.audio, this.video, this.signalRService.connectionId).then(() => {
        this.router.navigate(["../monitor"], { relativeTo: this.route });
      });
    }
    else {
      this.conferenceHub.conferenceBeitreten(this.startdata.conferenceStartInfo.token, this.startdata.teilnehmer.benutzerToken, this.audio, this.video).then(() => {
        sessionStorage.setItem(GESPRAECHTOKEN, this.startdata.conferenceStartInfo.token);
        this.router.navigate(["../chat"], { relativeTo: this.route });
      });
    }
  }

  showMediaSetttings(event: Event) {
    event && event.preventDefault();
    if (this.mediaSettingsContainer?.nativeElement) {
      (this.mediaSettingsContainer.nativeElement as HTMLDivElement).classList.toggle("open");
    }
  }

}