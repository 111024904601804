import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { RueckrufgesuchContainer, RueckrufgesuchRequestContainer, BadRequestModel } from "./types";
import { HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class RueckrufHttpService {

  constructor(private httpService : HttpService) { }

  get() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/Rueckruf`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as RueckrufgesuchContainer[] } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(request: RueckrufgesuchRequestContainer) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/Rueckruf`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as BadRequestModel } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
