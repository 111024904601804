import { Injectable } from '@angular/core';
import { TeilnehmerContainer } from '../generated/types';

@Injectable()
export class GespraecheListeService {

  getTeilnehmerListe(teilnehmer: TeilnehmerContainer[]) {
    var tn = "";
    for (var i = 0; i < teilnehmer.length; i++) {
      tn += teilnehmer[i].name + ", ";
    }
    return tn.slice(0, -2);
  }

  getDatum(datum: string) {
    var date = new Date(datum);
    return ('00' + date.getDate()).slice(-2) + '.' + ('00' + (date.getMonth() + 1)).slice(-2) + '.' + ('000' + date.getFullYear()).slice(-4) + ' ' + ('00' + date.getHours()).slice(-2) + ':' + ('00' + date.getMinutes()).slice(-2) + ':' + ('00' + date.getSeconds()).slice(-2)
  }

}