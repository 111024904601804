<ng-container *ngIf="visibleForTeilnehmer; else notVisibleForTeilnehmer">
  <div *ngIf="currentUserToken != n.SenderToken" class="from">Von <span (click)="whisperAnswer(n.SenderName)" title="{{senderStream | teilnehmername:forTeilnehmer:n.SenderName}} anflüstern">{{senderStream | teilnehmername:forTeilnehmer:n.SenderName}}</span></div>
  <div *ngIf="n.ReceiverName && currentUserToken != n.ReceiverToken" class="from">An <span (click)="whisperAnswer(n.ReceiverName)" title="{{n.ReceiverName}} anflüstern">{{n.ReceiverName}}</span></div>
</ng-container>
<ng-template #notVisibleForTeilnehmer>
  <div *ngIf="currentUserToken != n.SenderToken" class="from">Von <span>{{senderStream | teilnehmername:forTeilnehmer:n.SenderName}}</span></div>
  <div *ngIf="n.ReceiverName && currentUserToken != n.ReceiverToken" class="from">An <span>{{n.ReceiverName}}</span></div>
</ng-template>
<div class="text"><shared-template [isNoAgent]="isNoAgent" [inputText]="n.Text" [token]="n.GespraechToken" (onAddSysteminfo)="addSysteminfo($event)"></shared-template></div>
<div class="info">{{n.GesendetAm.substring(11)}} Uhr</div>
