import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'shared-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent {

  @Input() controlToValidate: FormControl;
   @Input() public reqMessage: string='Eine Eingabe wird erwartet';

}