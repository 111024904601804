import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'shared-emojipicker',
  templateUrl: './emojipicker.component.html'
})
export class EmojiPickerComponent {

  @Output() emoji: EventEmitter<string> = new EventEmitter<string>();

  addEmoji(unicode: string) {
    this.emoji.next(unicode);
  }

}