import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
  name: 'styles'
})
export class StylesNamePipe implements PipeTransform {
  transform(value: string, value2: string='') {
    let ret = "";
    switch (value) {
      case "background-color":
        ret = "Hintergrund-Farbe" + value2
        break;
      case "color":
        ret = "Text-Farbe"
        break;
      case "text-decoration":
        ret = "Text-Dekoration"
        break;
      case "max-width":
        ret = "Maximale Breite"
        break;
      case "max-height":
        ret = "Maximale Höhe"
        break;
      case "link-color":
        ret = "Link-Text-Farbe"
        break;
      case "link-color-hover":
        ret = "Link-Text-Farbe (hover)"
        break;
      default:
        ret = value;
    }
    return ret;
  }
}
