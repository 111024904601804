import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { isNullOrEmpty } from '@oevermann/core';
import { defer, merge, Observable, Subscription } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, connect, map, scan, shareReplay, switchMap, tap } from 'rxjs/operators';
import { ChatNachricht } from '../chatnachricht';
import { BENUTZERTOKEN, GESPRAECHTOKEN } from '../consts';
import { GespraechsStatus } from '../enums/gespraechsstatus';
import { TeilnehmerStatus } from '../enums/teilnehmerstatus';
import { NachrichtenTemplateHttpService } from '../generated/nachrichten-template-http.service';
import { GespraechContainer, NachrichtenTemplateContainer } from '../generated/types';
import { IncomingCallContainer } from '../incomingcallcontainer';
import { CapabilityService } from '../services/capability.service';
import { ChatHub, StateContainer } from '../services/chat.hub';

interface OfflineState {
  status: TeilnehmerStatus.Offline;
}

interface VerfuegbarState {
  status: TeilnehmerStatus.Verfuegbar;
}

interface BeschaeftigtState {
  status: TeilnehmerStatus.Beschaeftigt;
}

interface WartenAufTerminState {
  status: TeilnehmerStatus.WartenAufTermin;
}

interface InVermittlungState {
  status: TeilnehmerStatus.InVermittlung;
  incomingCall: IncomingCallContainer;
}

interface ImGespraechState {
  status: TeilnehmerStatus.ImGespraech;
  gespraech: GespraechContainer;
  history: ChatNachricht[];
}

interface InNachbearbeitungState {
  status: TeilnehmerStatus.InNachbearbeitung;
}

export type MonitorState =
  | OfflineState
  | VerfuegbarState
  | BeschaeftigtState
  | WartenAufTerminState
  | InVermittlungState
  | ImGespraechState
  | InNachbearbeitungState;

type Reducer<S> = (state: S) => S;

function mergeAndScan<S>(...observables: Observable<Reducer<S>>[]) {
  return merge(...observables).pipe(scan<Reducer<S>, S>((state, reducer) => reducer(state), undefined));
}

export function enterZone(zone: NgZone) {
  return <T>(source: Observable<T>) =>
    new Observable<T>(observer =>
      source.subscribe({
        next: (x) => zone.run(() => observer.next(x)),
        error: (err) => observer.error(err),
        complete: () => observer.complete()
      })
    );
}

@Injectable()
export class MonitorService implements OnDestroy {

  public isNoAgent = false;

  state: Observable<MonitorState>;
  private subscription = new Subscription();

  constructor(
    private chatHub: ChatHub,
    private capabilityService: CapabilityService,
    private nachrichtenTemplateHttpService: NachrichtenTemplateHttpService,
    private router: Router) {

    const onBeigetreten = defer(() => this.chatHub.userGruppeBeitreten());

    this.subscription.add(
      chatHub.signalRConnected.subscribe(state => {
        this.chatHub.userGruppeBeitreten();
      })
    );

  this.chatHub.onPushMaxVerfuegbarAlert.pipe(
      switchMap(agentCount => this.nachrichtenTemplateHttpService.getSeitentextAllgemein('MaxVerfuegbarAgentsReached')
        .pipe(map(res => (res.data as NachrichtenTemplateContainer).text.replace('###count###', agentCount.toString())))),
      catchError(err => of(null))
    ).subscribe(text => {
      if (text) {
        alert(text);
      }
    });

    this.state = mergeAndScan<MonitorState>(
      onBeigetreten.pipe(switchMap(this.projectBeigetreten.bind(this))),
      this.chatHub.onOffline.pipe(map(this.projectOffline.bind(this))),
      this.chatHub.onVerfuegbar.pipe(map(this.projectVerfuegbar.bind(this))),
      this.chatHub.onBeschaeftigt.pipe(map(this.projectBeschaeftigt.bind(this))),
      this.chatHub.onWartenAufTemin.pipe(map(this.projectWartenAufTermin.bind(this))),
      this.chatHub.onInVermittlung.pipe(map(this.projectInVermittlung.bind(this))),
      this.chatHub.onImGespraech.pipe(switchMap(this.projectImGespraech.bind(this))),
      this.chatHub.onInNachbearbeitung.pipe(switchMap(this.projectInNachbearbeitung.bind(this))),
      this.chatHub.onBeenden.pipe(map(this.projectBeenden.bind(this))),
      this.chatHub.onActivateBeweissicherung.pipe(map(this.projectActivateBeweissicherung.bind(this)))
    ).pipe(shareReplay(1));
  
    this.chatHub.onPushIsNoAgent.pipe(map(x => x)).subscribe((x) => this.isNoAgent =  x);

  }


  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get benutzerToken() {
    return sessionStorage.getItem(BENUTZERTOKEN);
  }

  chatWirdBeendet = false;

  agentIstImGespraech() {
    return !isNullOrEmpty(sessionStorage.getItem(GESPRAECHTOKEN));
  }

  private async projectBeigetreten(container: StateContainer): Promise<Reducer<MonitorState>> {

    const { token, status, gespraech, incomingCall } = container;
    sessionStorage.setItem(BENUTZERTOKEN, token);

    if (status === TeilnehmerStatus.Offline) {
      return this.projectOffline();
    } else if (status === TeilnehmerStatus.Beschaeftigt) {
      return this.projectBeschaeftigt();
    } else if (status === TeilnehmerStatus.ImGespraech) {
      return await this.projectImGespraech(gespraech, this.benutzerToken);
    } else if (status === TeilnehmerStatus.InVermittlung) {
      return this.projectInVermittlung(incomingCall);
    } else if (status === TeilnehmerStatus.Verfuegbar) {
      return this.projectVerfuegbar();
    } else if (status === TeilnehmerStatus.WartenAufTermin) {
      return this.projectWartenAufTermin();
    }

    throw new Error('Status kann nicht verarbeitet werden: ' + status);
  }

  private projectOffline(): Reducer<MonitorState> {
    return () => {
      return {
        status: TeilnehmerStatus.Offline
      };
    };
  }

  private projectVerfuegbar(): Reducer<MonitorState> {
    return () => {
      return {
        status: TeilnehmerStatus.Verfuegbar
      };
    };
  }

  private projectBeschaeftigt(): Reducer<MonitorState> {
    return () => {
      return {
        status: TeilnehmerStatus.Beschaeftigt
      };
    };
  }

  private projectInVermittlung(incomingCall: IncomingCallContainer): Reducer<MonitorState> {
    return () => {
      return {
        status: TeilnehmerStatus.InVermittlung,
        incomingCall
      };
    };
  }

  private projectWartenAufTermin(): Reducer<MonitorState> {
    return () => {
      return {
        status: TeilnehmerStatus.WartenAufTermin
      };
    };
  }

  private async projectImGespraech(gespraech: GespraechContainer, benutzerToken: string): Promise<Reducer<MonitorState>> {
    const history = await this.chatHub.loadChatHistory(gespraech.token, benutzerToken);
    // das benutzertoken ist hier nicht immer richtig gesetzt.
    sessionStorage.setItem(GESPRAECHTOKEN, gespraech.token);
    this.router.navigate(['cockpit', 'monitor']);
    return () => {
      return {
        status: TeilnehmerStatus.ImGespraech as TeilnehmerStatus.ImGespraech,
        gespraech,
        history
      };
    };
  }

  private async projectInNachbearbeitung(gespraechId: number): Promise<Reducer<MonitorState>> {
    await this.router.navigate(['cockpit', 'beendet', gespraechId]);
    return () => {
      return {
        status: TeilnehmerStatus.InNachbearbeitung
      };
    };
  }

  private projectBeenden(params: [string, GespraechsStatus, string]): Reducer<MonitorState> {
    const [token, status, beendetAm] = params;
    return state => {

      if (state.status === TeilnehmerStatus.ImGespraech && state.gespraech.token === token) {
        return {
          ...state,
          gespraech: {
            ...state.gespraech,
            beendetAm,
            status
          }
        };
      }

      return state;
    };
  }

  private projectActivateBeweissicherung(): Reducer<MonitorState> {
    return state => {

      if (state.status === TeilnehmerStatus.ImGespraech) {
        return {
          ...state,
          gespraech: {
            ...state.gespraech,
            beweissicherung: true
          }
        };
      }

      return state;
    };
  }

  onPushAgenten = this.chatHub.onPushAgenten;

  onPushEingehendeAnrufe = this.chatHub.onPushEingehendeAnrufe;

  onPushSystemstatus = this.chatHub.onPushSystemstatus;

  onRemoveIncomingCall = this.chatHub.onRemoveIncomingCall;

  onRemindEinladung = this.chatHub.onRemindEinladung;

  onPushRueckrufgesuchEingestellt = this.chatHub.onPushRueckrufgesuchEingestellt;

  onPushRueckrufStatusAenderung = this.chatHub.onPushRueckrufStatusAenderung;

  async monitorGruppeBeitreten() {
    await this.chatHub.monitorGruppeBeitreten();
  }

  offline() {
    return this.chatHub.offline();
  }

  async verfuegbar() {
    await this.chatHub.verfuegbar(await this.capabilityService.getArbeitsplatzausstattung());
  }

  async beschaeftigt() {
    await this.chatHub.beschaeftigt();
  }

  async wartenAufTermin() {
    await this.chatHub.wartenAufTermin(await this.capabilityService.getArbeitsplatzausstattung());
  }

  annehmen(token: string, connectionId: string) {
    return this.chatHub.annehmen(token, connectionId);
  }

  getStatusName(status: TeilnehmerStatus) {
    let ret = 'Offline'; //Default offline
    switch (status) {
      case TeilnehmerStatus.Verfuegbar:
        ret = 'Verfügbar';
        break;
      case TeilnehmerStatus.Beschaeftigt:
        ret = 'Beschäftigt';
        break;
      case TeilnehmerStatus.InNachbearbeitung:
        ret = 'In Nachbearbeitung';
        break;
      case TeilnehmerStatus.InVermittlung:
        ret = 'In Vermittlung';
        break;
      case TeilnehmerStatus.ImGespraech:
        ret = 'Im Gespräch';
        break;
      default:
        ret = 'Offline';
        break;
    }
    return ret;
  }

  formatTime(time) {
    return this.padLeft(time.getHours(), 2, '') + ':' + this.padLeft(time.getMinutes(), 2, '') + ':' + this.padLeft(time.getSeconds(), 2, '');
  }

  padLeft(nr, n, str) {
    return Array(n - String(nr).length + 1).join(str || '0') + nr;
  }
}
