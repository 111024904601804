import { Input, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Option } from "../generated/types";


@Component({
  selector: 'shared-dropdown-input',
  templateUrl: './dropdown-input.component.html'
}) 

export class DropdownInputComponent {
  @Input() id: string;
  @Input() label: string;
  @Input() items: Option[];
  @Input() control: FormControl;
  @Input() optional = false;
  @Input() required = false;
}
