import { Injectable, OnDestroy } from "@angular/core";
import { DeviceInfo, getDeviceInfo } from "../shared/helpers";
import { fromEvent, of, combineLatest, defer, from as fromPromise } from "rxjs";
import { startWith, flatMap, map, catchError, take } from "rxjs/operators";
import { Arbeitsplatzausstattung } from "../enums/arbeitsplatzausstattung";
import { WebRtcHub } from "./webrtc.hub";
import { closeRtcPeerConnection } from '../webrtc/helpers';

const isEventTarget = (sourceObj: any): sourceObj is EventTarget => {
  return !!sourceObj && typeof sourceObj.addEventListener === 'function' && typeof sourceObj.removeEventListener === 'function';
}

/** Die Ausprägungen einer Fähigkeit. */
export enum Capability {
  /** Fähigkeit kann nicht ermittelt werden. */
  Unknown = 0,
  /** Fähig */
  Capable = 1,
  /** Unfähig */
  Incapable = 2
}

const updateCapability = (capability: Capability, isCapable: boolean) => capability === Capability.Capable || isCapable ? Capability.Capable : Capability.Incapable;

/** Information über die Fähigkeit von Eingabe und Ausgabe von Audio und Video. */
export interface MediaCapability {
  /** Gibt an ob eine Kamera zur Verfügung steht. */
  camera: Capability;
  /** Gibt an ob ein Lautsprecher zur Verfügung steht. */
  speaker: Capability;
  /** Gibt an ob ein Mikrofon zur Verfügung steht. */
  microphone: Capability;
}

/** Informationen über die Fähigkeit einen Videochat zu starten */
export type WebRTCCapability = {
  /** Gibt an, ob der Browser WebRTC unterstützt. */
  browser: Capability;
  /** Gibt an ob der WebRTC-Server erreicht werden kann. */
  network: Capability;
} & MediaCapability;

/**
 * Gibt die WebRTC-Fähigkeit des Browsers zurück.
 */
function getDeviceCapability(deviceInfo: DeviceInfo): Capability {

  const name = deviceInfo.name;
  const major = parseInt(deviceInfo.version.split('.')[0]);

  if ((window as any).RTCPeerConnection == undefined) {
    console.log('RTCPeerConnection undefined');
    return Capability.Incapable;
  }

  const capable = (name === 'chrome' && major > 52)
    || name === 'edge-chromium'
    || (name === 'opera' && major >= 43)
    || (name === 'firefox' && major > 36)
    || ((name === 'safari' || name === 'ios') && major >= 11)
    || (name === 'samsung' && major >= 11);
  // console.log('getDeviceCypapbility: ' + name + ':' + major);
  return capable ? Capability.Capable : Capability.Incapable;
}

@Injectable()
export class CapabilityService {

  private readonly deviceInfo: DeviceInfo;

  /** Gibt an ob der Browser WebRTC unterstützt.*/
  private readonly browserCapability: Capability;

  constructor(private webRtcHub: WebRtcHub) {
    this.deviceInfo = getDeviceInfo();
    this.browserCapability = getDeviceCapability(this.deviceInfo);
  }
    
  /**
 * Gibt die Fähigkeit zurück sich mit dem TURN-Server zu verbinden.
 */
  async getNetworkCapability() {

    if (this.browserCapability !== Capability.Capable) {
      return Capability.Unknown; // status weiss
    }

    const configuration = await this.webRtcHub.getGenralConfiguration();

    let peerConnection = new RTCPeerConnection({ iceServers: configuration.iceServers });
    console.log('capability service created RTCPeerConnection', peerConnection);
    
    try {

      const capabilityPromise = new Promise<Capability>((resolve, reject) => {
      
        peerConnection.addEventListener('icegatheringstatechange', () => {
          if (peerConnection && peerConnection.iceGatheringState === 'complete') {
            resolve(Capability.Incapable); // status 2 (rot) Ice Server, deutet auf einen Verbindungsfehler hin. Passiert auch, wenn die Lizent abgelaufen ist.
          }
        });

        peerConnection.onicecandidate = ({ candidate }) => {

          if (candidate == undefined) {
            return;
          }

          if (candidate.candidate.indexOf('typ relay') !== -1) {
            resolve(Capability.Capable);
          }
        };
      });
      
      const offer = await peerConnection.createOffer({ offerToReceiveAudio: 1 } as any);
      await peerConnection.setLocalDescription(offer);

      const capability = await capabilityPromise;

      return capability;

    } finally {
      console.log('connection ist closed');
      closeRtcPeerConnection(peerConnection, false);
      // capability service closed RTCPeerConnection', peerConnection);
      peerConnection = null;
    }
  }

  /** Die Fähigkeit sich mit dem TURN-Server zu verbinden. */
  private networkCapability = defer(() => fromPromise(this.getNetworkCapability()))
    .pipe(startWith(Capability.Unknown));

  /** Ermittelt anhand der Media Devices die Media Capability. */
  private reduceMediaDevices = (devices: MediaDeviceInfo[]) => {

    const mediaCapability = devices.reduce((capability: MediaCapability, device) => ({
      camera: updateCapability(capability.camera, device.kind === 'videoinput'),
      speaker: updateCapability(capability.speaker, device.kind === 'audiooutput'),
      microphone: updateCapability(capability.microphone, device.kind === 'audioinput')
    }), { camera: Capability.Incapable, speaker: Capability.Incapable, microphone: Capability.Incapable } as MediaCapability);

    // Ausnahmen für Firefox und Safari, da diese keine Information liefern, ob ein Lautsprecher verfügbar ist.
    if ((this.deviceInfo.name === 'firefox' || this.deviceInfo.name === 'safari' || this.deviceInfo.name === 'ios') && mediaCapability.speaker === Capability.Incapable) {
      mediaCapability.speaker = Capability.Unknown;
    }

    return mediaCapability;
  }

  /** Gibt an ob Kamera, Lautsprecher und Mikrofon zur Verfügung stehen. */
  private mediaCapability = isEventTarget(navigator.mediaDevices)
    ? fromEvent(navigator.mediaDevices, 'devicechange')
      .pipe(
        startWith(null),
        flatMap(() => navigator.mediaDevices.enumerateDevices()),
        map<MediaDeviceInfo[], MediaCapability>(this.reduceMediaDevices),
        catchError(() => of<MediaCapability>({
          camera: Capability.Unknown,
          speaker: Capability.Unknown,
          microphone: Capability.Unknown
        }))
      )
    : of<MediaCapability>({
      camera: Capability.Unknown,
      speaker: Capability.Unknown,
      microphone: Capability.Unknown
    });

  /** Gibt an welche WebRTC-Fähigkeiten zur Verfügung stehen. */
  webRtcCapability = combineLatest(
    this.networkCapability,
    this.mediaCapability, (network, device) => ({
      browser: this.browserCapability,
      network,
      camera: device.camera,
      speaker: device.speaker,
      microphone: device.microphone
    }));

  private arbeitsplatzausstattung = this.mediaCapability.pipe(
    map(media => {

      let result = Arbeitsplatzausstattung.none;

      if (media.camera !== Capability.Incapable) {
        result |= Arbeitsplatzausstattung.kamera;
      }

      if (media.microphone !== Capability.Incapable) {
        result |= Arbeitsplatzausstattung.mikrofon;
      }

      if (media.speaker !== Capability.Incapable) {
        result |= Arbeitsplatzausstattung.lautsprecher;
      }

      return result;
    })
  );

  getArbeitsplatzausstattung() {
    return this.arbeitsplatzausstattung.pipe(take(1)).toPromise();
  }
}
