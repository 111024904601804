import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { Component, Input, OnDestroy, forwardRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

/** Eingabekomponente für Enums mit Flags-Attribut. */
@Component({
  selector: 'on-flags-select',
  template: `
    <div *ngFor="let option of options; let i = index">
      <input [disabled]="disabled" type="checkbox" [checked]="isChecked(i + 1)" (click)="toggle(i + 1)" />
      <label>{{option}}</label>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FlagsSelectComponent),
      multi: true
    }
  ]
})
export class FlagsSelectComponent implements ControlValueAccessor, OnDestroy {

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscription = new Subscription();

  disabled = false;

  value = new BehaviorSubject(0);

  /** Die Optionen als Array mit Strings, d.h. für "[Flags] enum { None = 0; Agent = 1; Kunde = 2 }" muss hier "['Agent', 'Kunde']" übergeben werden. */
  @Input() options: string[];

  writeValue(value: number): void {
    this.value.next(value);
  }

  registerOnChange(fn: (value: number) => void): void {
    this.subscription.add(this.value.subscribe(fn));
  }


  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  isChecked(value: number) {
    return !!(this.value.value & value);
  }

  toggle(value: number) {
    const nextValue = this.isChecked(value)
      ? this.value.value & ~value
      : this.value.value | value;
    this.value.next(nextValue);
  }
}
