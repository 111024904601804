import { getAudioTrackFromStream, getVideoTrackFromStream, hasMultipleCameras, stopStream } from "./helpers";
import { BehaviorSubject, fromEvent, defer } from "rxjs";
import { v4 as uuid } from 'uuid';
import { StreamSourceType } from "../services/conference.hub";
import { startWith, switchMap } from "rxjs/operators";
import { AUDIOSETTINGS, CAMERA_BACKGORUND_BLUR_SETTING, VIDEOSETTINGS } from '../consts';
import { createLogMessage, LogLevel } from '@oevermann/core';
import { LogService } from '@oevermann/angular';
import { StorageService } from '../services/storage.service';
import { SelfieSegmentation } from "@mediapipe/selfie_segmentation";
//import { Camera } from "@mediapipe/camera_utils";
import { Base64FileModel } from "../generated/types";

/** Kapselt den das Erzeugen und Verändern eines Kamera Streams in einem Objekt. */
export class LocalUserStream {

  private isEventTarget = (sourceObj: any): sourceObj is EventTarget => {
    return !!sourceObj && typeof sourceObj.addEventListener === 'function' && typeof sourceObj.removeEventListener === 'function';
  }

  static allStreams = new Map<string, MediaStream>();

  static async getUserMediaWithMessage(mediaStreamConstraints: MediaStreamConstraints, logService: LogService) {

    if (!mediaStreamConstraints.audio && !mediaStreamConstraints.video) {
      console.log("getUserMediaWithMessage missing constraints", mediaStreamConstraints);
      return { stream: new MediaStream(), message: null };
    }

    let message: string | null = null;
    let stream: MediaStream | null = null;

    const setStreamOrMessage = async (mediaStreamConstraints: MediaStreamConstraints) => {

      try {
        stream = await navigator.mediaDevices.getUserMedia(mediaStreamConstraints);
        this.allStreams.set(stream.id, stream);
        console.log("getUserMediaWithMessage added stream", stream.id);
      } catch (error) {
        console.log("getUserMedia error", error);
        console.log("getUserMedia mediaStreamConstraints", mediaStreamConstraints);
        await logService.logAsync(LogLevel.INFO, 'getUserMedia error', error, JSON.stringify(mediaStreamConstraints));
        if (error.name === 'NotAllowedError') {
          if (mediaStreamConstraints.audio && mediaStreamConstraints.video) {
            message = 'Der Zugriff auf das Mikrofon und die Kamera wurde verweigert. Bitte wählen Sie ggf. eine andere Kamera oder ein anderes Mikrofon.';
          } else if (mediaStreamConstraints.audio) {
            message = 'Der Zugriff auf das Mikrofon wurde verweigert. Bitte wählen Sie ggf. ein anderes Mikrofon.';
          } else if (mediaStreamConstraints.video) {
            message = 'Der Zugriff auf die Kamera wurde verweigert. Bitte wählen Sie ggf. eine andere Kamera.';
          }
        } else if (error.name === 'NotFoundError') {
          if (mediaStreamConstraints.audio && mediaStreamConstraints.video) {
            message = 'Es wurde kein Mikrofon oder keine Kamera gefunden.';
          } else if (mediaStreamConstraints.audio) {
            message = 'Es wurde kein Mikrofon gefunden.';
          } else if (mediaStreamConstraints.video) {
            message = 'Es wurde keine Kamera gefunden.';
          }
        } else if (error.name === 'NotReadableError') {
          if (mediaStreamConstraints.audio && mediaStreamConstraints.video) {
            message = 'Der Zugriff auf das Mikrofon oder die Kamera ist nicht möglich. Möglicherweise blockiert eine andere Anwendung den Zugriff. Bitte wählen Sie ggf. eine andere Kamera oder ein anderes Mikrofon.';
          } else if (mediaStreamConstraints.audio) {
            message = 'Der Zugriff auf das Mikrofon ist nicht möglich. Möglicherweise blockiert eine andere Anwendung den Zugriff. Bitte wählen Sie ggf. ein anderes Mikrofon.';
          } else if (mediaStreamConstraints.video) {
            message = 'Der Zugriff auf die Kamera ist nicht möglich. Möglicherweise blockiert eine andere Anwendung den Zugriff. Bitte wählen Sie ggf. eine andere Kamera.';
          }
        } else {
          if (mediaStreamConstraints.audio && mediaStreamConstraints.video) {
            message = 'Der Zugriff auf das Mikrofon und die Kamera ist nicht möglich. Bitte wählen Sie ggf. eine andere Kamera oder ein anderes Mikrofon.';
          } else if (mediaStreamConstraints.audio) {
            message = 'Der Zugriff auf das Mikrofon ist nicht möglich. Bitte wählen Sie ggf. ein anderes Mikrofon.';
          } else if (mediaStreamConstraints.video) {
            message = 'Der Zugriff auf die Kamera ist nicht möglich. Bitte wählen Sie ggf. eine andere Kamera.';
          }
        }
      }
    };

    // Wir versuchen den Stream zu setzen.
    await setStreamOrMessage(mediaStreamConstraints);

    // Wenn wir keinen Stream haben, aber Audio und Video angefragt wurde, probieren wir Audio und Video einzeln durch.
    if (!stream && mediaStreamConstraints.audio && mediaStreamConstraints.video) {

      // Wir probieren ohne devideId, vielleicht klappt es ja mit einer anderen Kamera / Mikrofon
      console.log("try mediaStreamConstraints without deviceid");
      if (mediaStreamConstraints.audio && (mediaStreamConstraints.audio as MediaTrackConstraints).deviceId) {
        (mediaStreamConstraints.audio as MediaTrackConstraints).deviceId = null;
      }
      if (mediaStreamConstraints.video && (mediaStreamConstraints.video as MediaTrackConstraints).deviceId) {
        (mediaStreamConstraints.video as MediaTrackConstraints).deviceId = null;
      }
      await logService.logAsync(LogLevel.DEBUG, 'try mediaStreamConstraints without deviceid', JSON.stringify(mediaStreamConstraints));
      await setStreamOrMessage(mediaStreamConstraints);

      if (!stream) {
        console.log("try mediaStreamConstraints minimal");
        const mediaStreamConstraintsMinimal = { audio: { deviceId: null }, video: { deviceId: null, facingMode: { ideal: 'user' } } };
        await logService.logAsync(LogLevel.DEBUG, 'try mediaStreamConstraints minimal', JSON.stringify(mediaStreamConstraintsMinimal));
        await setStreamOrMessage(mediaStreamConstraintsMinimal);
      }

      // Erst nur Audio,
      if (!stream) {
        console.log("try audioOnlyMediaStreamConstraints");
        const audioOnlyMediaStreamConstraints = { audio: mediaStreamConstraints.audio };
        await logService.logAsync(LogLevel.DEBUG, 'try audioOnlyMediaStreamConstraints', JSON.stringify(audioOnlyMediaStreamConstraints));
        await setStreamOrMessage(audioOnlyMediaStreamConstraints);
      }

      // wenn das nicht geplappt hat, dann nur Video.
      if (!stream) {
        console.log("try videoOnlyMediaStreamConstraints");
        const videoOnlyMediaStreamConstraints = { video: mediaStreamConstraints.video };
        await logService.logAsync(LogLevel.DEBUG, 'try videoOnlyMediaStreamConstraints', JSON.stringify(videoOnlyMediaStreamConstraints));
        await setStreamOrMessage(videoOnlyMediaStreamConstraints);
      }

      // Wenn auch das nicht geklappt hat, dann noch mal beides fragen, da Chrome sich merkt, was zuletzt angefragt wurde und der Nutzer sonst bei der Freigabe evtl. nur Audio oder Video freigibt, 
      // obwohl ursprünglich beides angefragt wurde.
      // Wir probieren es ohne deviceId
      if (!stream) {
        console.log("try mediaStreamConstraints final");
        const mediaStreamConstraintsFinal = { audio: { deviceId: null }, video: { deviceId: null, facingMode: { ideal: 'user' } } };
        await logService.logAsync(LogLevel.DEBUG, 'try mediaStreamConstraints final', JSON.stringify(mediaStreamConstraintsFinal));
        await setStreamOrMessage(mediaStreamConstraintsFinal);
      }
    }

    if (!stream) {
      console.log("empty stream");
      await logService.logAsync(LogLevel.DEBUG, 'empty stream', JSON.stringify(mediaStreamConstraints));
      stream = new MediaStream();
    } else {
      message = null;
    }

    //const clonedStream = stream.clone();

    return { stream, message };
  }

  static async create(logService: LogService,
    storageService: StorageService,
    audio: boolean,
    video: boolean,
    idealWidth?: number,
    idealHeight?: number,
    idealFrameRate?: number,
    backgorundBlur?: boolean,
    backgroundImage?: Base64FileModel): Promise<LocalUserStream> {

    const backgroundBlusSetting = storageService.getItem(CAMERA_BACKGORUND_BLUR_SETTING);
    backgorundBlur = backgroundBlusSetting ? backgroundBlusSetting.toLowerCase() == 'true' : backgorundBlur;
    backgorundBlur = backgorundBlur && (backgroundImage != null);

    // video
    const videoTrackConstraints: MediaTrackConstraints = {};
    let saveSettings = JSON.parse(storageService.getItem(VIDEOSETTINGS)) as MediaTrackSettings;

    videoTrackConstraints.facingMode = { ideal: 'user' };
    if (idealWidth) {
      videoTrackConstraints.width = { ideal: idealWidth };
    }
    if (idealHeight) {
      videoTrackConstraints.height = { ideal: idealHeight };
    }
    if (idealFrameRate) {
      videoTrackConstraints.frameRate = { ideal: idealFrameRate };
    }
    if (saveSettings) {
      if (saveSettings.deviceId != null && saveSettings.deviceId != '') {
        videoTrackConstraints.deviceId = { ideal: saveSettings.deviceId.toLocaleString() };
        videoTrackConstraints.facingMode = null;
      }
    }

    // audio
    const audioTrackConstraints: MediaTrackConstraints = {};
    saveSettings = JSON.parse(storageService.getItem(AUDIOSETTINGS)) as MediaTrackSettings;

    if (saveSettings) {
      if (saveSettings.deviceId != null && saveSettings.deviceId != '') {
        audioTrackConstraints.deviceId = { ideal: saveSettings.deviceId.toLocaleString() };
      }
      audioTrackConstraints.autoGainControl = saveSettings.autoGainControl ?? false;
      audioTrackConstraints.echoCancellation = saveSettings.echoCancellation ?? true;
      audioTrackConstraints.noiseSuppression = saveSettings.noiseSuppression ?? false;
    }

    const { stream, message } = await this.getUserMediaWithMessage({
      audio: audio ? (audioTrackConstraints != {} ? audioTrackConstraints : true) : false,
      video: video ? videoTrackConstraints : false
    }, logService);

    return new LocalUserStream(stream, message, videoTrackConstraints, logService, backgorundBlur, backgroundImage);
  }

  private _id: string;
  private _stream: MediaStream;
  private _originalstream: MediaStream;
  private _virtualStream: MediaStream;
  private _message: string | null;
  private logService: LogService;
  public backgorundBlur: boolean;
  private backgroundImage: Base64FileModel;
  private videoElement: HTMLVideoElement;
  private canvasElement: HTMLCanvasElement;
  private backGroundImageElement: any;
  private canvasCtx: CanvasRenderingContext2D;
  private selfieSegmentation: SelfieSegmentation;
  private animationFrameHandle: number;
  //private camera: Camera;

  private constructor(stream: MediaStream, message: string | null, private videoTrackConstraints: MediaTrackConstraints, logService: LogService, backgorundBlur: boolean, backgroundImage: Base64FileModel) {
    this._id = uuid();
    this._originalstream = stream;
    this._stream = this.createVirtualBackground(stream, backgorundBlur, backgroundImage);
    this._message = message
    this.logService = logService;
    this.backgorundBlur = backgorundBlur;
    this.backgroundImage = backgroundImage;
    this.audioTrack = new BehaviorSubject<MediaStreamTrack>(getAudioTrackFromStream(this._stream));
    this.videoTrack = new BehaviorSubject<MediaStreamTrack>(getVideoTrackFromStream(this._stream));
    this.originalVideoTrack = new BehaviorSubject<MediaStreamTrack>(getVideoTrackFromStream(this._originalstream));
  }

  destroy(): void {
    if (this.videoElement) {
      document.body.removeChild(this.videoElement);
      this.videoElement = null;
    }

    if (this.canvasElement) {
      document.body.removeChild(this.canvasElement);
      this.canvasElement = null;
    }

    if (this.canvasCtx) {
      this.canvasCtx = null;
    }
  }

  get id() {
    return this._id;
  }

  get type() {
    return StreamSourceType.Camera;
  }

  get stream() {
    return this._stream;
  }

  get originalStream() {
    return this._originalstream;
  }

  get message() {
    return this._message;
  }

  /** Gibt an ob der Ton eingeschaltet ist. */
  get audio() {
    const track = getAudioTrackFromStream(this._stream);
    return track ? track.enabled : false;
  }

  audioTrack: BehaviorSubject<MediaStreamTrack>;

  tryingToSetAudio = false;

  async trySetAudio(value: boolean, storageService: StorageService) {

    if (this.tryingToSetAudio) {
      return false;
    }

    try {
      this.tryingToSetAudio = true;
      return await this.setAudioInternal(value, storageService);
    } finally {
      this.tryingToSetAudio = false;
    }
  }

  private async setAudioInternal(enabled: boolean, storageService: StorageService) {

    // Wir holen uns den aktuellen Audio Track.
    const localTrack = getAudioTrackFromStream(this._stream);

    if (localTrack) {

      // Existiert dieser, können wir diesen Audio Track einfach ein- oder ausschalten.
      localTrack.enabled = enabled;
      if (!enabled) {
        localTrack.stop();
        this._stream.removeTrack(localTrack);
      }
      this._message = null;

      return true;
    } else {
      if (enabled) {
        const audioTrackConstraints: MediaTrackConstraints = {};
        const saveSettings = JSON.parse(storageService.getItem(AUDIOSETTINGS)) as MediaTrackSettings;

        if (saveSettings) {
          if (saveSettings.deviceId != null && saveSettings.deviceId != '') {
            audioTrackConstraints.deviceId = { ideal: saveSettings.deviceId.toLocaleString() };
          }
          audioTrackConstraints.autoGainControl = saveSettings.autoGainControl ?? false;
          audioTrackConstraints.echoCancellation = saveSettings.echoCancellation ?? true;
          audioTrackConstraints.noiseSuppression = saveSettings.noiseSuppression ?? false;
        }

        // Wenn der Audio Track nicht existiert, müssen wir den Zugriff anfordern.
        const { stream, message } = await LocalUserStream.getUserMediaWithMessage({ audio: (audioTrackConstraints != {} ? audioTrackConstraints : true) }, this.logService);
        this._message = message;

        // Wir bekommen einen Stream und holen uns daher den Audio Track aus dem Stream.
        const track = getAudioTrackFromStream(stream);

        if (track) {

          // Wenn wir einen Audio Track haben, können wir diesen unserem Stream hinzufügen.
          this._stream.addTrack(track);

          // Den neuen Audio Track nach außen geben, damit dieser der Peer Connection hinzugefügt werden kann.
          console.log("localUserStream audioTrack.next " + this.id, track);
          this.audioTrack.next(track);

          return true;
        } else {
          console.log("localUserStream audioTrack.next null " + this.id);
          this.audioTrack.next(null);
        }
      }
    }

    return false;
  }

  /** Gibt an ob das Bild eingeschaltet ist. */
  get video() {
    const track = getVideoTrackFromStream(this._stream);
    return track ? track.enabled : false;
  }

  videoTrack: BehaviorSubject<MediaStreamTrack>;
  originalVideoTrack: BehaviorSubject<MediaStreamTrack>;

  tryingToSetVideo = false;

  /** Setzt ob das lokale Video eingeschaltet ist. */
  async trySetVideo(value: boolean) {

    if (this.tryingToSetVideo) {
      return false;
    }

    try {
      this.tryingToSetVideo = true;
      return await this.setVideoInternal(value);
    } finally {
      this.tryingToSetVideo = false;
    }
  }

  private async setVideoInternal(enabled: boolean) {

    // Wir holen uns den aktuellen Video Track.
    const localTrack = getVideoTrackFromStream(this._originalstream);
    const localTrackBlur = getVideoTrackFromStream(this._stream);

    if (localTrack) {
      if (localTrackBlur) {
        localTrackBlur.enabled = enabled;
        if (!enabled) {
          localTrackBlur.stop();
          this._stream.removeTrack(localTrackBlur);
        }
      }
      // Existiert dieser, können wir diesen Video Track einfach ein- oder ausschalten.
      localTrack.enabled = enabled;
      if (!enabled) {
        localTrack.stop();
        this._originalstream.removeTrack(localTrack);
      }
      this._message = null;

      return true;
    } else {
      if (enabled) {

        // Wenn der Video Track nicht existiert, müssen wir den Zugriff anfordern.
        const { stream, message } = await LocalUserStream.getUserMediaWithMessage({ video: this.videoTrackConstraints }, this.logService);
        this._message = message;

        // Wir bekommen einen Stream und holen uns daher den Video Track aus dem Stream.
        const track = getVideoTrackFromStream(stream);

        if (track) {

          // Wenn wir einen Video Track haben, können wir diesen unserem Stream hinzufügen.
          this._originalstream.addTrack(track);

          // Den neuen Video Track nach außen geben, damit dieser der Peer Connection hinzugefügt werden kann.
          console.log("localUserStream videoTrack.next " + this.id, track);
          //this.videoTrack.next(track);
          this.setVirtualBackground(this.backgorundBlur);
          this.originalVideoTrack.next(track);

          return true;
        }
      } else {
        console.log("localUserStream videoTrack.next null " + this.id);
        this.videoTrack.next(null);
        this.originalVideoTrack.next(null);
      }
    }

    return false;
  }

  hasMultipleCameras = this.isEventTarget(navigator.mediaDevices)
    ? fromEvent(navigator.mediaDevices, 'devicechange').pipe(startWith(void (0)), switchMap(() => hasMultipleCameras()))
    : defer(() => hasMultipleCameras());

  tryingToSwitchCamera = false;

  /** Versucht auf eine andere Kamera zu wechseln. */
  async trySwitchCamera(storageService: StorageService) {

    if (this.tryingToSwitchCamera) {
      return;
    }

    try {
      this.tryingToSwitchCamera = true;
      await this.switchCameraInternal(storageService);
    } finally {
      this.tryingToSwitchCamera = false;
    }
  }

  private async switchCameraInternal(storageService: StorageService) {

    const currentVideoTrack = this.originalVideoTrack.value;
    const mediaTrackSettings = currentVideoTrack?.getSettings();
    let currentDeviceId = mediaTrackSettings?.deviceId || '';

    const cameras = (await navigator.mediaDevices.enumerateDevices()).filter(device => device.kind === 'videoinput');
    if (cameras.length < 2) {
      return;
    }

    let currentDeviceIndex = -1;
    if (currentDeviceId && currentDeviceId.length) {
      currentDeviceIndex = cameras.findIndex(device => device.deviceId === currentDeviceId);
    }
    if (currentDeviceIndex < 0) {
      currentDeviceIndex = 0;
      currentDeviceId = cameras[0].deviceId;
    }

    // Den aktuellen Track stoppen. Nötig auf Android, da sonst die Anfrage nach der nächsten Kamera verweigert wird.
    if (currentVideoTrack) {
      currentVideoTrack.stop();
      this._stream.removeTrack(currentVideoTrack);
    }

    let videoDeviceId = currentDeviceId;
    let couldSwitch = false;
    do {
      videoDeviceId = cameras[(currentDeviceIndex + 1) % cameras.length].deviceId;

      // Den neuen Stream holen
      this.videoTrackConstraints.deviceId = videoDeviceId;
      this.videoTrackConstraints.facingMode = null;
      const { stream, message } = await LocalUserStream.getUserMediaWithMessage({ video: this.videoTrackConstraints }, this.logService);
      this._message = message;

      if (stream) {
        const nextVideoTrack = getVideoTrackFromStream(stream);
        if (nextVideoTrack) {
          // Wenn wir einen Video Track haben, können wir diesen unserem Stream hinzufügen.
          this._stream.addTrack(nextVideoTrack);

          const constraints = nextVideoTrack.getConstraints();
          storageService.setItem(VIDEOSETTINGS, JSON.stringify(constraints));

          // Den neuen Video Track nach außen geben, damit dieser der Peer Connection hinzugefügt werden kann.
          console.log("localUserStream videoTrack.next " + this.id, nextVideoTrack);

          this.setVirtualBackground(this.backgorundBlur);
          this.originalVideoTrack.next(nextVideoTrack);
          couldSwitch = true;
        } else {
          console.log("localUserStream videoTrack.next null " + this.id);
          this.videoTrack.next(null);
          this.originalVideoTrack.next(null);
        }
      } else {
        console.log("localUserStream stream null " + this.id);
        this.videoTrack.next(null);
        this.originalVideoTrack.next(null);
      }

      currentDeviceIndex++;
    } while (!couldSwitch && videoDeviceId != currentDeviceId)
  }

  stop() {
    this.audioTrack.next(null);
    this.originalVideoTrack.next(null);
    this.videoTrack.next(null);
  }

  static stopAll(logService: LogService, stopTracks: boolean) {
    console.trace('localUserStream stopAll - stopTracks', stopTracks);
    this.allStreams.forEach(s => {
      this.stopStream(s, logService, stopTracks);
      s = null;
    });
    this.allStreams.clear();
  }

  static stopStream(stream: MediaStream, logService: LogService, stopTracks: boolean) {
    console.trace('localUserStream stopStream stream', stream);
    stream.getTracks().forEach(track => {
      if (stopTracks) {
        this.tryStopTrack(track, logService);
      }
      stream.removeTrack(track);
    });
    try {
      if ((stream as unknown as any)?.stop) { (stream as unknown as any).stop(); }
    }
    catch (error) {
      logService.logSync(LogLevel.ERROR, 'stop error', error, JSON.stringify(stream));
    }
  }

  static tryStopTrack(track: MediaStreamTrack, logService: LogService) {
    console.log('tryStopTrack track', track);
    if (track) {
      try {
        logService?.logSync(LogLevel.DEBUG, 'stopping track', track.id + ': ' + JSON.stringify(track));
        track.stop();
      }
      catch (error) {
        logService?.logSync(LogLevel.ERROR, 'error stopping track', error, track.id + ': ' + JSON.stringify(track));
      }
    }
  }

  setVirtualBackground(blur: boolean) {
    this.backgorundBlur = blur;
    if (this.backgorundBlur) {
      console.log('setVirtualBackground: this.createVirtualBackground', this.backgroundImage);
      this._stream = this.createVirtualBackground(this._originalstream, blur, this.backgroundImage);
    } else {
      console.log('setVirtualBackground: this._originalstream');
      this._virtualStream = null;
      this._stream = this._originalstream;
    }

    this.audioTrack.next(getAudioTrackFromStream(this._stream));
    this.videoTrack.next(getVideoTrackFromStream(this._stream));
  }

  createVirtualBackground(stream: MediaStream, backgorundBlur: boolean, backgroundImage: Base64FileModel): MediaStream {
    if (backgorundBlur) {

      const videoTrack = getVideoTrackFromStream(stream);
      if (videoTrack) {
        const settings = videoTrack.getSettings();

        if (this._virtualStream) {
          this._virtualStream = null;
        }

        if (this.animationFrameHandle) {
          window.cancelAnimationFrame(this.animationFrameHandle);
        }

        if (this.videoElement) {
          this.videoElement.onplaying = null;
          document.body.removeChild(this.videoElement);
          this.videoElement = null;
        }

        if (this.canvasElement) {
          document.body.removeChild(this.canvasElement);
          this.canvasElement = null;
          this.canvasCtx = null;
        }

        console.log('creating videoElement with stream', stream);
        this.videoElement = document.createElement('video');
        this.videoElement.width = settings.width;
        this.videoElement.height = settings.height;
        this.videoElement.srcObject = stream;
        this.videoElement.style.display = 'none';
        this.videoElement.muted = true;
        this.videoElement.autoplay = false;
        this.videoElement.playsInline = true;
        this.videoElement.disablePictureInPicture = true;
        document.body.appendChild(this.videoElement)


        // https://google.github.io/mediapipe/solutions/selfie_segmentation
        this.canvasElement = document.createElement('canvas') as HTMLCanvasElement;
        this.canvasElement.width = settings.width;
        this.canvasElement.height = settings.height;
        this.canvasElement.style.display = 'none';
        document.body.appendChild(this.canvasElement)

        if (!this.backGroundImageElement && backgroundImage) {
          this.backGroundImageElement = new Image();
          const url = 'data:' + this.getContentType(backgroundImage) + ';base64,' + backgroundImage.base64Data;
          this.backGroundImageElement.src = url;
        }

        this.canvasCtx = this.canvasElement.getContext('2d');
        const virtualStream = this.canvasElement.captureStream() as MediaStream;

        if (this.selfieSegmentation) {
          this.selfieSegmentation.close();
          this.selfieSegmentation = null;
        }

        if (!this.selfieSegmentation) {
          this.selfieSegmentation = new SelfieSegmentation({
            locateFile: (file) => {
              return `/assets/mediapipe/${file}`;
            }
          });
          this.selfieSegmentation.setOptions({
            modelSelection: 1,
          });
          this.selfieSegmentation.onResults((res) => { this.onResults(res); })
        }
        
        this.videoElement.onplaying = () => {
          console.log('videoElement.onplaying');
          this.playing();
        };
        this.videoElement.play();

        this._virtualStream = virtualStream;
        LocalUserStream.allStreams.set(virtualStream.id, virtualStream);
        return virtualStream;
      } else {
        return stream;
      }
    } else {
      return stream;
    }
  }

  async playing() {
    try {
      await this.selfieSegmentation.send({ image: this.videoElement });
    }
    catch (error) {
      //console.log('playing error', error);
      //console.log('playing error videoElement', this.videoElement);
    }

    this.animationFrameHandle = window.requestAnimationFrame(() => { this.playing(); });
  }

  onResults(results) {
    this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    // Draw the mask
    this.canvasCtx.drawImage(results.segmentationMask, 0, 0, this.canvasElement.width, this.canvasElement.height);

    // Fill green on everything but the mask
    this.canvasCtx.globalCompositeOperation = 'source-out';

    if (this.backGroundImageElement) {
      this.canvasCtx.drawImage(this.backGroundImageElement, 0, 0, this.canvasElement.width, this.canvasElement.height);
    } else {
      this.canvasCtx.fillStyle = '#00FF00';
      this.canvasCtx.fillRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    }

    // Add the original video back in (in image) , but only overwrite missing pixels.
    this.canvasCtx.globalCompositeOperation = 'destination-atop';
    this.canvasCtx.drawImage(results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);

    //this.canvasCtx.save();
    //this.canvasCtx.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    //this.canvasCtx.drawImage(results.segmentationMask, 0, 0,
    //  this.canvasElement.width, this.canvasElement.height);

    //// Only overwrite existing pixels.
    //this.canvasCtx.globalCompositeOperation = 'source-in';
    //this.canvasCtx.fillStyle = '#00FF00';
    //this.canvasCtx.fillRect(0, 0, this.canvasElement.width, this.canvasElement.height);

    //// Only overwrite missing pixels.
    //this.canvasCtx.globalCompositeOperation = 'destination-atop';
    //this.canvasCtx.drawImage(
    //  results.image, 0, 0, this.canvasElement.width, this.canvasElement.height);

    //this.canvasCtx.restore();
  }

  getContentType(model: Base64FileModel) {
    if (model.filename.indexOf('.svg') > -1) {
      return 'image/svg+xml'
    }
    if (model.filename.indexOf('.png') > -1) {
      return 'image/png'
    }
    if (model.filename.indexOf('.gif') > -1) {
      return 'image/gif'
    }
    if (model.filename.indexOf('.jpg') > -1) {
      return 'image/jpg'
    }
    if (model.filename.indexOf('.jpeg') > -1) {
      return 'image/jpeg'
    }
    if (model.filename.indexOf('.tif') > -1) {
      return 'image/tif'
    }
  }
}
