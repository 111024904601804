import { SignalRService } from "./signalr.service";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

export interface ConferenceConfiguration {
  maxBandwidthSteps: number[];
  idealWidth?: number;
  idealHeight?: number;
  idealFrameRate?: number;
  useKurento?: boolean;
}

export enum StreamSourceType {
  Camera = 0,
  Screen = 1
}

export interface ConferenceStreamData {
  clientId: string;
  teilnehmerId: number;
  benutzerToken: string;
  type: StreamSourceType;
  audio: boolean;
  video: boolean;
  userName: string;
  isOrganizer: boolean;
  isAnonymousForOthers: boolean;
  visibleForTeilnehmer: boolean;
  canActivateAudio: boolean;
  canActivateVideo: boolean;
  canStartScreensharing: boolean;
  isManager: boolean;
}

export interface ConferenceData {
  id: number;
  useKurento: boolean;
  iceServers: RTCIceServer[];
  streams: { [id: string]: ConferenceStreamData; };
}


export class ConferenceUpdateItem {
  BenutzerRequestToken: string;
  BenutzerToken: string;
  ClientId: string;
  ConferenceToken: string;
  ActivateAudio: boolean;
  DeactivateAudio: boolean;
  ActivateVideo: boolean;
  DeactivateVideo: boolean;
}

@Injectable()
export class ConferenceHub {

  constructor(private signalRService: SignalRService) {

  }

  private async invoke<T = void>(methodName: string, ...args: any[]): Promise<T> {
    try {
      return await this.signalRService.invoke('conference', methodName, ...args)
    }
    catch (ex) {
      console.log(ex.message, "Params methodName: " + methodName, "args: ", args);
      throw ex;
    }
  }

  private observe<T extends any[]>(eventName: string) {
    return this.signalRService.observe<T>('conference', eventName);
  }

  getConfiguration() {
    // console.trace('ConferenceHub getConfiguration');
    return this.invoke<ConferenceConfiguration>('GetConfiguration');
  }

  join(token: string, benutzerToken: string, prevConnectionId: string | null) {
    return this.invoke<boolean>('Join', token, benutzerToken, prevConnectionId, this.signalRService.connectionId);
  }

  leave(token: string) {
    return this.invoke('Leave', token);
  }

  leaveWithToken(token: string, benutzerToken: string) {
    return this.invoke('KickTeilnehmer', token, benutzerToken);
  }

  publish(token: string, benutzerToken: string, clientId: string, streamId: string, type: StreamSourceType, audio: boolean, video: boolean) {
    return this.invoke('Publish', token, benutzerToken, clientId, streamId, type, audio, video);
  }

  setAudio(token: string, streamId: string, benutzertoken: string, audio: boolean, managerToken:string) {
    return this.invoke('SetAudio', token, streamId, benutzertoken, audio, managerToken);
  }

  OtherUserConferenceRequest(conferenceUpdateItem: ConferenceUpdateItem) {   
    return this.invoke('OtherUserConferenceRequest', conferenceUpdateItem);
  }

  setVideo(token: string, streamId: string, benutzertoken: string, video: boolean, managerToken:string) {
    return this.invoke('SetVideo', token, streamId, benutzertoken, video, managerToken);
  }

  unpublish(token: string, streamId: string) {
    return this.invoke('Unpublish', token, streamId);
  }

  sendOfferToStreamer(token: string, streamId: string, connectionId: string, sdpOffer: string) {
    return this.invoke('SendOfferToStreamer', token, streamId, connectionId, sdpOffer);
  }

  sendAnswerToStreamer(token: string, streamId: string, connectionId: string, sdpAnswer: string) {
    return this.invoke('SendAnswerToConsumer', token, streamId, connectionId, sdpAnswer);
  }

  sendIceCandidateToStreamer(token: string, streamId: string, connectionId: string, iceCandidate: RTCIceCandidateInit) {
    return this.invoke('SendIceCandidateToStreamer', token, streamId, connectionId, iceCandidate);
  }

  joinStreamGroup(token: string, streamId: string, connectionId: string) {
    return this.invoke('JoinStreamGroup', token, streamId, connectionId);
  }

  sendOfferToStreamGroup(token: string, streamId: string, connectionId: string, sdpOffer: string) {
    return this.invoke('SendOfferToStreamGroup', token, streamId, connectionId, sdpOffer);
  }

  sendAnswerToStreamGroup(token: string, streamId: string, connectionId: string, sdpAnswer: string) {
    return this.invoke('SendAnswerToStreamGroup', token, streamId, connectionId, sdpAnswer);
  }

  sendIceCandidateToStreamGroup(token: string, streamId: string, connectionId: string, iceCandidate: RTCIceCandidateInit) {
    return this.invoke('SendIceCandidateToStreamGroup', token, streamId, connectionId, iceCandidate);
  }

  joinWaitingRoom(token: string) {
    return this.invoke('JoinWaitingRoom', token);
  }

  conferenceStarten(token: string, benutzerToken: string, audio: boolean, video: boolean, connectionId: string) {
    return this.invoke("ConferenceStarten", token, benutzerToken, audio, video, connectionId);
  }

  conferenceBeitreten(token: string, benutzerToken: string, audio: boolean, video: boolean) {
    return this.invoke("ConferenceBeitreten", token, benutzerToken, audio, video);
  }

  sendOfferToConsumer(token: string, streamId: string, connectionId: string, sdpOffer: string) {
    return this.invoke('SendOfferToConsumer', token, streamId, connectionId, sdpOffer);
  }

  sendAnswerToConsumer(token: string, streamId: string, connectionId: string, sdpAnswer: string) {
    return this.invoke('SendAnswerToConsumer', token, streamId, connectionId, sdpAnswer);
  }

  sendIceCandidateToConsumer(token: string, streamId: string, connectionId: string, iceCandidate: RTCIceCandidateInit) {
    return this.invoke('SendIceCandidateToConsumer', token, streamId, connectionId, iceCandidate);
  }

  onPush = this.observe<[string, ConferenceData]>('Push');

  // todo: implement.
  onPushConferenceSettings = this
  .observe<[ConferenceUpdateItem]>('OnPushConferenceSettings')
  .pipe(map(a => a[0]));


  onSendOfferToStreamer = this.observe<[string, string, string]>('SendOfferToStreamer');

  onSendAnswerToStreamer = this.observe<[string, string, string]>('SendAnswerToStreamer')

  onSendIceCandidateToStreamer = this.observe<[string, string, RTCIceCandidateInit]>('SendIceCandidateToStreamer');

  onSendOfferToStreamGroup = this.observe<[string, string, string]>('SendOfferToStreamGroup');

  onSendAnswerToStreamGroup = this.observe<[string, string, string]>('SendAnswerToStreamGroup')

  onSendIceCandidateToStreamGroup = this.observe<[string, string, RTCIceCandidateInit]>('SendIceCandidateToStreamGroup');

  onSendOfferToConsumer = this.observe<[string, string, string]>('SendOfferToConsumer');

  onSendAnswerToConsumer = this.observe<[string, string, string]>('SendAnswerToConsumer');

  onSendIceCandidateToConsumer = this.observe<[string, string, RTCIceCandidateInit]>('SendIceCandidateToConsumer');

  onInformConferenceStarted = this.observe<[string]>('InformConferenceStarted');

  onConferenceLeave = this.observe('OnConferenceLeave');

}
