import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FrageGesprachEndeModel} from '../generated/types';
import {GlobalFunctions} from './global-functions';

@Component({
    selector: 'shared-bewertung',
    templateUrl: './bewertung.component.html',
    styleUrls: ['./bewertung.component.css']
})
export class BewertungComponent implements OnInit {
    @Input() form: FormGroup;
    @Input() fragen: { [id: string]: FrageGesprachEndeModel };
    @Input() stars = 5;
    @Input() headline: string;
    @Input() containerCssClass = '';
    @Input() showTextarea = false;

    public debugJsonOnForm = GlobalFunctions.debugJsonOnForm;
    public displayHtmlComponent = GlobalFunctions.displayHtmlComponent;
    public selectValues = [];

    ngOnInit(): void {
        // dictionary container mit FrageId keys erstellen, indem die SelectedValues abgelegt werden.
        Object.keys(this.fragen).forEach((id) => {
            this.selectValues.push({
                key: id,
                value: 0
            });
        });
    }
}
