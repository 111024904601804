import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { EinladungRequestContainer, EinladungItem, EinladungContainer, EinladungTeilnehmerContainer } from "./types";
import { HttpRequest, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";

@Injectable()
export class EinladungHttpService {

  constructor(private httpService : HttpService) { }

  post(request: EinladungRequestContainer) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/einladung`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as string } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getById(id: number) { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/einladung/detail/${id}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as EinladungItem } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  move(id: number, request: EinladungRequestContainer) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/einladung/detail/move/${id}`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  put(id: number) { 
    return this.httpService
      .requestWithBody({ method: 'PUT', url: URI`/api/einladung/${id}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  delete(id: number) { 
    return this.httpService
      .request({ method: 'DELETE', url: URI`/api/einladung/${id}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getUpcoming(from: string) { 
    const params : any = {};
    if(from != null) { params['from'] = from; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/einladung/upcoming`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as { [key: string]: EinladungItem[] } } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  get(token: string) { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/einladung/getByToken/${token}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as EinladungContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getAgenten(anrufgruppeId: number) { 
    const params : any = {};
    if(anrufgruppeId != null) { params['anrufgruppeId'] = anrufgruppeId; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/einladung/agenten`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as EinladungTeilnehmerContainer[] } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
