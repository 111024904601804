

<div *ngFor="let styleForm of styles.controls; let i = index" class="row">
  <ng-container [ngSwitch]="styleForm.value.typ">
    <ng-container *ngSwitchCase="0">
      <strong *ngIf="!styles.value[i-1] || styles.value[i-1].typ != 0">Button</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()" label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'background-color'" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
        <shared-colorpicker-input (change)="hasChanged()" label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'color'" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>

        <!--<input type="text" *ngIf="styleForm.value.name == 'text-decoration'" [id]="'value_' + i" [formControl]="styleForm.controls['value']" />-->
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="1">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 1">Button (hover)</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'background-color'" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'color'" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
        <!--<input type="text" *ngIf="styleForm.value.name == 'text-decoration'" [id]="'value_' + i" [formControl]="styleForm.controls['value']" />-->
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="2">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 2">Link</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'color'" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
        <shared-dropdown-input label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'text-decoration'" [id]="'value_' + i" [items]="textDecorationOptions" [control]="styleForm.controls['value']"></shared-dropdown-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="3">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 3">Link (hover)</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'color'" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
        <shared-dropdown-input label="{{styleForm.controls['name'].value}}" *ngIf="styleForm.value.name == 'text-decoration'" [id]="'value_' + i" [items]="textDecorationOptions" [control]="styleForm.controls['value']"></shared-dropdown-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="4">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 4">Chatbubble (eigene)</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="5">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 5">Chatbubble (Gesprächspartner)</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="6">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 6">Menü-Fähnchen</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="7">
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" withHoverLabel="true"  [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="8">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 8">Logo auf der Anrufen-Seite</strong>
      <div class="row" *ngIf="styleForm.value.name == 'max-width'">
        <label>{{styleForm.controls['name'].value}}</label>
        <input type="text" [id]="'value_' + i" [formControl]="styleForm.controls['value']" />
      </div>
      <div class="row" *ngIf="styleForm.value.name == 'max-height'">
        <label>{{styleForm.controls['name'].value}}</label>
        <input type="text" [id]="'value_' + i" [formControl]="styleForm.controls['value']" />
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="9">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 9">Chatbubble Flüstern (eigene)</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="10">
      <strong *ngIf="styles.value[i-1] && styles.value[i-1].typ != 10">Chatbubble Flüstern (Gesprächspartner)</strong>
      <div class="row">
        <shared-colorpicker-input (change)="hasChanged()"  label="{{styleForm.controls['name'].value}}" [id]="'value_' + i" [control]="styleForm.controls['value']"></shared-colorpicker-input>
      </div>
    </ng-container>
  </ng-container>
</div>



