import { Component, Input, OnInit } from "@angular/core";
import { TypedFormControl } from "../generated/forms";
import { Base64FileModel } from "../generated/types";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";
import { importExpr } from "@angular/compiler/src/output/output_ast";



@Component({
  selector: 'shared-image',
  templateUrl: './image.component.html'
})
export class ImageComponent implements OnInit {
    
  @Input() id: string;
  @Input() model: Base64FileModel;
  @Input() width: number;
  @Input() height: number;


  src: string | SafeUrl;

  constructor(private sanitizer: DomSanitizer) {
    
  }

  ngOnInit(): void {
    const url = 'data:' + this.getContentType() + ';base64,' + this.model.base64Data;
    this.src = this.sanitizer.bypassSecurityTrustUrl(url);
  }


  getContentType() {    
    if (this.model.filename.indexOf('.svg') > -1) {
      return 'image/svg+xml'
    }
    if (this.model.filename.indexOf('.png') > -1) {
      return 'image/png'
    }
    if (this.model.filename.indexOf('.gif') > -1) {
      return 'image/gif'
    }
    if (this.model.filename.indexOf('.jpg') > -1) {
      return 'image/jpg'
    }
    if (this.model.filename.indexOf('.jpeg') > -1) {
      return 'image/jpeg'
    }
    if (this.model.filename.indexOf('.tif') > -1) {
      return 'image/tif'
    }
  }


}