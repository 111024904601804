
<div *ngIf='invalidDateFormat'>Datum {{control.value}} ist im falschen Format (kein UTC).</div>
<div [attr.class]="wrapperCssClass">
  <label *ngIf="label != ''" [for]="id + '_input'">{{label}}</label>
  <p-calendar [id]="id + '_input'"
              [disabled]="control.disabled"
              [disabledDays]="disabledDays"
              [readonlyInput]="readonlyInput"
              [showIcon]="true"
              [showButtonBar]="!timeOnly"
              [dateFormat]="de.dateFormat"
              [(ngModel)]="dateValue"
              [monthNavigator]="true"
              [yearNavigator]="true"
              [yearRange]="yearRange"
              [view]="view"
              [showTime]="showTime"
              [timeOnly]="timeOnly"
              (onClearClick)="removeValue()"
              [minDate]="getMinDate()"
              [maxDate]="getMaxDate()"
              [attr.required]="required ? true : null"
              (onBlur)="setTouched($event)"
              (onClose)="setTouched($event)"
              (ngModelChange)="changeDate()"
              [styleClass]="cssClass != '' ? cssClass : null"
              showWeek="true"></p-calendar>
  <!--<app-error-output [control]="control"></app-error-output>-->
</div>