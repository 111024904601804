import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { Observable, Subscription } from "rxjs";
import {GespraechContainer, TeilnehmerContainer} from '../generated/types';
import { ConferenceData, ConferenceHub, ConferenceStreamData, ConferenceUpdateItem } from "../services/conference.hub";
import { StorageService } from "../services/storage.service";
import { TeilnehmerService } from "../services/teilnehmer.service";
import { ConferenceDatas } from "../webrtc/conference2.component";
import { LocalUserStream } from "../webrtc/LocalUserStream";
import { BaseComponent } from "./base.component";


@Component({
  selector: 'conference-members',
  templateUrl: './conference-members.component.html'
})

export class ConferenceMembersComponent extends BaseComponent  {


  private subscriptionConfSettings = new Subscription();
  private conferenceUpdateItem: ConferenceUpdateItem
  private benutzerToken: string;

  data: ConferenceData | null = null;
  mydata: ConferenceStreamData | null = null;
  clientId: any;
  oldCount: number = 0;

  @Input() forTeilnehmer: boolean = false;
  @Input() isConference: boolean = false;
  @Output() teilnehmerCountChange: EventEmitter<number> = new EventEmitter<number>();
 

  @ViewChild("in") in: ElementRef;
  @ViewChild("out") out: ElementRef;
  @Input() token: string;
  @Input() gespraech: GespraechContainer;
  
  // wird momentan nicht benutzt.
  public teilnehmerInStream = 0;

  private _localUserStream: LocalUserStream | null;
  @Input() set localUserStream(value: LocalUserStream | null) {
    this._localUserStream = value;
  }
  get localUserStream(): LocalUserStream | null {
    return this._localUserStream;
  }

  constructor(
    private teilnehmerService: TeilnehmerService,
    private storageService: StorageService,
    private conferenceHub: ConferenceHub,
    private confirmationService: ConfirmationService
  ) {
    super();
  }

   async ngOnInit() {

    this.subscription.add(this.conferenceHub.onPush.subscribe(async ([token, data]) => {  
      if (token === this.token) { // Konferenztoken
        // this.data = data;
        if(this.gespraech) {
          // if (this.gespraech.typ === 0) { // nur für Konferenzen enfernt, funktionalität für alle Gesprächstypen.
            // das hier wird letztendlich nur verwendet, wenn wir kein Stream haben, ganz am Anfang beim laden der Seite
            // und möglicherweise beim Textchat ?!
            this.gespraech.teilnehmer = await this.teilnehmerService.getTeilnehmerWithToken(this.gespraech.token);
        // }
        }
      }
    }));

    this.benutzerToken = await this.teilnehmerService.getBenutzerToken(); 
    
    // subscribe auf Anfrage vom Server zum ändern der Einstellungen hinzufügen.
    this.subscriptionConfSettings.add(this.conferenceHub.onPushConferenceSettings.subscribe((data) => {
      this.updateConferenceSettings(data);
    }));
  }

   private async updateConferenceSettings(conferenceUpdateItem: ConferenceUpdateItem){ //
    this.conferenceUpdateItem = conferenceUpdateItem;
    if (conferenceUpdateItem.BenutzerToken == this.benutzerToken){

      if ((conferenceUpdateItem.ActivateAudio) || (conferenceUpdateItem.DeactivateAudio)){      
        const activate = conferenceUpdateItem.ActivateAudio? true: false;   
        if (activate) {
          this.confirmationService.confirm({
            message: "Anfrage Mikrofon einschalten Bestätigen.",
            accept: async () => {
              this.changeAudio(activate,conferenceUpdateItem.BenutzerRequestToken);
            }
          });
        } else {
          this.changeAudio(activate,conferenceUpdateItem.BenutzerRequestToken);
        }
      } else if ((conferenceUpdateItem.ActivateVideo) || (conferenceUpdateItem.DeactivateVideo)) {    
        const activate = conferenceUpdateItem.ActivateVideo? true: false;

        if (activate) {
          this.confirmationService.confirm({
            message: "Anfrage Kamera einschalten bestätigen.",
            accept: async () => {
              this.changeVideo(activate,conferenceUpdateItem.BenutzerRequestToken);
            }
          });
        } else {
          this.changeVideo(activate,conferenceUpdateItem.BenutzerRequestToken);
        }
      } 
    }
  }

  public setData(data: ConferenceDatas) {
    // console.log('conference-members setData', data);
    this.data = data.data;
    this.clientId = data.clientId
    this.setCount();
    this.setMyData();
  }

  private async changeAudio(activate: boolean, benutzerRequestToken:string){
    const success = await this.localUserStream.trySetAudio(activate, this.storageService);
    if (success) {
      const benutzerToken = await this.teilnehmerService.getBenutzerToken(); 
      await this.conferenceHub.setAudio(this.token, this.localUserStream.id, benutzerToken, activate, benutzerRequestToken);
    }
  }

  
  private async changeVideo(activate: boolean, benutzerRequestToken:string){

    const success = await this.localUserStream.trySetVideo(activate);
    if (success) {
      const benutzerToken = await this.teilnehmerService.getBenutzerToken(); 
      await this.conferenceHub.setVideo(this.token, this.localUserStream.id, benutzerToken, activate, benutzerRequestToken);
    }
  }

  setCount() {
    let newCount = Object.entries(this.data.streams).length;
    this.teilnehmerInStream = newCount;
    this.playSoundWithUserInteraction(newCount - this.oldCount);
    this.oldCount = newCount;
    // auf das aktualisieren der Liste reagieren.
    this.teilnehmerCountChange.emit(newCount);
  }

  setMyData() {

    // in dem Objekt this.data.streams sollten sich eigentlich alle Teilnehmer befinden
    let ret = Object.entries(this.data.streams).find(s => s[1].clientId == this.clientId);
    this.mydata = ret ? ret[1] : null;
  }



  showMember(stream: ConferenceStreamData) {
    // todo: überrüfen und ggf. fixen wenn ich mit 2 Instanzen im Gespräch bin, dann sollte ich hier auch nicht angezeigt werden.
    return stream.clientId !== this.clientId && (this.forTeilnehmer
      ? (this.mydata?.isOrganizer || this.mydata?.isManager ? true : stream.visibleForTeilnehmer)
      : true);
  }


  // Anfrage wird an den Server zum ändern der Einstellugen schicken
  public async otherUserAudioRequest(conferenceStreamData: ConferenceStreamData) {

    const conferenceUpdateItem = await this.createConferenceUpdateItem(conferenceStreamData);
    if (conferenceStreamData.audio){
      conferenceUpdateItem.DeactivateAudio = true
    } else {
      conferenceUpdateItem.ActivateAudio = true
    }
    await this.conferenceHub.OtherUserConferenceRequest(conferenceUpdateItem);

  }


  public async otherUserVideoRequest(conferenceStreamData: ConferenceStreamData) {
    const conferenceUpdateItem = await this.createConferenceUpdateItem(conferenceStreamData);
    conferenceStreamData.benutzerToken
    if (conferenceStreamData.video){
      conferenceUpdateItem.DeactivateVideo = true;
    } else {
      conferenceUpdateItem.ActivateVideo = true;
    }
    await this.conferenceHub.OtherUserConferenceRequest(conferenceUpdateItem);
  }

  private async createConferenceUpdateItem(conferenceStreamData: ConferenceStreamData) {
    const conferenceUpdateItem = new ConferenceUpdateItem();

    // das ist vermutlich falsch. Ich brauche Teilnehmer token
    conferenceUpdateItem.BenutzerRequestToken = await this.teilnehmerService.getBenutzerToken(); 

    conferenceUpdateItem.BenutzerToken = conferenceStreamData.benutzerToken
    conferenceUpdateItem.ConferenceToken = this.token
    return conferenceUpdateItem;
  }

  private async playSoundWithUserInteraction(change: number) {
    if (change > 0) { // Teilnehmer rein
      const element = this.in.nativeElement as HTMLAudioElement;
      if (element.paused) {
        element.currentTime = 0; // Der Ton soll immer von vorne beginnen
        try {
          await element.play();
        } catch (error) {
          console.log('error playing sound: ', error);
        }
      }
    }
    else if (change < 0) { // Teilnehmer raus
      const element = this.out.nativeElement as HTMLAudioElement;
      if (element.paused) {
        element.currentTime = 0; // Der Ton soll immer von vorne beginnen
        try {
          await element.play();
        } catch (error) {
          console.log('error playing sound: ', error);
        }
      }
    }
  }

  hasStreams(): boolean {
    let newCount = Object.entries(this.data.streams).length;
    // console.log("newCount:" + newCount);
    return newCount > 0;
  }

  kickTeilnehmer(tn: TeilnehmerContainer){
    this.confirmationService.confirm({
      header: "Teilnehmer rauswerfen",
      message: "Teilnehmer wirklich aus Besprechung entfernen?",
      accept: () => {
        if (this.gespraech?.token){
          this.conferenceHub.leaveWithToken(this.gespraech.token, tn.benutzerToken);
        } else {
          console.error('Fehler beim entfernen. Kein Gesprächstoken.');
        }
      }
    });
  }
}
