<ng-container *ngIf="controlToValidate.touched && controlToValidate.errors">
  <div *ngIf="controlToValidate.errors['required']">
    {{reqMessage}}
  </div>
  <div *ngIf="controlToValidate.errors['email']">Eine gültige E-Mail-Adresse wird erwartet.</div>
  <div *ngIf="controlToValidate.errors['match']">{{controlToValidate.errors['match']}}</div>
  <div *ngIf="controlToValidate.errors['server']">{{controlToValidate.errors['server']}}</div>
  <div *ngIf="controlToValidate.errors['custom']">{{controlToValidate.errors['custom']}}</div>
  <div *ngIf="controlToValidate.errors['url']">{{controlToValidate.errors['url']}}</div>
  <div *ngIf="controlToValidate.errors['matchOnGroup']">{{controlToValidate.errors['matchOnGroup']}}</div>
  <div *ngIf="controlToValidate.errors['pattern']">Der Name ist ungültig. Erlaubt ist A-Z und 0-9. Keine Leernzeilen oder Sonderzeichen.</div>
  <div *ngIf="controlToValidate.errors['greaterThan']">Muss größer sein als '{{controlToValidate.errors['greaterThan']}}'</div>
  <div *ngIf="controlToValidate.errors['lessThan']">Muss kleiner sein als '{{controlToValidate.errors['lessThan']}}'</div>
  <div *ngIf="controlToValidate.errors['futureGreaterThan']">Muss in der Zukunft liegen und größer sein als '{{controlToValidate.errors['futureGreaterThan']}}'</div>
  <div *ngIf="controlToValidate.errors['futureLessThan']">Muss in der Zukunft liegen und kleiner sein als '{{controlToValidate.errors['futureLessThan']}}'</div>
  <div *ngIf="controlToValidate.errors['future']">Muss in der Zukunft liegen.</div>
  <div *ngIf="controlToValidate.errors['url']">{{controlToValidate.errors['url']}}</div>
</ng-container>
