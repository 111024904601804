import { Component, Input } from "@angular/core";
import { FormControl } from "@angular/forms";

@Component({
  selector: 'shared-upload-input',
  templateUrl: './upload.component.html',
  host: {
    'class': 'flex-row margin-row'
  }
})
export class UploadComponent {
  @Input() id: string;
  @Input() control: FormControl;
  @Input() allowedFileTypes = "";
  @Input() maxSize: undefined | number;
}
