import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { ConfigurationContainer } from "./generated/types";
import { BaseComponent } from "./shared/base.component";

interface Data {
  configuration: ConfigurationContainer;
}

@Component({
  templateUrl: './forbidden.component.html'
})
export class ForbiddenComponent extends BaseComponent implements OnInit {

  public configuration: ConfigurationContainer;

  constructor(
    private route: ActivatedRoute,
    private router: Router) {
    super();
  }

  async ngOnInit() {
    this.subscription.add(this.route.data.subscribe(({ configuration }: Data) => {
      this.configuration = configuration;
    }));
  }

  backToLogin(event: Event) {
    event && event.preventDefault();
    this.router.navigate(['/']);
  }

}