import { Injectable } from '@angular/core';
import { HttpService } from '@oevermann/angular';
import { PrimeNGConfig, Translation } from 'primeng/api';
import { throttleTime } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private http: HttpService,
    private primeNGConfig: PrimeNGConfig

  ) {
    
   
    this.loadPrimeNg();
   }

  public loadPrimeNg(): void {

    this.primeNGConfig.setTranslation({
      dayNamesMin: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
      dayNames: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
      dayNamesShort: ["Son", "Mon", "Die", "Mit", "Don", "Fre", "Sam"],
      monthNames: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
      monthNamesShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"],
      today: 'Heute',
      clear: 'Leeren',
      weekHeader: 'KW',
      firstDayOfWeek: 1
    });
    // funktioniert auf derSeite nicht, ständiges neu laden der Seite, obwohl Ressourcen da sind.
    // this.http.get<Translation>("/assets/i18n/de-de.json", 'json').subscribe((json) => {
    //   console.log(json.body);
    //   // this.primeNGConfig.setTranslation(json.body);
    // });
  }
}
