import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from "@angular/router";
import { ConferenceHttpService } from "../../generated/conference-http.service";
import { ConferenceStartModel } from "../../generated/types";
import { NotificationService } from "../notification.service";

@Injectable()
export class ConferenceStartResolver implements Resolve<ConferenceStartModel> {

  constructor(private conferenceHttpService: ConferenceHttpService,
    private notificationService: NotificationService) { }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<ConferenceStartModel> {

    const einladungstoken = route.queryParams["einladungstoken"] as string;

    const result = await this.conferenceHttpService.getConferenceInfosByEinladungsToken(einladungstoken).toPromise();

    if (result.status === 200) {
      return result.data;
    }
    else if (result.status === 400) {
      this.notificationService.notifyError("Conference konnte nicht ermittelt werden");
    } 
  }
}