import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: 'shared-systeminfolink-output',
  templateUrl: './systeminfolink-output.component.html'
})
export class SysteminfoLinkOutputComponent {
  @Input() token: string;
  @Input() text: string;

  @Output() onAddSysteminfo: EventEmitter<string> = new EventEmitter();

  constructor(private router: Router) { }

  addSysteminfo(text: string) {
    if (text !== '') {
      this.onAddSysteminfo.next(text);
    }
  }

  collectSysteminfo(event: Event) {
    event && event.preventDefault();
    let sizes = this.getSizes();
    let cookie = this.hasCookies();
    //let quality = this.getConnectionQuality();
    this.VideoChatFeatureDetect.get(this, function (context, data) {
      let sysinfo = '{{strong*Systeminformationen:}}{{zeilenumbruch*1}}' +
        'Betriebssystem: ' + data.os + ' ' + data.osVersion + '{{zeilenumbruch*1}}' +
        'Browser: ' + data.browser + ' ' + data.version + '{{zeilenumbruch*1}}' +
        'WebRTC-Plugin: ' + (data.plugin ? 'ja' : 'nein') + '{{zeilenumbruch*1}}' +
        'Kamera: ' + (data.camera ? 'ja' : 'nein') + '{{zeilenumbruch*1}}' +
        'Mikrofon: ' + (data.microphone ? 'ja' : 'nein') + '{{zeilenumbruch*1}}' +
        'Lautsprecher: ' + (data.speakers ? 'ja' : 'nein') + '{{zeilenumbruch*1}}' +
        'Mobil: ' + (data.mobile ? 'ja' : 'nein') + '{{zeilenumbruch*1}}' +
        'Cookies: ' + (cookie ? 'ja' : 'nein') + '{{zeilenumbruch*1}}' +
        'Bildschirm: ' + sizes.sWidth + ' x ' + sizes.sHeight + ' px{{zeilenumbruch*1}}' +
        'Browserfenster: ' + sizes.wWidth + ' x ' + sizes.wHeight + ' px';
      context.addSysteminfo(sysinfo);
    });
  }

  getSizes() {
    let wWidth: number = 0;
    let wHeight: number  = 0;
    if (window.outerWidth) {
      wWidth = (window.innerWidth) ? window.innerWidth : -1;
      wHeight = (window.innerHeight) ? window.innerHeight : -1;
    }
    let sWidth: number = 0;
    let sHeight: number = 0;
    if (screen.width) {
      sWidth = (screen.width) ? screen.width : -1;
      sHeight = (screen.height) ? screen.height : -1;
    }
    return { wWidth: wWidth, wHeight: wHeight, sWidth: sWidth, sHeight: sHeight };
  }

  hasCookies() {
    let cookieEnabled = (navigator.cookieEnabled) ? true : false;
    if (typeof navigator.cookieEnabled === 'undefined' && !cookieEnabled) {
      document.cookie = 'testcookie';
      cookieEnabled = (document.cookie.indexOf('testcookie') > -1) ? true : false;
    }
    return cookieEnabled;
  }

  //getConnectionQuality(callbackUp: any, callbackDown: any) {
    //$('#bandWidthTest').wlBWt({
    //  BinPath: '/onbankingNET/BandWidthTest/bins',
    //  ServerPost: 'server.aspx',
    //  TestPing: false,
    //  TestDownload: true,
    //  TestUpload: true,
    //  AnimationDuration: 0,
    //  ShowComparison: false,
    //  ShowCost: false,
    //  SpeedMU: 'Mbps',
    //  CallbackUp: callbackUp,
    //  CallbackDown: callbackDown
    //});
  //}

  VideoChatFeatureDetect = {
    get: function (context: any, callback: Function, allowPlugin?: boolean) {
      allowPlugin = typeof allowPlugin === 'undefined' ? true : allowPlugin;
      if (!context.BrowserDetect.initialized) { context.BrowserDetect.init(); }
      let mobile = this.isMobile();
      let plugin = this.hasPlugin();

      this.hasMediaDevices(function (result) {
        callback(context, {
          os: context.BrowserDetect.get().os,
          osVersion: context.BrowserDetect.get().osVersion,
          browser: context.BrowserDetect.get().browser,
          version: context.BrowserDetect.get().version,
          mobile: mobile,
          plugin: plugin,
          camera: result.camera,
          microphone: result.microphone,
          speakers: result.speakers
        });
      });

    },
    hasPlugin: function () {
      let comName = 'Tem';
      let plugName = 'TemWebRTCPlugin';
      let pluginArray = navigator.plugins;
      for (var i = 0; i < pluginArray.length; i++) {
        if (pluginArray[i].name.indexOf(plugName) >= 0) {
          return true;
        }
      }
      return false;
    },
    isMobile: function () {
      return /Mobile|mini|Fennec|Android|iP(ad|od|hone)/.test(navigator.appVersion);
    },
    hasMediaDevices: function (callback) {
      let isWebcamAlreadyCaptured = false;
      let isMicrophoneAlreadyCaptured = false;
      let canEnumerate = false;
      let camera = false;
      let microphone = false;
      let speakers = false;
      let navEnumDev;

      if (navigator.mediaDevices && navigator.mediaDevices.enumerateDevices) {
        navEnumDev = function (callback) {
          navigator.mediaDevices.enumerateDevices().then(callback);
        };
      }

      if (!navEnumDev) {
        if (callback) {
          callback({ camera: camera, microphone: microphone, speakers: speakers });
        }
        return;
      }

      var MediaDevices: any[] = [];
      navEnumDev(function (devices) {
        devices.forEach(function (_device) {
          var device: any = {};
          for (var d in _device) {
            device[d] = _device[d];
          }

          if (device.kind === 'audio') {
            device.kind = 'audioinput';
          }
          if (device.kind === 'video') {
            device.kind = 'videoinput';
          }

          var skip: boolean;
          MediaDevices.forEach(function (d) {
            if (d.id === device.id && d.kind === device.kind) {
              skip = true;
            }
          });

          if (skip) {
            return;
          }

          if (!device.deviceId) {
            device.deviceId = device.id;
          }
          if (!device.id) {
            device.id = device.deviceId;
          }

          if (device.kind === 'videoinput' && !isWebcamAlreadyCaptured) {
            isWebcamAlreadyCaptured = true;
          }
          if (device.kind === 'audioinput' && !isMicrophoneAlreadyCaptured) {
            isMicrophoneAlreadyCaptured = true;
          }

          if (device.kind === 'audioinput') {
            microphone = true;
          }
          if (device.kind === 'audiooutput') {
            speakers = true;
          }
          if (device.kind === 'videoinput') {
            camera = true;
          }

          MediaDevices.push(device);
        });

        if (callback) {
          callback({ camera: camera, microphone: microphone, speakers: speakers });
        }
      });
    }
  };

  BrowserDetect = {
    initialized: false,
    init: function () {
      this.browser = this.searchString(this.dataBrowser) || "An unknown browser";
      this.version = this.searchVersion(navigator.userAgent) || this.searchVersion(navigator.appVersion) || "an unknown version";
      let tmp = this.getSystemInfo();
      this.os = tmp.os;
      this.osVersion = tmp.version;
      this.initialized = true;
    },
    searchString: function (e) {
      for (var t = 0; t < e.length; t++) {
        var n = e[t].string;
        var r = e[t].prop;
        this.versionSearchString = e[t].versionSearch || e[t].identity;
        if (n) {
          if (n.indexOf(e[t].subString) != -1) {
            return e[t].identity;
          }
        }
        else if (r) {
          return e[t].identity;
        }
      }
    },
    searchVersion: function (e) {
      var t = e.indexOf(this.versionSearchString);
      if (t == -1) {
        return;
      }
      let v = e.substring(t + this.versionSearchString.length + 1);
      return v.substring(0, v.indexOf(' '));
    },
    dataBrowser: [
      { string: navigator.userAgent, subString: "Edge", identity: "Edge" },
      { string: navigator.userAgent, subString: "OPR", identity: "Opera", versionSearch: "OPR" },
      { string: navigator.userAgent, subString: "Chrome", identity: "Chrome" },
      { string: navigator.userAgent, subString: "OmniWeb", versionSearch: "OmniWeb/", identity: "OmniWeb" },
      { string: navigator.vendor, subString: "Apple", identity: "Safari", versionSearch: "Version" },
      { string: navigator.vendor, subString: "iCab", identity: "iCab" },
      { string: navigator.vendor, subString: "KDE", identity: "Konqueror" },
      { string: navigator.userAgent, subString: "Firefox", identity: "Firefox" },
      { string: navigator.vendor, subString: "Camino", identity: "Camino" },
      { string: navigator.userAgent, subString: "Netscape", identity: "Netscape" },
      { string: navigator.userAgent, subString: "MSIE", identity: "Internet Explorer", versionSearch: "MSIE" },
      { string: navigator.userAgent, subString: "Gecko", identity: "Mozilla", versionSearch: "rv" },
      { string: navigator.userAgent, subString: "Mozilla", identity: "Netscape", versionSearch: "Mozilla" }
    ],
    getSystemInfo() {
      let os = 'unbekannt';
      let clientStrings = [
        { s: 'Windows 10', r: /(Windows 10.0|Windows NT 10.0)/ },
        { s: 'Windows 8.1', r: /(Windows 8.1|Windows NT 6.3)/ },
        { s: 'Windows 8', r: /(Windows 8|Windows NT 6.2)/ },
        { s: 'Windows 7', r: /(Windows 7|Windows NT 6.1)/ },
        { s: 'Windows Vista', r: /Windows NT 6.0/ },
        { s: 'Windows Server 2003', r: /Windows NT 5.2/ },
        { s: 'Windows XP', r: /(Windows NT 5.1|Windows XP)/ },
        { s: 'Windows 2000', r: /(Windows NT 5.0|Windows 2000)/ },
        { s: 'Windows ME', r: /(Win 9x 4.90|Windows ME)/ },
        { s: 'Windows 98', r: /(Windows 98|Win98)/ },
        { s: 'Windows 95', r: /(Windows 95|Win95|Windows_95)/ },
        { s: 'Windows NT 4.0', r: /(Windows NT 4.0|WinNT4.0|WinNT|Windows NT)/ },
        { s: 'Windows CE', r: /Windows CE/ },
        { s: 'Windows 3.11', r: /Win16/ },
        { s: 'Android', r: /Android/ },
        { s: 'Open BSD', r: /OpenBSD/ },
        { s: 'Sun OS', r: /SunOS/ },
        { s: 'Linux', r: /(Linux|X11)/ },
        { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
        { s: 'Mac OS X', r: /Mac OS X/ },
        { s: 'Mac OS', r: /(MacPPC|MacIntel|Mac_PowerPC|Macintosh)/ },
        { s: 'QNX', r: /QNX/ },
        { s: 'UNIX', r: /UNIX/ },
        { s: 'BeOS', r: /BeOS/ },
        { s: 'OS/2', r: /OS\/2/ },
        { s: 'Search Bot', r: /(nuhk|Googlebot|Yammybot|Openbot|Slurp|MSNBot|Ask Jeeves\/Teoma|ia_archiver)/ }
      ];
      for (let id in clientStrings) {
        let cs = clientStrings[id];
        if (cs.r.test(navigator.userAgent)) {
          os = cs.s;
          break;
        }
      }

      let version: RegExpExecArray | string = 'unbekannt';
      if (/Windows/.test(os)) {
        version = /Windows (.*)/.exec(os)[1];
        os = 'Windows';
      }
      switch (os) {
        case 'Mac OS X':
          version = /Mac OS X (10[\.\_\d]+)/.exec(navigator.userAgent)[1];
          break;
        case 'Android':
          version = /Android ([\.\_\d]+)/.exec(navigator.userAgent)[1];
          break;
        case 'iOS':
          version = /OS (\d+)_(\d+)_?(\d+)?/.exec(navigator.appVersion);
          version = version[1] + '.' + version[2] + '.' + ((version[3] as any) | 0);
          break;
      }

      return { os: os, version: version };
    },
    get: function () {
      return { browser: this.browser, version: this.version, os: this.os, osVersion: this.osVersion };
    }
  };

}