<ng-container *ngIf="displayHtmlComponent">{{displayHtmlComponentName()}}</ng-container>
<audio #in id="in" volume="1" playsinline>
  <source src="/assets/sounds/conference_member_in.mp3" type="audio/mp3" />
  <source src="/assets/sounds/conference_member_in.ogg" type="audio/ogg" />
</audio>
<audio #out id="out" volume="1" playsinline>
  <source src="/assets/sounds/conference_member_out.mp3" type="audio/mp3" />
  <source src="/assets/sounds/conference_member_out.ogg" type="audio/ogg" />
</audio>

<div class="nachrichtentemplates members">
  <ng-container *ngIf="data">

    <div class="memberListItem clearfix">


      <div class="name">Ich</div>
      <div class="settings">
        <div *ngIf="isConference && mydata?.isOrganizer" class="setting organizer" title="Organisator"></div>
        <div *ngIf="isConference && mydata?.isManager" class="setting organizer" title="Manager"></div>
        <div *ngIf="isConference && mydata && !mydata?.visibleForTeilnehmer" class="setting invisible" title="nicht sichtbar für andere Teilnehmer"></div>
        <div *ngIf="isConference && mydata?.visibleForTeilnehmer && mydata?.isAnonymousForOthers" class="setting anonymous" title="Anonym"></div>
        <div class="setting cam" [class.off]="!mydata?.video"></div>
        <div class="setting mic" [class.off]="!mydata?.audio"></div>
      </div>
    </div>
    <ng-container *ngIf="!hasStreams() && gespraech && gespraech.teilnehmer">
      <ng-container *ngFor="let tn of gespraech.teilnehmer">
        <div *ngIf="tn.benutzerToken != benutzerToken && tn.status !== 0" class="memberListItem clearfix">
          <div class="name">{{tn.name}}</div>
          <div class="setting kick" title="Aus Besprechung entfernen" style="cursor: pointer" (click)="kickTeilnehmer(tn)"> </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngFor="let stream of data.streams | keyvalue; trackBy: getKey">
      <div *ngIf="showMember(stream.value)" class="memberListItem clearfix">
        <div class="name">{{stream.value | teilnehmername:(forTeilnehmer && !mydata?.isOrganizer && !mydata?.isManager)}}</div>
        <div class="settings">
          <!-- isManager:{{mydata?.isManager}}<br>stream.value.isManager<br>{{stream.value.isManager}}<br> -->
          <!-- s.isOrg:{{stream.value.isOrganizer}}<br> -->
          <div *ngIf="!isConference || mydata?.isOrganizer || (mydata?.isManager && !(stream.value.isOrganizer))" class="setting kick" title="Aus Besprechung entfernen" style="cursor: pointer" (click)="kickTeilnehmer(stream.value)"> </div>
          <div *ngIf="isConference && stream.value.isOrganizer" class="setting organizer" title="Organisator"></div>
          <div *ngIf="isConference && stream.value.isManager" class="setting organizer" title="Manager"></div>
          <div *ngIf="isConference && !stream.value.visibleForTeilnehmer" class="setting invisible" title="nicht sichtbar für andere Teilnehmer"></div>
          <div *ngIf="isConference && stream.value.visibleForTeilnehmer && stream.value.isAnonymousForOthers" class="setting anonymous" title="Anonym"></div>
          <div *ngIf="isConference && (mydata?.isManager || mydata?.isOrganizer);then canChangeVideo else noChangeVideo"></div>
          <ng-template #canChangeVideo>
            <div class="setting cam" [class.off]="!stream.value.video"
                 [attr.title]="stream.value.video ? 'Kamera deaktivieren' : 'Kamera aktivieren'" (click)="otherUserVideoRequest(stream.value)"></div>
          </ng-template>
          <ng-template #noChangeVideo>
            <div class="setting cam" [class.off]="!stream.value.video"
                 [attr.title]="stream.value.video ? 'Kamera aktiv' : 'Kamera inaktiv'"></div>
          </ng-template>


          <div *ngIf="isConference && (mydata?.isManager || mydata?.isOrganizer); then canChangeAudio else noChangeAudio"></div>
          <ng-template #canChangeAudio>
            <div class="setting mic" [class.off]="!stream.value.audio"
                 [attr.title]="stream.value.audio ? 'Mikrofon deaktivieren' : 'Mikrofon aktivieren'" (click)="otherUserAudioRequest(stream.value)"></div>
          </ng-template>
          <ng-template #noChangeAudio>
            <div class="setting mic" [class.off]="!stream.value.audio"
                 [attr.title]="stream.value.audio ? 'Mikrofon aktiv' : 'Mikrofon inaktiv'"></div>
          </ng-template>

        </div>
      </div>
    </ng-container>
  </ng-container>
</div>
<p-confirmDialog #cd header="Kamera oder Mikrofon einschalten">
  <p-footer>
    <div class="buttonrow">
      <button type="button" class="actionbutton" (click)="cd.accept()">OK</button>
      <button type="button" class="actionbutton" (click)="cd.reject(true)">Abbrechen</button>
    </div>
  </p-footer>
</p-confirmDialog>
