import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { NotificationService, Notification } from './notification.service';
import { Observable ,  Subscription ,  interval } from 'rxjs';
import { merge } from 'rxjs/operators';

@Component({
  selector: 'shared-notification',
  templateUrl: './notification.component.html',
  host: {
    '[class.populated]': 'populated'
  }
})
export class NotificationComponent implements OnInit, OnDestroy {

  constructor(private notificationService: NotificationService) {

  }

  ngOnInit() {
    this.subscription = this.notificationService.notifications
      .pipe(
        merge(interval(2000))
      )
      .subscribe(this.onUpdate);
  }

  private onUpdate = (event: Notification | number) => {
    const filteredNotifications = this.notifications.filter(({ expires }) => expires > new Date());
    if (typeof event === 'number') {
      if (filteredNotifications.length !== this.notifications.length) {
        this.notifications = filteredNotifications;
        this.populated = this.notifications.length > 0;
      }
    } else {
      this.notifications = [event, ...filteredNotifications];
      this.populated = true;
    }
  }
   
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private subscription: Subscription;

  populated = false;

  notifications: Notification[] = [];
}
