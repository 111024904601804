import { Injectable } from "@angular/core";
import { TeilnehmerHttpService } from "../generated/teilnehmer-http.service";
import { BENUTZERTOKEN } from "../consts";

@Injectable()
export class TeilnehmerService {

  constructor(private teilnehmerHttpService: TeilnehmerHttpService) {

  }

  private benutzerTokenPromise: Promise<string> | null = null;

  getBenutzerToken() {
    return this.benutzerTokenPromise || (this.benutzerTokenPromise = this.getBenutzerTokenFromSessionStorageOrServer())
  }

  private async getBenutzerTokenFromSessionStorageOrServer() {

    let benutzerToken = sessionStorage.getItem(BENUTZERTOKEN);
    if (benutzerToken) {
      return benutzerToken;
    }

    const response = await this.teilnehmerHttpService.getToken().toPromise();
    benutzerToken = response.data;
    sessionStorage.setItem(BENUTZERTOKEN, benutzerToken);
    return benutzerToken;
  }

  async getVideoAudioSettings(gespraechsToken: string, benutzerToken: string) {
    const response = await this.teilnehmerHttpService.getVideoAudioSettings(gespraechsToken, benutzerToken).toPromise();
    if (response.status === 200) {
      return response.data;
    }
  }

  async getTeilnehmerWithToken(gespraechsToken: string){
    const response = await this.teilnehmerHttpService.getTeilnehmerWithToken(gespraechsToken).toPromise();
    if (response.status === 200) {
      return response.data;
    }
  }
}