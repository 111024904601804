import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { PageOfDokumentItem, DokumentContainer } from "./types";
import { HttpParams, HttpRequest, HttpHeaders, HttpErrorResponse } from "@angular/common/http";

@Injectable()
export class DokumentHttpService {

  constructor(private httpService : HttpService) { }

  getPage(request? : { filter? : { searchText?: string; anrufgruppeId?: number }; paging? : { size?: number; index?: number }; ordering? : { key?: string; direction?: number } }) { 
    const params : any = {};
    if(request.filter.searchText != null) { params['request.filter.searchText'] = request.filter.searchText; }
    if(request.filter.anrufgruppeId != null) { params['request.filter.anrufgruppeId'] = request.filter.anrufgruppeId; }
    if(request.paging.size != null) { params['request.paging.size'] = request.paging.size; }
    if(request.paging.index != null) { params['request.paging.index'] = request.paging.index; }
    if(request.ordering.key != null) { params['request.ordering.key'] = request.ordering.key; }
    if(request.ordering.direction != null) { params['request.ordering.direction'] = request.ordering.direction; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/Dokument/page`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as PageOfDokumentItem } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getAllChatDocuments(token: string) { 
    const params : any = {};
    if(token != null) { params['token'] = token; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/Dokument/GetAllChatDocuments`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as DokumentContainer[] } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(request: DokumentContainer) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/Dokument`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  delete(ids: number[]) { 
    const params : any = {};
    if(ids != null) { params['ids'] = ids; }
    return this.httpService
      .request({ method: 'DELETE', url: URI`/api/Dokument`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  get(id: number) { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/Dokument/${id}`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as DokumentContainer } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  put(id: number, request: DokumentContainer) { 
    return this.httpService
      .requestWithBody({ method: 'PUT', url: URI`/api/Dokument/${id}`, body: request, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 204) { return { status: 204 as 204, statusText: response.statusText }; }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
