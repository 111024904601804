import { Component, Input, Output, ChangeDetectionStrategy, EventEmitter } from '@angular/core';
import { ChatNachricht } from '../chatnachricht';
import { ConferenceStreamData } from '../services/conference.hub';

@Component({
  selector: 'chat-nachricht',
  templateUrl: './chatnachricht.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChatNachrichtComponent {
  @Input() n: ChatNachricht;
  @Input() currentUserToken: string;
  @Input() senderStream: ConferenceStreamData;
  @Input() forTeilnehmer = false;
  @Input() visibleForTeilnehmer: boolean = false;
  @Input() isNoAgent: boolean;

  @Output() onAddSysteminfo: EventEmitter<string> = new EventEmitter<string>();
  @Output() onWhisperAnswer: EventEmitter<string> = new EventEmitter<string>();

  addSysteminfo(text: string) {
    this.onAddSysteminfo.next(text);
  }

  whisperAnswer(name: string) {
    if (this.currentUserToken != this.n.SenderToken || (this.n.ReceiverName && this.currentUserToken != this.n.ReceiverToken)) {
      this.onWhisperAnswer.next(name);
    }
  }

}
