import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class EnsureSaveService {

  public canDeactivate(target: { tryClose?: () => Observable<string> }): Observable<boolean> {
    return target?.tryClose().pipe(map((r) => r !== 'cancel'));
  }
}
