import { Injectable } from '@angular/core';


@Injectable()
export class BrowserDownloadService {
  /**
  * Erlaubt dem Benutzer einen Blob anzusehen oder zu speichern.
  * @param blob Der Blob.
  * @param filename Der Dateiname.
  */
  saveOrOpenBlob(blob: Blob, filename: string) {
    const url = URL.createObjectURL(blob);
    this.downloadURL(url, filename);
  }

  dataURItoBlob(dataURI: string) {
    const binary = dataURI;
    const array: number[] = [];
    for (let i = 0; i < binary.length; i++) {
      array.push(binary.charCodeAt(i));
    }
    return new Blob([new Uint8Array(array)], { type: 'text/plain' });
  }

  downloadURL(url: string, filename: string) {
    const a = document.createElement('a') as HTMLAnchorElement;
    a.style.display = 'none';
    a.href = url;
    a.setAttribute('download', filename);
    a.innerText = filename;
    document.body.appendChild(a);
    setTimeout(() => {
      if (document.createEvent) {
        console.log('download with create event');
        const event = document.createEvent("MouseEvents");
        event.initMouseEvent(
          "click", true, false, window, 0, 0, 0, 0, 0
          , false, false, false, false, 0, null
        );
        a.dispatchEvent(event);
      } else {
        console.log('download with click');
        a.click();
      }
      document.body.removeChild(a);
    }, 500);
  }

}