
<label *ngIf="!withHoverLabel" [for]="id + '_colorpicker'">{{label | styles}}</label>
<label *ngIf="withHoverLabel" [for]="id + '_colorpicker'">{{label | styles:' (hover)'}}</label>
<p-colorPicker [id]="id + '_colorpicker'" [formControl]="control" [inline]="inline"></p-colorPicker>
<input type="text" [id]="id + '_input'" placeholder="Hexcode" [value]="control.value" (keyup)="setColorPickerValue($event.srcElement.value)" class="pickerinput" />
<div class="error" *ngIf="control.touched && control.errors">
  <div *ngIf="control.errors['required']">Eine Eingabe wird erwartet</div>
  <div *ngIf="control.errors['match']">{{control.errors['match']}}</div>
  <div *ngIf="control.errors['server']">{{control.errors['server']}}</div>
  <div *ngIf="control.errors['custom']">{{control.errors['custom']}}</div>
</div>
