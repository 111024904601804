import { Injectable } from "@angular/core";
import { HttpService } from "../shared/http.service";
import { URI, getFilenameFromHeaderString, serializeQuery } from "../shared/helpers";
import { map, switchMap } from "rxjs/operators";
import { ConferenceCreateModel, Option, ConferenceStartModel } from "./types";
import { HttpRequest, HttpHeaders, HttpErrorResponse, HttpParams } from "@angular/common/http";

@Injectable()
export class ConferenceHttpService {

  constructor(private httpService : HttpService) { }

  getDefaultConferenceEinladung() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/conference/GetDefaultConferenceEinladung`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as ConferenceCreateModel } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getRechtevorlageOptions() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/conference/rechtevorlage-options`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as Option[] } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getConferenceInfosByEinladungsToken(einladungsToken: string) { 
    const params : any = {};
    if(einladungsToken != null) { params['einladungsToken'] = einladungsToken; }
    return this.httpService
      .request({ method: 'GET', url: URI`/api/conference/GetConferenceInfosByEinladungsToken`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as ConferenceStartModel } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  post(conferenceCreateModel: ConferenceCreateModel) { 
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/conference`, body: conferenceCreateModel, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as ConferenceCreateModel } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  inviteTeilnehmer(gespraechsToken: string, conferenceCreateModel: ConferenceCreateModel) { 
    const params : any = {};
    if(gespraechsToken != null) { params['gespraechsToken'] = gespraechsToken; }
    return this.httpService
      .requestWithBody({ method: 'POST', url: URI`/api/conference/InviteTeilnehmer`, body: conferenceCreateModel, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), params: new HttpParams({ fromString: serializeQuery(params) }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as ConferenceCreateModel } }
        if (response.status === 400) { return { status: 400 as 400, statusText: response.statusText, data: responseData as { message : string; modelState : { [key: string]: string[] };  } } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };

  getAllAnrufgruppenFromUser() { 
    return this.httpService
      .request({ method: 'GET', url: URI`/api/conference/GetAllAnrufgruppenFromUser`, headers: new HttpHeaders({ 'Content-Type': 'application/json' }), responseType: 'json'})
      .pipe(switchMap(async response => {
        const responseData = await this.httpService.getResponseData(response);
        if (response.status === 200) { return { status: 200 as 200, statusText: response.statusText, data: responseData as Option[] } }
        throw new Error(response.status + ' ' + response.statusText);
      }));
  };
}
