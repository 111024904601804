
export class BandwidthReducer {

  private index = 0;

  constructor(private steps: number[]) {

  }

  reduce() {
    this.index = Math.max(this.index + 1, this.steps.length - 1);
  }

  get value() {
    return this.steps.length > 0 ? this.steps[this.index] : null;
  }

}