<div class="{{containerCssClass}} clearfix" [formGroup]="form">
  <h1>{{headline}}</h1>
  <div class="bewertung clearfix" *ngFor="let frage of fragen | keyvalue">
    {{frage.value.text}}<br />


    <p-rating *ngIf="frage.value.bewertungstyp === 'Bewertungsskala'" [formControl]="form.controls.fragen.controls[frage.key]" [cancel]="false" [stars]="stars"></p-rating>
    <textarea *ngIf="frage.value.bewertungstyp === 'Freitextantwort'"  name="text" [formControl]="form.controls.fragen.controls[frage.key]">



    </textarea>
    <div *ngIf="frage.value.bewertungstyp === 'Einfachauswahl'" style="margin-top: 10px">
      <div *ngFor="let f2a of frage.value.frage2Auswahl" class="p-field-radiobutton">
          <p-radioButton [name]="f2a.id" [value]="f2a.id" [(ngModel)]="selectValues[frage.key]" [formControl]="form.controls.fragen.controls[frage.key]">
          </p-radioButton>
          <label class="radio-label" for="{{f2a.id}}">{{f2a.text}}</label>
          <!-- <input id="{{f2a.id}}" class="p-field-checkbox p-col radio-style" [value]='f2a.id' type="radio" name="f2a.id" [(ngModel)]="selectValues[frage.key]" [formControl]="form.controls.fragen.controls[frage.key]"> -->
          <!-- {{f2a.text}} -->   
      </div>
    </div>

    <div *ngIf="frage.value.bewertungstyp === 'Mehrfachauswahl'" style="margin-top: 10px">
      <div *ngFor="let f2a of frage.value.frage2Auswahl" class="p-field-checkbox" >
        <p-checkbox name="group2" [inputId]="f2a.id" [value]="f2a.id" [(ngModel)]="selectValues[frage.key]" [formControl]="form.controls.fragen.controls[frage.key]" >
        </p-checkbox>
        <label [for]="f2a.id">{{f2a.text}}</label>    
      </div>



    </div>
  </div>
  <div class="bewertung clearfix" *ngIf="showTextarea">
    <label for="text">Sonstige Anmerkungen:</label>
    <textarea id="text" name="text" [formControl]="form.controls.text"></textarea>
  </div>
</div>
<div class="buttonrow clearfix">
  <button class="actionbutton" type="submit">Fertig</button>
</div>
<ng-container *ngIf="debugJsonOnForm">
  <pre>{{fragen | json}}</pre>
</ng-container>
