import { Component, Input, OnInit, EventEmitter, Output } from "@angular/core";
import { NachrichtenTemplateHttpService } from "../../generated/nachrichten-template-http.service";
import { isNullOrEmpty } from "@oevermann/core";

@Component({
  selector: 'shared-seitentext-ouput',
  templateUrl: './seitentext-output.component.html'
})
export class SeitentextOutputComponent implements OnInit {
    
  @Input() id: string;
  @Input() seitentextname: string;
  @Input() defaultText: string;
  @Input() token: string;
  @Input() anrufgruppeId?: number;

  @Output() onRueckrufLinkClicked: EventEmitter<Event> = new EventEmitter<Event>();

  private displayText: string;

  public set DisplayText(value: string) {
    this.displayText = value;
  }

  public get DisplayText() {
    if (isNullOrEmpty(this.displayText)) {
      return this.defaultText;
    }
    return this.displayText;
  }

  

  constructor(private nachrichtenTemplateHttpService: NachrichtenTemplateHttpService) { }

  ngOnInit(): void {
    if (!isNullOrEmpty(this.seitentextname)) {
      if (!isNullOrEmpty(this.token)) {
        this.nachrichtenTemplateHttpService.getSeitentext(this.seitentextname, this.token, this.anrufgruppeId || -1).subscribe(o => {
          if (o.status === 200) {
            this.DisplayText = o.data.text
          }
        });
      } else if (this.anrufgruppeId) {
          this.nachrichtenTemplateHttpService.getSeitentext(this.seitentextname, 'EMPTYTOKEN', this.anrufgruppeId || -1).subscribe(o => {
            if (o.status === 200) {
              this.DisplayText = o.data.text
            }
          });
      } else {
        this.nachrichtenTemplateHttpService.getSeitentextAllgemein(this.seitentextname).subscribe(o => {
          if (o.status === 200) {
            this.DisplayText = o.data.text
          }
        });
      }
    }
  }

  rueckrufLinkClicked(event: Event) {
    this.onRueckrufLinkClicked.next(event);
  }

}