import { SignalRService, WebRtcClientConfiguration } from "../services/signalr.service";
import { Injectable } from "@angular/core";
import { map } from 'rxjs/operators';

@Injectable()
export class WebRtcHub {

  constructor(private signalRService: SignalRService) {

  }

  private async invoke<T = void>(methodName: string, ...args: any[]): Promise<T> {
    return this.signalRService.invoke('webRtc', methodName, ...args)
  }

  private observe<T extends any[]>(eventName: string) {
    return this.signalRService.observe<T>('webRtc', eventName);
  }

  sendOffer(token: string, sdpOffer: string, maxBandwidth: number) {
    return this.invoke('SendOffer', token, sdpOffer, maxBandwidth);
  }

  sendAnswer(token: string, sdpAnswer: string) {
    return this.invoke('SendAnswer', token, sdpAnswer);
  }

  forceReconnect(token: string) {
    return this.invoke<string>('ForceReconnect', token);
  }

  addIceCandidate(token: string, iceCandidate: RTCIceCandidateInit) {
    return this.invoke('AddIceCandidate', token, iceCandidate);
  }

  start(token: string, steal: boolean) {
    return this.invoke<boolean>('Start', token, steal);
  }

  stop(token: string) {
    return this.invoke('Stop', token);
  }

  getGenralConfiguration() {
    return this.invoke<WebRtcClientConfiguration>('GetGeneralConfiguration');
  }

  getConfiguration(token: string) {
    // console.trace('WebRtcHub getConfiguration');
    // console.log('WebRtcHub getConfiguration token', token);
    return this.invoke<WebRtcClientConfiguration>('GetConfiguration', token);
  }

  onAddIceCandidate = this.observe<[string, RTCIceCandidateInit]>('addIceCandidate');

  onForceReconnect = this
    .observe<[string]>('ForceReconnect')
    .pipe(map(a => a[0]));

  onSendOffer = this.observe<[string, string]>('SendOffer');

  onSendAnswer = this.observe<[string, string]>('SendAnswer');

  onStart = this.observe<[string, boolean]>('Start');

  onStop = this.observe<[string, boolean]>('Stop');
}