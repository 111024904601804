<div #mediaSettings class="mediasettings" [class.nachrichtentemplates]="!previewStream">

  <div *ngIf="media_message.length" class="row mediaerror">
    <ng-conatiner *ngFor="let error of media_message; let last = last">
      {{error}}<br *ngIf="!last" />
    </ng-conatiner>
  </div>

  <div class="row bold">
    <label for="availableCameras">Kamera:</label>
    <select id="availableCameras" [formControl]="camera_deviceId">
      <option value="">Keine Kamera verfügbar</option>
    </select>
  </div>

  <div *ngIf="isAllowedToActivateVirtualBackground" class="row">
    <input id="backgroundBlur" type="checkbox" [formControl]="camera_backgroundBlur" />
    <label for="backgroundBlur">Hintergrund ausblenden</label>
  </div>

  <div class="row bold">
    <label for="availableMicrophones">Mikrofon:</label>
    <select id="availableMicrophones" [formControl]="microphone_deviceId">
      <option value="">Kein Mikrofon verfügbar</option>
    </select>
  </div>

  <div class="row">
    <input id="autoGainControl" type="checkbox" [formControl]="microphone_autoGainControl" />
    <label for="autoGainControl">Automatische Verstärkungsregelung</label>
  </div>

  <div class="row">
    <input id="echoCancellation" type="checkbox" [formControl]="microphone_echoCancellation" />
    <label for="echoCancellation">Echokompensation</label>
  </div>

  <div class="row">
    <input id="noiseSuppression" type="checkbox" [formControl]="microphone_noiseSupression" />
    <label for="noiseSuppression">Rauschunterdrückung</label>
  </div>

  <div class="row bold" *ngIf="hasSinkId">
    <label for="availableSpeakers">Lautsprecher:</label>
    <select id="availableSpeakers" [formControl]="speaker_deviceId">
      <option value="">Kein Lautsprecher verfügbar</option>
    </select>
  </div>

  <!--
  Cam: {{ camera_deviceId.value }}<br />
  Mic: {{ microphone_deviceId.value }}<br />
  Mic: {{ microphone_autoGainControl.value }}<br />
  Mic: {{ microphone_echoCancellation.value }}<br />
  Mic: {{ microphone_noiseSupression.value }}<br />
  Speak: {{ speaker_deviceId.value }}
  -->

  <div class="buttonrow clearfix">
    <button class="actionbutton" (click)="setSettings($event)">Übernehmen</button>
    <button *ngIf="closeButton" class="actionbutton other" (click)="hideMediaSettings($event)">Abbrechen</button>
  </div>
</div>
