import { PipeTransform, Pipe } from "@angular/core";
import { AngemeldeterAgentContainer, RueckrufgesuchContainer } from "../generated/types";
import { MemberService } from "@oevermann/angular";


@Pipe({
  name: 'meineRueckrufgesuche',
  pure: true
})
export class MeineRueckrufgesuchePipe implements PipeTransform {

  constructor(private memberService: MemberService) { }
// 
  transform(value: RueckrufgesuchContainer[], angemeldeterAgent: AngemeldeterAgentContainer): RueckrufgesuchContainer[] {

    if (value && (!this.memberService.isInRole('Manager') && (!this.memberService.isInRole('Anrufgruppenmanager'))  
    && !this.memberService.isInRole('Administrator'))) { // agent und einladungsmanager
      return value.filter(r => angemeldeterAgent.anrufgruppen.map(function (a) { return a.id; }).indexOf(r.anrufgruppeId, 0) > -1);
    } else {  // Administrator, Manager, Anrufgruppenmanager (kann gleichzeitig Agentrolle habe, scheint trotzdem zu passen)
      return value;
    }
  }
}