<ng-container *ngIf="displayHtmlComponent">{{displayHtmlComponentName()}}</ng-container>
<div class="conferenceTeilnehmer">
  <form [formGroup]="conferenceTeilnehmer" class="backendform"> 
    <div class="row">
      <label for="anrede">Anrede*</label>
      <input type="text" id="anrede" name="anrede" formControlName="anrede" required/>
      <shared-error [controlToValidate]="conferenceTeilnehmer.controls['anrede']"></shared-error>
    </div>
    <div class="row">
      <label for="name">Name*</label>
      <input type="text" id="name" formControlName="name" required/>
      <shared-error [controlToValidate]="conferenceTeilnehmer.controls['name']"></shared-error>
    </div>
    <div class="row">
      <label for="email">E-Mail*</label>
      <input type="text" id="email" formControlName="email" required/>
      <shared-error [controlToValidate]="conferenceTeilnehmer.controls['email']"></shared-error>
    </div>
    <div class="actions" *ngIf="!isActiveConference">
      <div class="pointer" title="Teilnehmer entfernen" (click)="removeTeilnehmer(conferenceTeilnehmer)" *ngIf="teilnehmerCount > 1 && formCount > 1">
        <img src="/assets/cockpit/action_active_cancel.svg" alt="Teilnehmer entfernen"/>
      </div>
      <div class="pointer" title="Teilnehmer hinzufügen" (click)="addTeilnehmer($event)"
           *ngIf="last && teilnehmerCount < maxAllowedConferenceMembers">
        <img src="/assets/add.svg" alt="Teilnehmer hinzufügen"/>
      </div>
    </div>
    <div class="row">
      <shared-dropdown-input id="typ" label="Rechtevorlage *" [items]="rechtevorlagen | async"
                             [control]="conferenceTeilnehmer.controls['rechtevorlageId']"></shared-dropdown-input>
      <shared-error [controlToValidate]="conferenceTeilnehmer.controls['rechtevorlageId']"></shared-error>
    </div>
    <div class="actions clearfix" *ngIf="isActiveConference">
      <div class="pointer" title="Teilnehmer entfernen" (click)="removeTeilnehmer(conferenceTeilnehmer)" *ngIf="teilnehmerCount > 1 && formCount > 1">
        <img src="/assets/cockpit/action_active_cancel.svg" alt="Teilnehmer entfernen"/>
      </div>
      <div class="pointer" title="Teilnehmer hinzufügen" (click)="addTeilnehmer($event)"
           *ngIf="last && teilnehmerCount < maxAllowedConferenceMembers">
        <img src="/assets/add.svg" alt="Teilnehmer hinzufügen"/>
      </div>
    </div>
  </form>
</div>
