import { FormGroup } from "@angular/forms";
import { detect, SearchBotDeviceInfo } from 'detect-browser';

/**
 * Eine Tagged Template Function, welche die Werte für eine URI codiert.
 * @param literals
 * @param values
 */
export function URI(literals: TemplateStringsArray, ...values: (number | boolean | string | { name: string; items: any[] })[]) {
  let result = '';
  const length = Math.max(literals.length, values.length);
  for (let i = 0; i < length; i++) {
    result += literals[i];
    const value = values[i];
    if (value !== undefined) {
      if (typeof value === 'object') {
        result += value.items.map(v => value.name + '=' + encodeURIComponent(v)).join('&');
      } else {
        result += encodeURIComponent(values[i].toString());
      }
    }
  }
  return result;
}

export function getFilenameFromHeaderString(input: string) {
  var filename = '';
  if (input && input.indexOf('attachment') !== -1) {
    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    var matches = filenameRegex.exec(input);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return decodeURIComponent(filename);
}

export function serializeQuery(params, prefix? : string) {
  const query = Object.keys(params).map((key) => {
    const value = params[key];
    if (value == undefined) {
      return;
    }

    if (params.constructor === Array) {
      key = `${prefix}`;
    }
    else if (params.constructor === Object) {
      key = (prefix ? `${prefix}.${key}` : key);
    }

    if (typeof value === 'object') {
      return serializeQuery(value, key);
    }
    else {
      return `${key}=${encodeURIComponent(value)}`;
    }
  });

  return [].concat.apply([], query).filter(q => q != undefined).join('&');
}

export type DeviceInfo = ReturnType<typeof detect>

let deviceInfo: DeviceInfo | undefined;

/**
 * Gibt Informationen über den Browser zurück.
 */
export function getDeviceInfo(): DeviceInfo {

  if (!deviceInfo) {
    deviceInfo = detect();
  }

  return deviceInfo
}