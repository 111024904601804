import {Component, Input, EventEmitter, Output} from '@angular/core';
import {from, Observable} from 'rxjs';
import {ConferenceTeilnehmerCreateModelForm} from '../../generated/forms';
import {BaseComponent} from '../../shared/base.component';
import {ConfigurationContainer, Option} from '../../generated/types';
import {ActivatedRoute} from '@angular/router';
import {OnInit} from '@angular/core';
import {Validators} from '@angular/forms';
import {OnValidators} from '@oevermann/angular';
import {SettingDetailComponent} from '../../administrator/setting/setting-detail.component';
import {ConfigurationHttpService} from '../../generated/configuration-http.service';
import { GlobalFunctions } from '../global-functions';

interface ParentData {
    configuration: ConfigurationContainer;
}

@Component({
    selector: 'conference-teilnehmer-einladung',
    templateUrl: './conference.teilnehmer.einladung.component.html'
})
export class ConferenceTeilnehmerEinladungComponent extends BaseComponent implements OnInit {

    public maxAllowedConferenceMembers = GlobalFunctions.maxAllowedConferenceMembers
    @Input() conferenceTeilnehmer: ConferenceTeilnehmerCreateModelForm;
    @Input() rechtevorlagen: Observable<Option[]>;
    @Input() isActiveConference = false;
    @Input() first: boolean;
    @Input() last: boolean;
    @Input() teilnehmerCount: number;
    @Input() formCount: number;

    @Output() onAdd: EventEmitter<Event> = new EventEmitter();
    @Output() onRemove: EventEmitter<ConferenceTeilnehmerCreateModelForm> = new EventEmitter();

 
    constructor(
        private route: ActivatedRoute,
        private configurationHttpService: ConfigurationHttpService
    ) {
        super();
    }


    ngOnInit() {
        this.conferenceTeilnehmer.controls.email.setValidators([Validators.required, OnValidators.emailOrEmpty]);
        this.conferenceTeilnehmer.controls.rechtevorlageId.setValidators(Validators.required);

        this.configurationHttpService.get().subscribe(response => {
            this.maxAllowedConferenceMembers = response.data.maxAllowedConferenceMembers - 1 ?? 19;
        });

        this.subscription.add(this.route.parent.data.subscribe((data: ParentData) => {
            this.maxAllowedConferenceMembers = data.configuration.maxAllowedConferenceMembers - 1 ?? 19;
        }));
    }

    addTeilnehmer(event: Event) {
        this.onAdd.next(event);
    }

    removeTeilnehmer(teilnehmer: ConferenceTeilnehmerCreateModelForm) {
        this.onRemove.next(teilnehmer);
    }
}
