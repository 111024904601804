import { Injectable } from '@angular/core';
import { WebRtcHub } from '../services/webrtc.hub';

@Injectable()
export class WebRtcService {

  constructor(private webRtcHub: WebRtcHub) {
  }

  onAddIceCandidate = this.webRtcHub.onAddIceCandidate;

  onForceReconnect = this.webRtcHub.onForceReconnect;

  onSendOffer = this.webRtcHub.onSendOffer;

  onSendAnswer = this.webRtcHub.onSendAnswer;

  onStart = this.webRtcHub.onStart;

  onStop = this.webRtcHub.onStop;

  /**
   * Schickt einen ICE Candidate zum Server.
   * @param token Das Token des Gesprächs.
   * @param iceCandidate Der ICE Candidate.
   */
  addIceCandidate(token: string, iceCandidate: RTCIceCandidateInit) {
    return this.webRtcHub.addIceCandidate(token, iceCandidate);
  }

  /**
   * Gibt die Konfiguration für ein Gespräch zurück.
   * @param token Das Token des Gesprächs.
   */
  async start(token: string, steal: boolean) {
    return this.webRtcHub.start(token, steal);
  }

  async stop(token: string) {
    return this.webRtcHub.stop(token);
  }

  /**
   * Gibt die Konfiguration mit allen ICE-Servern zurück.
   */
  async getGeneralConfiguration() {
    return this.webRtcHub.getGenralConfiguration();
  }


  /**
 * Gibt die Konfiguration zurück.
 */
  async getConfiguration(token: string) {
    return this.webRtcHub.getConfiguration(token);
  }
  /**
   * Initialisiert eine WebRTC-Verbindung.
   * @param token Das Token.
   * @param sdpOffer Die Session Description.
   * @param maxBandwidth Die maximale Bandbreite.
   */
  async sendOffer(token: string, sdpOffer: string, maxBandwidth: number) {
    return this.webRtcHub.sendOffer(token, sdpOffer, maxBandwidth);
  }

  async sendAnswer(token: string, sdpAnswer: string) {
    return this.webRtcHub.sendAnswer(token, sdpAnswer);
  }

  async forceReconnect(token: string) {
    await this.webRtcHub.forceReconnect(token);
  }
}