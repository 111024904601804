import {Component, Output, EventEmitter, ViewChild, Input} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {filter, throttleTime} from 'rxjs/operators';
import {GespraechContainer, GespraechsSettingsContainer} from '../generated/types';
import { AgentChatComponent } from '../agent/agent.chat.component';
import { ConferenceData } from '../services/conference.hub';
import { ConferenceDatas } from '../webrtc/conference2.component';
import { NotificationService } from './notification.service';
import { AllowConferenceMemberList } from '../enums/allowConferenceMemberList';
import { ChatService } from '../services/chat.service';
import { UploadNotifier } from './upload-notifier';
import { BaseComponent } from './base.component';


export interface WhisperData {
  receiverToken: string;
  text: string;
}

@Component({
  selector: 'chat-aktionen',
  templateUrl: './chataktionen.component.html'
})
export class ChatAktionenComponent extends BaseComponent  {

  public uploadFileInProgress: boolean=false;
  nachricht: string;
  isWritingBouncing = new Subject<string>();
  isDisabled: boolean = false;
  uploadVisible: boolean = false;
  upload: boolean;
  currentFileList: FileList;
  @Input() data: ConferenceData | null = null;
  @Input() clientId: any;
  
  write2UserList: string[] = [];

  @Input() settings: GespraechsSettingsContainer;
  @Input() agentTemplatesAvailable: boolean = false;        // Templateicon soll beim Agent nur angezeigt werden, wenn auch Templates verfügbar
  @Input() allowConferenceMemberList: AllowConferenceMemberList = AllowConferenceMemberList.none;
  @Input() currentGespraech: GespraechContainer;

  @Output() onSend: EventEmitter<string> = new EventEmitter();
  @Output() onWhisper: EventEmitter<WhisperData> = new EventEmitter();
  @Output() attach: EventEmitter<string> = new EventEmitter();
  @Output() isWriting: Observable<string>;
  @Output() getEmojiPicker: EventEmitter<void> = new EventEmitter();
  @Output() getEmojiPickerClose: EventEmitter<void> = new EventEmitter();
  @Output() getTemplatePicker: EventEmitter<void> = new EventEmitter();
  @Output() getTemplatePickerClose: EventEmitter<void> = new EventEmitter();


  @Output() attachFile: EventEmitter<FileList> = new EventEmitter();

  @ViewChild('nachrichtInput') ni: any;
  @ViewChild('write2userContainer') w2uc: any;


  // Agentchat injected, damit das Nachrichtentemplate-Menü aus dieser Component geöffnet werden kann
  constructor(
    private notificationService: NotificationService,
    private chatService: ChatService,
    private uploadNotifier: UploadNotifier
  ) {
    super();
    this.isWriting = this.isWritingBouncing
      .pipe(
        filter(text => {
          return typeof (text) !== 'undefined' ? text.length > 0 && !text.startsWith('@') : false
        }),
        throttleTime(500)
      );

      this.uploadNotifier.uploadFileInProgresChanged.subscribe(inProgress => {this.uploadFileInProgress = inProgress});
  }

  openMessageTemplateMenu() {
    /*this.agentChatComponent.setMenu(1);*/
  }

  messageActions(event: any) {
    this.checkWhisper();
    this.isWritingBouncing.next(this.nachricht);
    this.showConferenceMemberList();
    this.replaceWrittenEmojis();
  }

  replaceWrittenEmojis() {
    if (this.nachricht && this.settings && !this.settings.emojiDisabled) {
      
      this.nachricht = this.nachricht
        .replace(/:\)/, '🙂')
        .replace(/;\)/g, '😉')
        .replace(/:\//g, '😕').replace(/😕\//g, '://')
        .replace(/:\|/g, '😐')
        .replace(/:D/g, '😀')
        .replace(/:O/g, '😯')
        .replace(/:P/g, '😋')
        .replace(/:\(/g, '☹')
        .replace(/;\(/g, '😢')
        .replace(/<3/g, '❤');
    }
  }

  fileChanged(event) {
    this.currentFileList = event.target.files;
    this.upload = this.currentFileList.length > 0;
  }

  send() {
    if (this.nachricht.length) {
      if (this.nachricht.startsWith('@')) {
        this.whisperMessage();
      }
      else {
        this.sendMessage();
      }
    }
  }

  sendMessage() {
    this.closeEmojiPicker();
    this.closeTemplatePicker();
    this.onSend.next(this.nachricht);
    this.nachricht = '';
  }

  whisperMessage() {
    const whisperToRegEx: RegExp = /^@(.*?):/;
    const whisperTo = whisperToRegEx.exec(this.nachricht);
    if (this.data) {
      const receiverToken = Object.entries(this.data.streams)
        .filter(s => s[1].clientId != this.clientId && s[1].visibleForTeilnehmer && s[1].userName.toLowerCase() == whisperTo[1].toLowerCase())
        .map(i => i[1].benutzerToken);
      if (receiverToken.length == 1) {
        const text = this.nachricht.replace(whisperTo[0], '').trim()
        if (text.length) {
          this.onWhisper.next({ receiverToken: receiverToken[0], text: text });
          this.nachricht = '';
        }
      }
      else if (receiverToken.length > 1) {
        // console.log('whisperMessage - receiverToken.length > 1', this.data.streams)
        this.notificationService.notifyError("Der angegebene Empfänger \"" + whisperTo[1] + "\" konnte nicht eindeutig ermittelt werden.<br />Die Nachricht wurde nicht versendet.");
      }
      else {
        // console.log('whisperMessage - this.data.streams', this.data.streams)
        this.notificationService.notifyError("Der angegebene Empfänger \"" + whisperTo[1] + "\" konnte nicht gefunden werden.<br />Die Nachricht wurde nicht versendet.");
      }
    } else {
      // console.log('whisperMessage - no data')
      this.notificationService.notifyError("Es konnten keine Empfänger ermittelt werden.<br />Die Nachricht wurde nicht versendet.");
    }
  }

  sendFile() {
    if (this.currentFileList.length > 0 && !this.settings.uploadDisabled) {  
      this.attachFile.next(this.currentFileList);
      this.currentFileList = null;
      this.toggleUpload();
    }
  }

  toggleUpload() {
    this.currentFileList = null;
    this.closeEmojiPicker();
    this.uploadVisible = !this.uploadVisible;
  }

  toggleEmojiPicker() {
    this.getEmojiPicker.next();
  }

  closeEmojiPicker() {
    this.getEmojiPickerClose.next();
  }

  toggleTemplatePicker() {
    this.getTemplatePicker.next();
  }

  closeTemplatePicker() {
    this.getTemplatePickerClose.next();
  }

  addEmojiToText(add: string) {
    let input = this.ni.nativeElement;
    let text = this.nachricht || '';
    let pos = 0;

    if (input.selectionStart || input.selectionStart === '0') {
      pos = input.selectionStart;
    }

    let before = text.substring(0, pos);
    let after = text.substring(pos, text.length);
    this.nachricht = before + add + after;
    pos = pos + add.length;

    window.setTimeout(function () {
      if (input.createTextRange) {
        let range = input.createTextRange();
        range.move('character', pos);
        range.select();
      } else {
        if (input.setSelectionRange) {
          input.focus();
          input.setSelectionRange(pos, pos);
        } else {
          input.focus();
        }
      }
    }, 1);
  }

  inputIsEmpty() {
    return typeof this.nachricht === 'undefined' || this.nachricht === '';
  }

  uploadIsEmpty() {
    return this.currentFileList === null || this.currentFileList.length === 0;
  }

  public setData(data: ConferenceDatas) {
    
    this.LogComponentMethods(`setData`);

    // console.log('chataktionen.component - setData', data);
    this.data = data.data;
    this.clientId = data.clientId;
  }

  checkWhisper() {
    if (this.nachricht && this.nachricht.startsWith('@')) {
      this.ni.nativeElement.classList.add('whisper');
    }
    else {
      this.ni.nativeElement.classList.remove('whisper');
    }
  }

  showConferenceMemberList() {
    if (this.allowConferenceMemberList != AllowConferenceMemberList.none) {
      const whisperToRegEx: RegExp = /^@(((?!:).)*)$/;
      const whisperTo = whisperToRegEx.exec(this.nachricht);
      if (whisperTo) {
        if (this.allowConferenceMemberList == AllowConferenceMemberList.all && this.data) {
          this.write2UserList = Object.entries(this.data.streams)
            .filter(s => s[1].clientId != this.clientId && s[1].visibleForTeilnehmer && s[1].userName.toLowerCase().startsWith(whisperTo[1].toLowerCase()))
            .map(i => { return i[1].isAnonymousForOthers ? 'Teilnehmer ' + i[1].teilnehmerId : i[1].userName });
        }
        else if (this.allowConferenceMemberList == AllowConferenceMemberList.organizerOnly && this.data) {
          this.write2UserList = Object.entries(this.data.streams)
            .filter(s => s[1].isOrganizer && s[1].clientId != this.clientId && s[1].visibleForTeilnehmer)
            .map(i => { return i[1].isAnonymousForOthers ? 'Teilnehmer ' + i[1].teilnehmerId : i[1].userName });
        }
      }
      else {
        this.write2UserList = [];
      }
    }
  }

  public writeTo(value: string) {
    this.nachricht = '@' + value + ': ';
    this.write2UserList = []
    this.checkWhisper();
    this.ni.nativeElement.focus();
  }

  selectWrite2UserList() {
    if (this.w2uc && this.w2uc.nativeElement.classList.contains('show')) {
      for (let i = 0; i < this.w2uc.nativeElement.children.length; i++) {
        this.w2uc.nativeElement.children[i].classList.remove('selected');
      }
      this.w2uc.nativeElement.children[0].classList.add('selected')
      this.w2uc.nativeElement.focus();
    }
  }

  navigate(event: KeyboardEvent) {
    event.preventDefault();
    const key = event.which;
    switch (key) {
      case 38: // arrow up
        this.moveUp();
        break;
      case 40: // arrow down
        this.moveDown();
        break;
      case 9: // tab
        this.select();
        break;
    }
  }

  moveUp() {
    if (this.w2uc && this.w2uc.nativeElement.classList.contains('show')) {
      const num = this.w2uc.nativeElement.children.length -1;
      for (let i = num; i >= 0; i--) {
        if (this.w2uc.nativeElement.children[i].classList.contains('selected')) {
          this.w2uc.nativeElement.children[i].classList.remove('selected');
          const prev = (i - 1 == 0 ? num : i - 1);
          this.w2uc.nativeElement.children[prev].classList.add('selected');
          break;
        }
      }
    }
  }

  moveDown() {
    if (this.w2uc && this.w2uc.nativeElement.classList.contains('show')) {
      const num = this.w2uc.nativeElement.children.length;
      for (let i = 0; i < num; i++) {
        if (this.w2uc.nativeElement.children[i].classList.contains('selected')) {
          this.w2uc.nativeElement.children[i].classList.remove('selected');
          const next = (i + 1 == num ? 0 : i + 1);
          this.w2uc.nativeElement.children[next].classList.add('selected');
          break;
        }
      }
    }
  }

  select() {
    if (this.w2uc && this.w2uc.nativeElement.classList.contains('show')) {
      const num = this.w2uc.nativeElement.children.length;
      for (let i = 0; i < num; i++) {
        if (this.w2uc.nativeElement.children[i].classList.contains('selected')) {
          this.writeTo(this.w2uc.nativeElement.children[i].textContent.replace('@', ''));
          break;
        }
      }
    }
  }

}

