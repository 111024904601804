<div class="chatarea">
  <div *ngFor="let nachricht of nachrichten" class="clearfix">
    <chat-nachricht [ngClass]="{ 'self': currentUserToken == nachricht.SenderToken, 'whisper': nachricht.ReceiverToken }"
                    [n]="nachricht"
                    [forTeilnehmer]="forTeilnehmer"
                    [isNoAgent]="isNoAgent"
                    [visibleForTeilnehmer]="visibleForTeilnehmer"
                    [currentUserToken]="currentUserToken"
                    [senderStream]="getSenderStream(nachricht.SenderToken)"
                    (onAddSysteminfo)="addSysteminfo($event)"
                    (onWhisperAnswer)="whisperAnswer($event)">
    </chat-nachricht>
  </div>
  <div class="otheriswriting" *ngIf="showIsWriting">
    <div><img src="assets/iswriting.gif" alt="Gesprächspartner schreibt" /></div>
  </div>
</div>
